import styled from '@emotion/styled/macro';
import { ModelOverrides } from 'app/apiGenerated/generatedApiTypes';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  entityPreferencesActions,
  selectEntityPrefs,
} from 'app/slices/entityPreferencesSlice';
import { projectActions } from 'app/slices/projectSlice';
import React from 'react';
import { useModal } from 'ui/common/Modal/useModal';
import { scanNodesAndSignals } from 'ui/modelEditor/optimizations/optimizerModalUtils';
import { TabSelector } from 'ui/modelEditor/optimizations/TabSelector';
import {
  ENSEMBLE_SIM_PREFS_V1_KEY,
  EnsembleSimPrefsV1,
  EnsembleTab,
} from 'ui/userPreferences/ensembleSimModelPrefs';
import { MonteCarloConfig } from './MonteCarloConfig';
import { ParameterSweepConfig } from './ParameterSweepConfig';

const EnsembleSimModalContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  max-width: 600px;
`;

const ModelOptimizerNavSpacer = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.grey[10]};
`;

const ModelOptimizerNavContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.normal};

  > * {
    flex: 1;
  }

  & > *:nth-child(2) {
    flex: 0;
    flex-basis: 480px;
  }
`;

const EnsembleSimModal: React.FC<{ initPrefs: EnsembleSimPrefsV1 }> = ({
  initPrefs,
}) => {
  const tabOptions: Array<{ value: EnsembleTab; label: string }> = [
    { value: 'paramSweep', label: 'Parameter sweep' },
    { value: 'monteCarlo', label: 'Monte Carlo' },
  ];

  const modelUuid = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );

  const dispatch = useAppDispatch();

  const { closeModal } = useModal();

  const updatedPrefs: EnsembleSimPrefsV1 | undefined = useAppSelector((state) =>
    selectEntityPrefs(state, ENSEMBLE_SIM_PREFS_V1_KEY, modelUuid),
  );

  const prefs =
    updatedPrefs && Object.keys(updatedPrefs).length > 0
      ? updatedPrefs
      : initPrefs;

  const onRunAll = React.useCallback(
    (modelOverrides: ModelOverrides) => {
      dispatch(projectActions.requestRunEnsemble({ modelOverrides }));
      closeModal();
    },
    [closeModal, dispatch],
  );

  // remove parameters and signals that don't exist, this can happen when the
  // user removes/renames a model param or deletes a block
  const modelState = useAppSelector((state) => state.model.present);
  const { signalOptions } = React.useMemo(
    () => scanNodesAndSignals(modelState),
    [modelState],
  );
  React.useEffect(() => {
    ['paramSweep', 'monteCarlo'].forEach((tab) => {
      const { overridenParams, signals } = prefs[tab as EnsembleTab];
      const prefParamNames = Object.keys(overridenParams);
      const existingParamNames = prefParamNames.filter((param) =>
        modelState.parameters.find((p) => p.name === param),
      );

      const newSignals = signals.filter((signal) =>
        signalOptions.find((s) => s.signalPathName === signal),
      );
      if (
        existingParamNames.length !== prefParamNames.length ||
        newSignals.length !== signals.length
      ) {
        dispatch(
          entityPreferencesActions.onUserUpdatedEnsembleSimModalPrefs({
            modelId: modelUuid,
            tab: tab as EnsembleTab,
            prefs: {
              overridenParams: existingParamNames.reduce((acc, param) => {
                acc[param] = overridenParams[param];
                return acc;
              }, {} as Record<string, any>),
              signals: newSignals,
            },
          }),
        );
      }
    });
  }, [dispatch, modelState.parameters, modelUuid, prefs, signalOptions]);

  const setSelectedTab = (selectedTab: string) => {
    dispatch(
      entityPreferencesActions.onUserUpdatedPrefs({
        preferencesKey: ENSEMBLE_SIM_PREFS_V1_KEY,
        entityId: modelUuid,
        prefs: {
          ...prefs,
          selectedTab,
        },
      }),
    );
  };

  const renderSection = () =>
    ({
      paramSweep: (
        <ParameterSweepConfig prefs={prefs.paramSweep} onRunAll={onRunAll} />
      ),
      monteCarlo: (
        <MonteCarloConfig prefs={prefs.monteCarlo} onRunAll={onRunAll} />
      ),
    }[prefs.selectedTab]);

  return (
    <EnsembleSimModalContainer>
      <ModelOptimizerNavContainer>
        <ModelOptimizerNavSpacer />
        <TabSelector
          value={prefs.selectedTab}
          options={tabOptions}
          onSelect={setSelectedTab}
        />
        <ModelOptimizerNavSpacer />
      </ModelOptimizerNavContainer>
      {renderSection()}
    </EnsembleSimModalContainer>
  );
};

export default EnsembleSimModal;
