import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { PortSide } from 'app/common_types/PortTypes';
import { blockClassLookup } from 'app/generated_blocks/';
import { BlockParameterDefinition } from 'app/generated_types/ComputationBlockClass';
import { NodeInstance, Port } from 'app/generated_types/SimulationModel';
import { nodeTypeIsDynamicBlock, nodeTypeIsSubdiagram } from 'app/helpers';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { getPortDisplayName } from 'app/utils/portConditionUtils';
import {
  canAddPort,
  canDeletePort,
  canRenamePort,
} from 'app/utils/portOperationUtils';
import { getDefaultPortParameters } from 'app/utils/portUtils';
import React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import { isValidBlockPortNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import { DetailsInput, DetailsSection } from 'ui/modelEditor/DetailsComponents';
import { useVisualizerPrefs } from 'ui/modelEditor/useVisualizerPrefs';
import BlockPortParameters from './BlockPortParameter';
import VisualizerToggler from './Visualizer/VisualizerToggler';

type Props = {
  parentPath: string[];
  selectedNode: NodeInstance;
  canEdit: boolean;
};

export const PortList = styled.div``;

export const PortDropArea = styled.div``;

export const PortRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing.small};

  > * {
    width: 100%;
  }
`;

const PortDragHandleContainer = styled.div<{ canDrag: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  width: 16px;
  height: 22px;
  margin-left: 0px;
  flex-shrink: 0;
  ${({ canDrag }) =>
    canDrag
      ? `
    opacity: 1;
    pointer-events: all;
  `
      : `
    opacity: 0;
    pointer-events: none;
    width: 0px;
  `}
`;

const PortDragHandleGroove = styled.div`
  background: #ccc;
  width: 8px;
  height: 1px;
  margin-top: 3px;
`;

export const PortDragHandle = ({ canDrag, ...rest }: { canDrag: boolean }) => (
  <PortDragHandleContainer canDrag={canDrag} {...rest}>
    <PortDragHandleGroove />
    <PortDragHandleGroove />
    <PortDragHandleGroove />
  </PortDragHandleContainer>
);

const BlockOutportDetails: React.FC<Props> = ({
  parentPath,
  selectedNode,
  canEdit,
}) => {
  const dispatch = useAppDispatch();

  const {
    addPortsToChart,
    removePortsFromChart,
    getAreAllNodePortsInChart,
    getIsPortInChart,
  } = useVisualizerPrefs();
  const blockClass = blockClassLookup(selectedNode.type);

  const minDynamicOutputCount =
    blockClass.ports.outputs?.dynamic?.min_count || 0;

  const canShowAddButton =
    canEdit &&
    !nodeTypeIsSubdiagram(selectedNode.type) &&
    !nodeTypeIsDynamicBlock(selectedNode);
  const canAddOutput = canEdit && canAddPort(selectedNode, PortSide.Output);

  const addOutput = React.useCallback(() => {
    const parameters = getDefaultPortParameters(
      blockClass.ports.outputs?.dynamic?.parameter_definitions,
    );
    dispatch(
      modelActions.addPort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Output,
        parameters,
      }),
    );

    // Heuristic to toggle on visualization to this new port if other ports
    // in this block are currently being visualized.
    if (getAreAllNodePortsInChart({ nodeId: selectedNode.uuid, parentPath })) {
      addPortsToChart([
        {
          nodeId: selectedNode.uuid,
          portIndex: selectedNode.outputs.length,
          parentPath,
        },
      ]);
    }
  }, [
    blockClass.ports.outputs?.dynamic?.parameter_definitions,
    dispatch,
    parentPath,
    selectedNode.uuid,
    selectedNode.outputs.length,
    getAreAllNodePortsInChart,
    addPortsToChart,
  ]);

  const getRemoveOutput = (outputId: number) => () => {
    // Stop visualizing the port removing it.
    if (
      getIsPortInChart({
        nodeId: selectedNode.uuid,
        portIndex: outputId,
        parentPath,
      })
    ) {
      removePortsFromChart([
        {
          nodeId: selectedNode.uuid,
          portIndex: outputId,
          parentPath,
        },
      ]);
    }

    // Finally, remove the port itself.
    dispatch(
      modelActions.removePort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Output,
        portId: outputId,
      }),
    );
  };

  const renameOutput = (outputId: number, newPortName: string) => {
    dispatch(
      modelActions.renamePort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Output,
        portId: outputId,
        newPortName,
      }),
    );
  };

  const getPortKind = (port: Port): 'static' | 'dynamic' | 'conditional' => {
    if (port.kind) return port.kind;
    for (const portDef of blockClass.ports.outputs?.static || []) {
      if (portDef.name === port.name) {
        return 'static';
      }
    }
    for (const portDef of blockClass.ports.outputs?.conditional || []) {
      if (portDef.name === port.name) {
        return 'conditional';
      }
    }
    return 'dynamic';
  };

  const getPortParamDefinitions = (
    port: Port,
    index: number,
  ): BlockParameterDefinition[] | undefined => {
    switch (getPortKind(port)) {
      case 'dynamic':
        return blockClass.ports.outputs?.dynamic?.parameter_definitions;
      case 'conditional':
        return undefined;
      case 'static':
        return blockClass.ports.outputs?.static?.[index]?.parameter_definitions;
    }
  };

  const canDragPorts =
    canEdit &&
    (selectedNode.type === 'core.Group' ||
      selectedNode.type === 'core.PythonScript');

  const sortedPorts: Array<{ port: Port; portIndex: number }> =
    React.useMemo(() => {
      const outputs = [];

      for (let i = 0; i < selectedNode.outputs.length; i++) {
        let portIndex = 0;

        // if these ports aren't draggable
        // or we don't have any port-ordering data (not likely to ever happen, but technically possible in the typedef)
        // we default to the underlying port order.
        if (!canDragPorts || !selectedNode.uiprops.outport_order) portIndex = i;
        // otherwise, of course, we use the port ordering data we have.
        else portIndex = selectedNode.uiprops.outport_order[i];

        if (
          portIndex !== undefined &&
          portIndex < selectedNode.outputs.length &&
          selectedNode.outputs[portIndex]?.variant?.variant_kind !== 'acausal'
        ) {
          outputs.push({ port: selectedNode.outputs[portIndex], portIndex });
        }
      }

      return outputs;
    }, [
      canDragPorts,
      selectedNode.outputs,
      selectedNode.uiprops.outport_order,
    ]);

  // Always show Inputs/Outputs header for Submodels
  const showOutputsHeader =
    canAddOutput ||
    nodeTypeIsSubdiagram(selectedNode.type) ||
    sortedPorts.length > 0;

  const onDragEnd: OnDragEndResponder = (dragInfo) => {
    if (
      !dragInfo.destination ||
      dragInfo.source.index === dragInfo.destination.index
    )
      return;

    const portIndex = parseInt(dragInfo.draggableId);

    dispatch(
      modelActions.reorderNodePort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        side: PortSide.Output,
        portIndex,
        fromOrderIndex: dragInfo.source.index,
        toOrderIndex: dragInfo.destination.index,
      }),
    );
  };

  return (
    <>
      {showOutputsHeader && (
        <SectionHeading
          testId="outputs"
          onButtonClick={canShowAddButton ? addOutput : undefined}
          isButtonEnabled={canAddOutput}
          buttonTooltip={t({
            id: 'blockDetails.addOutputButtonTooltip',
            message: 'Add output',
          })}>
          {t({
            id: 'blockDetails.PortOutputsTitle',
            message: 'Outputs',
          })}
        </SectionHeading>
      )}
      {(sortedPorts.length > 0 || canAddOutput) && (
        <DragDropContext onDragEnd={onDragEnd}>
          <PortList>
            <Droppable droppableId="droppable-outports" type="outports">
              {(providedDroppable, _) => (
                <PortDropArea
                  ref={providedDroppable.innerRef}
                  {...providedDroppable.droppableProps}>
                  {sortedPorts.map(
                    ({ portIndex, port: output }, orderIndex) => (
                      <Draggable
                        draggableId={`${portIndex}`}
                        index={orderIndex}
                        key={`drag-output-${portIndex}`}>
                        {(providedDraggable, _) => (
                          <PortRow
                            ref={providedDraggable.innerRef}
                            key={output.name || `row-output[${portIndex}]`}
                            {...providedDraggable.draggableProps}>
                            <DetailsSection>
                              <PortDragHandle
                                canDrag={canDragPorts}
                                {...providedDraggable.dragHandleProps}
                              />
                              <DetailsInput
                                grow
                                testId={`block-output-name-${portIndex}`}
                                value={getPortDisplayName(
                                  selectedNode.type,
                                  output.name,
                                )}
                                placeholder={`Unnamed output ${portIndex}`}
                                onSubmitValue={(newName) =>
                                  renameOutput(orderIndex, newName)
                                }
                                disabled={
                                  !canEdit ||
                                  !canRenamePort(selectedNode, output)
                                }
                                validationRules={isValidBlockPortNameRuleSet(
                                  selectedNode,
                                  {
                                    id: portIndex,
                                    side: PortSide.Output,
                                  },
                                )}
                              />
                              <VisualizerToggler
                                nodeId={selectedNode.uuid}
                                parentPath={parentPath}
                                portIndex={portIndex}
                                output={output}
                              />
                              {canEdit &&
                                canDeletePort(
                                  selectedNode,
                                  minDynamicOutputCount,
                                  output,
                                  PortSide.Output,
                                ) && (
                                  <Button
                                    testId={`block-output-remove-button-${portIndex}`}
                                    variant={ButtonVariants.LargeTertiary}
                                    Icon={Remove}
                                    onClick={getRemoveOutput(portIndex)}
                                  />
                                )}
                            </DetailsSection>
                            <BlockPortParameters
                              parentPath={parentPath}
                              selectedNode={selectedNode}
                              canEdit={canEdit}
                              port={output}
                              index={orderIndex}
                              paramDefinitions={getPortParamDefinitions(
                                output,
                                orderIndex,
                              )}
                              portSide={PortSide.Output}
                            />
                          </PortRow>
                        )}
                      </Draggable>
                    ),
                  )}
                  {providedDroppable.placeholder}
                </PortDropArea>
              )}
            </Droppable>
          </PortList>
        </DragDropContext>
      )}
    </>
  );
};

export default BlockOutportDetails;
