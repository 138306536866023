import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { getSimulationRef } from 'app/sliceRefAccess/SimulationRef';
import { MINIMUM_ZOOM } from 'app/slices/cameraSlice';
import { SignalIndividualTypeAndTimeInfo } from 'app/slices/compilationAnalysisDataSlice';
import * as NVG from 'nanovg-js';
import { getNodePathName } from 'ui/modelEditor/portPathNameUtils';
import { RendererState } from 'ui/modelRendererInternals/modelRenderer';
import { calculateTextSize } from 'util/calculateTextSize';
import {
  RasterLoadState,
  getOrInitLoadImageFromStore,
} from './rasterTextureStore';

const LABEL_FONTSIZE = 8;
const LABEL_SCALEDOWN_THRESHOLD = 0.7;
const LABEL_SCALEUP_THRESHOLD = 1.75;
const MIN_LABEL_FONTSIZE = 0;

const labelIconSize = 0; // keeping this in case we want to bring back icons for a label
const textYOffset = 3;
const labelMarginLeft = 4;
const innerPaddingLeft = 4 + labelIconSize;
const innerPaddingRight = 4;
const baseYOffset = 1;

const drawLabel = (
  nvg: NVG.Context,
  rs: RendererState,
  rX: number,
  rY: number,
  offsetCount: number,
  text: string,
  fadeText?: boolean,
  iconId?: string,
  fadeIcon?: boolean,
  flipped?: boolean,
) => {
  const rasterMeta = getOrInitLoadImageFromStore(
    nvg,
    `${process.env.PUBLIC_URL}/assets/signal_icons/${iconId}.png`,
    iconId || '__label_no_image',
    2,
  );

  const scaledDownFontSize = Math.max(
    LABEL_FONTSIZE * (rs.zoom / LABEL_SCALEDOWN_THRESHOLD),
    MIN_LABEL_FONTSIZE,
  );
  const fontSize = rs.zoom * LABEL_FONTSIZE;
  /*
    rs.zoom < LABEL_SCALEDOWN_THRESHOLD
      ? scaledDownFontSize
      : rs.zoom > LABEL_SCALEUP_THRESHOLD
      ? LABEL_FONTSIZE * (rs.zoom / LABEL_SCALEUP_THRESHOLD)
      : LABEL_FONTSIZE;
  */

  const rawLabelOpacity =
    (rs.zoom - MINIMUM_ZOOM * 2) /
    (LABEL_SCALEDOWN_THRESHOLD - MINIMUM_ZOOM * 2);
  const labelOpacity = Math.max(0, Math.min(1, rawLabelOpacity));

  const { width: textWidthRaw } = calculateTextSize(text, {
    font: 'Archivo',
    fontSize: `${fontSize * 0.9}px`,
  });

  const textWidth = textWidthRaw * 1.1;

  const labelVPadding = 1;
  const labelHeight = fontSize + labelVPadding * 2 * rs.zoom;
  const labelTextOffset = labelVPadding;

  nvg.fontSize(fontSize);
  nvg.fontFace('archivo');
  nvg.textAlign(NVG.Align.LEFT | NVG.Align.TOP);

  const zoomedHPadding = innerPaddingLeft + innerPaddingRight * rs.zoom;
  const rectWidth = textWidth + zoomedHPadding;

  const rectX = flipped
    ? (rX - labelMarginLeft) * rs.zoom - rectWidth
    : (rX + labelMarginLeft) * rs.zoom;
  const rectY = (rY - baseYOffset) * rs.zoom;

  nvg.beginPath();
  nvg.roundedRect(rectX, rectY, rectWidth, labelHeight, 3);
  nvg.fillColor(nvg.RGBA(241, 243, 243, 255 * labelOpacity * 0.7));
  nvg.fill();

  const textX = flipped
    ? (rX - labelMarginLeft) * rs.zoom - textWidth
    : (rX + labelMarginLeft) * rs.zoom;

  const textY = (rY - baseYOffset + labelTextOffset) * rs.zoom;

  nvg.fillColor(nvg.RGBA(92, 111, 112, (fadeText ? 128 : 255) * labelOpacity));
  nvg.text(textX, textY, text);

  if (rasterMeta?.loadState === RasterLoadState.Loaded) {
    const imgPaint = nvg.imagePattern(
      rectX,
      rectY,
      labelIconSize,
      labelIconSize,
      0,
      rasterMeta.imageId,
      fadeIcon ? 0.5 : 1,
    );
    nvg.beginPath();
    nvg.rect(rectX, rectY, labelIconSize, labelIconSize);
    nvg.fillPaint(imgPaint);
    nvg.fill();
  }
};

export const signalTypeToDisplayTypeAndIcon = (
  sigTy?: SignalIndividualTypeAndTimeInfo,
): { displayType: string; icon: string; fadeIcon: boolean } => {
  if (!sigTy) {
    return {
      displayType: 'Unknown',
      icon: 'scalar_signal_type_icon',
      fadeIcon: true,
    };
  }

  if (sigTy.dimension.length > 0) {
    const isMatrix = sigTy.dimension.length > 1;
    const icon = isMatrix
      ? 'matrix_signal_type_icon'
      : 'vector_signal_type_icon';

    const dimensionString =
      sigTy.dimension.length > 0 ? ` (${sigTy.dimension})` : '';
    return {
      displayType: `${sigTy.dtype}${dimensionString}`,
      icon,
      fadeIcon: false,
    };
  }

  return {
    displayType: sigTy.dtype,
    icon: 'scalar_signal_type_icon',
    fadeIcon: true,
  };
};

export function drawSignalLabels(
  nvg: NVG.Context,
  rs: RendererState,
  worldX: number,
  worldY: number,
  offsetX: number,
  offsetY: number,
  nodeId: string,
  outputId: number,
): void {
  if (!rs.refs.current.uiFlags.showDatatypesInModel) return;

  const originNode =
    rs.refs.current.nodes[rs.refs.current.nodesIndexLUT[nodeId]];
  if (!originNode) return;

  const nodePathName = getNodePathName(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    { parentPath: getCurrentModelRef().submodelPath, nodeId },
  );

  const { datatypeAndDimensions } = (nodePathName &&
    getSimulationRef().compilationData.signalsData[nodePathName]) || {
    datatypeAndDimensions: undefined,
  };

  const portDataTypeAndDimensions = (datatypeAndDimensions || [])[outputId];
  const {
    displayType,
    icon: _icon,
    fadeIcon,
  } = signalTypeToDisplayTypeAndIcon(portDataTypeAndDimensions);

  const flipped = originNode.uiprops.directionality === 'left';

  drawLabel(
    nvg,
    rs,
    worldX + offsetX,
    worldY + offsetY,
    0,
    displayType,
    !datatypeAndDimensions || displayType === 'Unknown',
    undefined,
    fadeIcon,
    flipped,
  );
}
