import { PortVariant } from '@collimator/model-schemas-ts/src/schemas/SimulationModel';
import { HoverEntityType, MouseActions } from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import {
  LinkInstance,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { getSimulationRef } from 'app/sliceRefAccess/SimulationRef';
import { TimeModeType } from 'app/slices/compilationAnalysisDataSlice';
import { LinkRenderData } from 'app/utils/linkToRenderData';
import * as NVG from 'nanovg-js';
import { getNodePathName } from 'ui/modelEditor/portPathNameUtils';
import { RendererState } from 'ui/modelRendererInternals/modelRenderer';
import { isEntityInteractable } from './clickHandlers/isEntityInteractable';
import { LINE_COLORS } from './coloring';
import { drawPort } from './drawPort';
import {
  getOrInitLoadImageFromStore,
  RasterLoadState,
} from './rasterTextureStore';

const LINK_CURVE_RADIUS = 5;

type AcausalLinkData =
  | { isAcausal: false }
  | {
      isAcausal: true;
      domain: Extract<
        PortVariant,
        { variant_kind: 'acausal' }
      >['acausal_domain'];
    };

const getAcausalLinkData = (
  link: LinkInstance,
  srcNode?: NodeInstance,
  dstNode?: NodeInstance,
): AcausalLinkData => {
  let acausalLinkData: AcausalLinkData = { isAcausal: false };

  if ((srcNode && link.src) || (dstNode && link.dst)) {
    // foolishness to make TSC behave
    const usingConnection = link.src ? link.src! : link.dst!; // eslint-disable-line
    const usingNode = link.src ? srcNode! : dstNode!; // eslint-disable-line

    let checkingPortList = usingNode.inputs;
    if (link.src) {
      checkingPortList =
        link.src.port_side === 'inputs' ? usingNode.inputs : usingNode.outputs;
    } else if (link.dst) {
      checkingPortList =
        link.dst.port_side === 'outputs' ? usingNode.outputs : usingNode.inputs;
    }

    const checkingPort = checkingPortList[usingConnection.port];

    if (checkingPort?.variant?.variant_kind === 'acausal') {
      acausalLinkData = {
        isAcausal: true,
        domain: checkingPort.variant.acausal_domain,
      };
    }
  }

  return acausalLinkData;
};

export const getTimeModeColor = (rs: RendererState, timeMode: TimeModeType) => {
  if (timeMode.mode === 'Discrete') {
    if (!rs.refs.current.uiFlags.showAllDiscreteLevelsInModel) {
      return LINE_COLORS.discrete_1;
    }

    return (
      [
        LINE_COLORS.discrete_1,
        LINE_COLORS.discrete_2,
        LINE_COLORS.discrete_3,
        LINE_COLORS.discrete_4,
        LINE_COLORS.discrete_5,
      ][timeMode.stepLevel] || LINE_COLORS.normal
    );
    // TODO: we should determine how many discrete levels we need,
    // and then pre-compute a gradient (with OKLAB color space interpolation)
    // between 2 (or more) set colors, with each discrete level
    // being an evenly-distributed sampled color along that gradient
  }

  const modeColors = {
    Iterator: LINE_COLORS.iterator,
    Continuous: LINE_COLORS.continuous,
    Constant: LINE_COLORS.constant,
    Unknown: LINE_COLORS.normal,
    Acausal: LINE_COLORS.normal,
    Hybrid: LINE_COLORS.hybrid,
  };

  return modeColors[timeMode.mode] || LINE_COLORS.normal;
};

export const getLinkColor = (
  rs: RendererState,
  link: LinkInstance,
  acausalLinkData: AcausalLinkData,
  linkHasError: boolean,
  srcNode?: NodeInstance,
  _dstNode?: NodeInstance,
) => {
  const srcNodePathName =
    srcNode &&
    getNodePathName(
      getCurrentModelRef().topLevelNodes,
      getCurrentModelRef().submodels,
      { parentPath: getCurrentModelRef().submodelPath, nodeId: srcNode.uuid },
    );

  // 'timeMode' is the node-level time mode and not the signal-level
  const { datatypeAndDimensions, timeMode: _nodeTimeMode } = (srcNodePathName &&
    getSimulationRef().compilationData.signalsData[srcNodePathName]) || {
    datatypeAndDimensions: undefined,
    timeMode: undefined,
  };

  const timeMode: TimeModeType = (
    (datatypeAndDimensions || [])[link.src?.port ?? -1] || {}
  ).mode;

  const dontShowAcausalColors = rs.refs.current.uiFlags.showDatatypesInModel;

  let currentLinkConnectedColor =
    acausalLinkData.isAcausal && !dontShowAcausalColors
      ? LINE_COLORS[acausalLinkData.domain]
      : LINE_COLORS.normal;

  if (
    rs.refs.current.uiFlags.showDatatypesInModel &&
    link.src &&
    datatypeAndDimensions &&
    !acausalLinkData.isAcausal
  ) {
    const portDataTypeAndDimensions = datatypeAndDimensions[link.src.port];
    if (portDataTypeAndDimensions) {
      if (portDataTypeAndDimensions.dimension.length >= 3)
        currentLinkConnectedColor = LINE_COLORS.tensor;
      else if (portDataTypeAndDimensions.dimension.length === 2)
        currentLinkConnectedColor = LINE_COLORS.matrix;
      else if (portDataTypeAndDimensions.dimension.length === 1)
        currentLinkConnectedColor = LINE_COLORS.vector;
      else currentLinkConnectedColor = LINE_COLORS.scalar;
    }
  }

  if (
    rs.refs.current.uiFlags.showSignalDomainsInModel &&
    link.src &&
    !acausalLinkData.isAcausal
  ) {
    if (rs.refs.current.currentSubdiagramType === 'core.Iterator') {
      // TODO: we might want to rely on the backend for this
      // this is just for now until we're sure how the backend timemode data looks for iterators
      currentLinkConnectedColor = LINE_COLORS.iterator;
    } else if (timeMode) {
      currentLinkConnectedColor = getTimeModeColor(rs, timeMode);
    }
  }

  if (linkHasError) {
    currentLinkConnectedColor = LINE_COLORS.link_disconnected;
  }

  return currentLinkConnectedColor;
};

export function drawLink(
  nvg: NVG.Context,
  rs: RendererState,
  linkRenderData: LinkRenderData,
  offsetX: number,
  offsetY: number,
): void {
  const { vertexData, linkUuid } = linkRenderData;

  const link = rs.refs.current.links[rs.refs.current.linksIndexLUT[linkUuid]];
  if (!link) return;

  const linkType = link.uiprops.link_type;
  const tappedLink =
    linkType.connection_method === 'link_tap'
      ? rs.refs.current.links[
          rs.refs.current.linksIndexLUT[linkType.tapped_link_uuid || '']
        ]
      : undefined;

  const selected = rs.refs.current.selectedLinkIds.includes(linkUuid);
  const hovering =
    (rs.mouseState.state === MouseActions.DraggingLinkSegment &&
      rs.mouseState.linkUuid === linkUuid) ||
    (rs.hoveringEntity &&
      (rs.hoveringEntity.entityType === HoverEntityType.Link ||
        rs.hoveringEntity.entityType === HoverEntityType.FakeLinkSegment ||
        rs.hoveringEntity.entityType === HoverEntityType.TapPoint) &&
      rs.hoveringEntity.linkUuid === linkUuid &&
      isEntityInteractable(rs, rs.hoveringEntity));

  const highlight = selected || hovering;

  const userDrawingThisLink =
    (rs.mouseState.state === MouseActions.DrawingLinkFromStart ||
      rs.mouseState.state === MouseActions.DrawingLinkFromEnd) &&
    rs.mouseState.linkUuid === link.uuid;

  const srcNode = link.src
    ? rs.refs.current.nodes[rs.refs.current.nodesIndexLUT[link.src.node]]
    : undefined;
  const dstNode = link.dst
    ? rs.refs.current.nodes[rs.refs.current.nodesIndexLUT[link.dst.node]]
    : undefined;

  const srcPorts =
    link.src?.port_side === 'inputs' ? srcNode?.inputs : srcNode?.outputs;
  const dstPorts =
    link.dst?.port_side === 'outputs' ? dstNode?.outputs : dstNode?.inputs;

  const srcDisconnected =
    !link.src || (srcPorts && srcPorts.length - 1 < (link.src?.port || -1));
  const dstDisconnected =
    !link.dst || (dstPorts && dstPorts.length - 1 < (link.dst?.port || -1));

  const linkIsDisconnected =
    (!userDrawingThisLink && (!link.src || !link.dst)) ||
    srcDisconnected ||
    dstDisconnected;

  const linkIsInLoopError = rs.refs.current.algebraicLoopLinkIdSet.has(
    link.uuid,
  );

  const acausalLinkData = getAcausalLinkData(link, srcNode, dstNode);

  const currentLinkConnectedColor = getLinkColor(
    rs,
    link,
    acausalLinkData,
    linkIsInLoopError,
    srcNode,
    dstNode,
  );

  if (!vertexData[0]) return;

  const [firstX, firstY] = vertexData[0].coordinate;

  if (tappedLink && highlight) {
    nvg.beginPath();
    nvg.fillColor(selected ? LINE_COLORS.selected : LINE_COLORS.highlight);
    nvg.circle(
      (firstX + offsetX) * rs.zoom,
      (firstY + offsetY) * rs.zoom,
      6 * rs.zoom,
    );
    nvg.fill();
  }

  nvg.beginPath();
  if (vertexData.length > 1) {
    nvg.moveTo((firstX + offsetX) * rs.zoom, (firstY + offsetY) * rs.zoom);

    for (let i = 1; i < vertexData.length; i++) {
      const [vX, vY] = vertexData[i].coordinate;

      if (vertexData[i + 1]) {
        const [pVX, pVY] = vertexData[i - 1].coordinate;
        const [nVX, nVY] = vertexData[i + 1].coordinate;

        const prevSegmentDistance =
          vY === pVY ? Math.abs(pVX - vX) : Math.abs(pVY - vY);
        const nextSegmentDistance =
          vY === nVY ? Math.abs(nVX - vX) : Math.abs(nVY - vY);

        const halfSegmentDistance =
          Math.min(prevSegmentDistance, nextSegmentDistance) / 2;

        nvg.arcTo(
          (vX + offsetX) * rs.zoom,
          (vY + offsetY) * rs.zoom,
          (nVX + offsetX) * rs.zoom,
          (nVY + offsetY) * rs.zoom,
          Math.min(halfSegmentDistance, LINK_CURVE_RADIUS) * rs.zoom,
        );
      } else {
        nvg.lineTo((vX + offsetX) * rs.zoom, (vY + offsetY) * rs.zoom);
      }
    }
  }

  if (highlight) {
    nvg.strokeWidth(6 * rs.zoom); // 2pt width outer stroke; 2pt for each side
    nvg.strokeColor(selected ? LINE_COLORS.selected : LINE_COLORS.highlight);
    nvg.stroke();
  }

  nvg.strokeWidth(2 * rs.zoom);
  if (linkIsDisconnected && !userDrawingThisLink) {
    nvg.strokeColor(LINE_COLORS.link_disconnected);
  } else {
    nvg.strokeColor(currentLinkConnectedColor);
  }
  nvg.stroke();

  // draw the dot to represent the link tap
  if (tappedLink) {
    nvg.beginPath();
    if (linkIsDisconnected && !userDrawingThisLink) {
      nvg.fillColor(LINE_COLORS.link_disconnected);
    } else {
      nvg.fillColor(currentLinkConnectedColor);
    }
    nvg.circle(
      (firstX + offsetX) * rs.zoom,
      (firstY + offsetY) * rs.zoom,
      4 * rs.zoom,
    );
    nvg.fill();

    if (rs.refs.current.uiFlags.renderDebug) {
      nvg.fontSize(10);
      nvg.textLineHeight(16);
      nvg.fontFace('archivo');
      nvg.textAlign(NVG.Align.LEFT | NVG.Align.TOP);
      nvg.fillColor(nvg.RGB(0, 0, 0));
      nvg.text(
        (firstX + offsetX) * rs.zoom + 5,
        (firstY + offsetY) * rs.zoom + 5,
        `src: ${link.src?.node || 'none'}`,
        null,
      );
    }
  }

  if (
    !tappedLink &&
    (srcDisconnected ||
      (rs.mouseState.state === MouseActions.DrawingLinkFromStart &&
        rs.mouseState.linkUuid === link.uuid))
  ) {
    const hanging = srcDisconnected && !userDrawingThisLink;

    nvg.beginPath();
    if (hanging) {
      nvg.fillColor(LINE_COLORS.link_disconnected);
    } else {
      nvg.fillColor(currentLinkConnectedColor);
    }
    nvg.circle(
      (firstX + offsetX) * rs.zoom,
      (firstY + offsetY) * rs.zoom,
      4 * rs.zoom,
    );
    nvg.fill();
  }

  if (
    dstDisconnected ||
    (rs.mouseState.state === MouseActions.DrawingLinkFromEnd &&
      rs.mouseState.linkUuid === link.uuid)
  ) {
    const [finalX, finalY] = vertexData[vertexData.length - 1].coordinate;
    const [secondFinalX] = (vertexData[vertexData.length - 2] || {})
      .coordinate || [0];

    const flipped = finalX < secondFinalX;

    const portShapeX = userDrawingThisLink
      ? finalX
      : link.uiprops?.hang_coord_end?.x || finalX;
    const portShapeY = userDrawingThisLink
      ? finalY
      : link.uiprops?.hang_coord_end?.y || finalY;

    drawPort(
      nvg,
      rs,
      portShapeX + offsetX,
      portShapeY + offsetY,
      rs.zoom,
      PortSide.Input,
      true,
      false,
      false,
      flipped,
      currentLinkConnectedColor,
      userDrawingThisLink,
      false, // hollow
      linkIsInLoopError, // hasError
      acausalLinkData.isAcausal ? acausalLinkData.domain : undefined,
    );
  }

  const occlusionPoints = rs.linksOcclusionPointLUT[linkRenderData.linkUuid];

  if (occlusionPoints) {
    const rawScale = Math.round(window.devicePixelRatio * rs.zoom);
    const scale = rawScale > 2 ? 4 : 2;
    const vertRasterID = `link_occlusion_v_${scale}x`;
    const horizRasterID = `link_occlusion_h_${scale}x`;

    for (let i = 0; i < occlusionPoints.length; i++) {
      const occlusionPoint = occlusionPoints[i];

      const rasterID =
        occlusionPoint.orientation == 'vertical' ? vertRasterID : horizRasterID;

      const rasterMeta = getOrInitLoadImageFromStore(
        nvg,
        `${process.env.PUBLIC_URL}/assets/${rasterID}.png`,
        rasterID,
        scale,
      );

      if (rasterMeta?.loadState === RasterLoadState.Loaded) {
        const rw = (rasterMeta.width / scale) * rs.zoom;
        const rh = (rasterMeta.height / scale) * rs.zoom;
        const midX = occlusionPoint.x + offsetX;
        const midY = occlusionPoint.y + offsetY;
        const rx = midX * rs.zoom - rw / 2;
        const ry = midY * rs.zoom - rh / 2;
        const imgPaint = nvg.imagePattern(
          rx,
          ry,
          rw,
          rh,
          0,
          rasterMeta.imageId,
          1,
        );
        nvg.beginPath();
        nvg.rect(rx, ry, rw, rh);
        nvg.fillPaint(imgPaint);
        nvg.fill();
      }
    }
  }
}
