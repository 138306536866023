import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { t } from '@lingui/macro';
import { useGetSimulationProcessResultsReadByUuidQuery } from 'app/apiGenerated/generatedApi';
import { SimulationResultsS3Url } from 'app/apiGenerated/generatedApiTypes';
import { Coordinate } from 'app/common_types/Coordinate';
import { HoverEntityType } from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { ModelDiagram } from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { getSimulationRef } from 'app/sliceRefAccess/SimulationRef';
import {
  modelActions,
  selectCurrentSubdiagramType,
} from 'app/slices/modelSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { getLinkName } from 'app/utils/linkUtils';
import { renderConstants } from 'app/utils/renderConstants';
import React from 'react';
import { getThemeValue } from 'theme/themes';
import { Constant, Continuous, Discrete } from 'ui/common/Icons/Small';
import Input from 'ui/common/Input/Input';
import { isValidBlockNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import { SimpleTextTooltip } from 'ui/common/Tooltip/PortalTextTooltip';
import { ManualTooltip } from 'ui/common/Tooltip/PortalTooltip';
import { TooltipPlacement } from 'ui/common/Tooltip/tooltipTypes';
import { RCContextMenu } from 'ui/modelEditor/RightClickMenu';
import {
  getPortPathName,
  getPortRecordMode,
} from 'ui/modelEditor/portPathNameUtils';
import { LINE_COLORS, LineColorType } from 'ui/modelRendererInternals/coloring';
import { getVisualNodeHeight } from 'ui/modelRendererInternals/getVisualNodeHeight';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { nvgColorToHex } from 'util/nvgColorHexConvert';
import { CommandPalette } from './CommandPalette';
import { ModelCommentsDisplay } from './ModelCommentsDisplay';
import { SimTutorialPopup } from './SimTutorialPopup';
import { useCurrentDiagramAcausalInfo } from './useCurrentDiagramAcausalInfo';
import { useVisualizerPrefs } from './useVisualizerPrefs';

const RendererOverlayContainer = styled.div`
  isolation: isolate;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
`;

const WorldSpaceOverlayOuterContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
`;
const WorldSpaceOverlayScaler = styled.div<{
  scale: number;
}>`
  position: absolute;
  pointer-events: none;

  ${({ scale }) => `
    transform: scale(${scale});

    .inverted-scale {
      transform: scale(${1 / scale});
    }
  `}
`;

const WorldSpaceOverlayPosAnchor = styled.div<{ x: number; y: number }>`
  ${({ x, y }) => `
    transform: translate(${x}px, ${y}px);
  `}

  position: absolute;
  height: 0;
  width: 0;
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
`;

const SignalPreviewWrapper = styled.div`
  height: 80px;
  aspect-ratio: 7/1;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `
  border-radius: ${theme.spacing.xsmall};
  background-color: ${theme.colors.grey[5]};
  color: ${theme.colors.grey[85]};
  margin-bottom: ${theme.spacing.normal};
  `}
`;

const SignalPreviewImage = styled.img`
  ${({ theme }) => `
  border-radius: ${theme.spacing.xsmall};`}
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const SignalTooltipTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SignalTooltipIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignalTooltipText = styled.div`
  font-weight: 300;
  margin: auto;
`;

const SignalTooltipContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EntityNameEditInputWrapper = styled.div<{
  entityX: number;
  entityY: number;
  entityWidth?: number;
  entityHeight: number;
  labelTop: boolean;
}>`
  ${({ entityX: x, entityY: y, entityWidth, entityHeight, labelTop }) => `
    width: ${(entityWidth || renderConstants.BLOCK_MIN_WIDTH) + 20}px;
    transform: translate(${x - 10}px, ${
    labelTop ? y - 28 : y + entityHeight
  }px);
  `}
`;

function getNode(
  currentDiagram: ModelDiagram | null,
  editingNodeNameUUID: string | null,
) {
  if (!editingNodeNameUUID) return null;

  if (!currentDiagram) return null;

  return (
    currentDiagram.nodes.find((n) => n.uuid === editingNodeNameUUID) || null
  );
}

const NodeNameEditInput = ({
  isDiagramReadonly,
}: {
  isDiagramReadonly: boolean;
}) => {
  const dispatch = useAppDispatch();

  const editingNodeNameUUID = useAppSelector(
    (state) => state.uiFlags.editingNodeNameUUID,
  );

  const currentDiagram = useAppSelector(
    (state) => state.modelMetadata.currentDiagram,
  );
  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present.currentSubmodelPath,
  );

  const node = getNode(currentDiagram, editingNodeNameUUID);
  const currentModelNodes = currentDiagram?.nodes;

  const visualizerPrefs = useVisualizerPrefs();

  const onSubmitValue = (newName: string) => {
    if (!editingNodeNameUUID || isDiagramReadonly) return;

    visualizerPrefs.removeBlockPortsFromChart(
      [
        {
          nodeId: editingNodeNameUUID,
          parentPath: currentSubmodelPath,
        },
      ],
      true,
    );

    // TODO update block name in table
    dispatch(
      modelActions.changeBlockName({
        parentPath: currentSubmodelPath,
        blockUuid: editingNodeNameUUID,
        newName,
      }),
    );

    dispatch(uiFlagsActions.setUIFlag({ editingNodeNameUUID: null }));
  };

  const onCancel = () => {
    dispatch(uiFlagsActions.setUIFlag({ editingNodeNameUUID: null }));
  };

  if (!node) return null;

  return (
    <EntityNameEditInputWrapper
      entityX={node.uiprops.x}
      entityY={node.uiprops.y}
      entityHeight={getVisualNodeHeight(node)}
      labelTop={node.uiprops.label_position === 'top'}>
      <Input
        autoFocus
        value={node.name}
        onSubmitValue={onSubmitValue}
        onCancel={onCancel}
        disabled={isDiagramReadonly}
        validationRules={isValidBlockNameRuleSet(currentModelNodes, node.uuid)}
      />
    </EntityNameEditInputWrapper>
  );
};

function getAnnotation(
  currentDiagram: ModelDiagram | null,
  editingAnnotationTextUUID: string | null,
) {
  if (!editingAnnotationTextUUID) return null;

  if (!currentDiagram) return null;

  return (
    (currentDiagram.annotations || []).find(
      (a) => a.uuid === editingAnnotationTextUUID,
    ) || null
  );
}

const AnnoNameEditInput = ({
  isDiagramReadonly,
}: {
  isDiagramReadonly: boolean;
}) => {
  const dispatch = useAppDispatch();

  const editingAnnoNameUUID = useAppSelector(
    (state) => state.uiFlags.editingAnnotationTextUUID,
  );

  const currentDiagram = useAppSelector(
    (state) => state.modelMetadata.currentDiagram,
  );
  const annotation = getAnnotation(currentDiagram, editingAnnoNameUUID);

  const onSubmitValue = (newText: string) => {
    if (!editingAnnoNameUUID || isDiagramReadonly) return;

    dispatch(
      modelActions.setAnnotationText({
        uuid: editingAnnoNameUUID,
        text: newText,
      }),
    );

    dispatch(uiFlagsActions.setUIFlag({ editingAnnotationTextUUID: null }));
  };

  const onCancel = () => {
    dispatch(uiFlagsActions.setUIFlag({ editingAnnotationTextUUID: null }));
  };

  if (!annotation) return null;

  return (
    <EntityNameEditInputWrapper
      entityX={annotation.x}
      entityY={annotation.y}
      entityWidth={annotation.grid_width * renderConstants.GRID_UNIT_PXSIZE}
      entityHeight={annotation.grid_height * renderConstants.GRID_UNIT_PXSIZE}
      labelTop={annotation.label_position === 'top'}>
      <Input
        autoFocus
        value={annotation.text}
        disabled={isDiagramReadonly}
        onSubmitValue={onSubmitValue}
        onCancel={onCancel}
      />
    </EntityNameEditInputWrapper>
  );
};

const OverlaySignalTooltip = () => {
  const contentEl = React.useRef<HTMLDivElement>(null);
  const contentRect = contentEl?.current?.getBoundingClientRect();
  const theme = useTheme();

  const [[mouseX, mouseY], setMousePos] = React.useState<
    [x: number, y: number]
  >([0, 0]);

  const { simulationSummary } = useAppSelector((state) => ({
    simulationSummary: state.project.simulationSummary,
  }));

  const simRecordModeAll = useAppSelector(
    (state) => state.model.present.configuration.record_mode === 'all',
  );

  const mouseMoveRAF = React.useRef<number>(0);
  React.useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      if (mouseMoveRAF.current) {
        window.cancelAnimationFrame(mouseMoveRAF.current);
      }
      mouseMoveRAF.current = window.requestAnimationFrame(() =>
        setMousePos([e.x, e.y]),
      );
    };

    window.addEventListener('mousemove', onMouseMove);
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      mouseMoveRAF.current = 0;
    };
  }, [setMousePos, mouseMoveRAF]);

  const signalPreview = React.useRef<{
    path: string;
    /** Not exactly accurate since a model and settings can change after sim run */
    recorded: boolean;
  }>();

  const {
    data: signalResults,
    isFetching: isSignalPreviewUrlFetching,
    isSuccess: isSignalPreviewUrlSuccess,
  } = useGetSimulationProcessResultsReadByUuidQuery(
    {
      threshold: 2500,
      downsamplingAlgorithm: 'LTTB',
      modelUuid: simulationSummary?.model_uuid || '',
      simulationUuid: simulationSummary?.uuid || '',
      signalNames: signalPreview.current?.path,
      genCharts: true,
      finalValues: true,
      combinedVectors: true,
    },
    {
      skip:
        !simulationSummary ||
        !simulationSummary.results_available ||
        !signalPreview.current ||
        !signalPreview.current?.recorded,
    },
  );

  let signalPreviewUrl: SimulationResultsS3Url | undefined;
  let finalValue: string | undefined;
  if (
    !isSignalPreviewUrlFetching &&
    isSignalPreviewUrlSuccess &&
    signalPreview.current
  ) {
    // .png is the preview image file name
    signalPreviewUrl = signalResults.s3_urls.find((s3_url) =>
      s3_url.name.endsWith('png'),
    );

    if (signalResults.final_values && signalResults.final_values.length > 0) {
      finalValue = signalResults.final_values[0].value.toString();
    } else {
      console.error('Final values for signal preview not found.');
    }
  }

  if (
    !rendererState ||
    !(
      rendererState.hoveringEntity?.entityType ===
        HoverEntityType.FakeLinkSegment ||
      rendererState.hoveringEntity?.entityType === HoverEntityType.Link ||
      rendererState.hoveringEntity?.entityType === HoverEntityType.Port ||
      rendererState.hoveringEntity?.entityType === HoverEntityType.TapPoint
    )
  ) {
    signalPreview.current = undefined;
    return null;
  }

  let hoveringLinkId: string | undefined;
  let originNodeUuid = '';
  let outputId = -1;

  switch (rendererState.hoveringEntity.entityType) {
    case HoverEntityType.TapPoint:
      const linkUuid = rendererState.hoveringEntity.linkUuid;
      const linkIndex = rendererState.refs.current.linksIndexLUT[linkUuid];
      const link = rendererState.refs.current.links[linkIndex];
      if (link && link.src) {
        originNodeUuid = link.src.node;
        outputId = link.src.port;
      }
      hoveringLinkId = linkUuid;
      break;
    case HoverEntityType.FakeLinkSegment:
    case HoverEntityType.Link:
      if (rendererState.hoveringEntity.link.src) {
        originNodeUuid = rendererState.hoveringEntity.link.src.node;
        outputId = rendererState.hoveringEntity.link.src.port;
      }
      hoveringLinkId = rendererState.hoveringEntity.linkUuid;
      break;
    case HoverEntityType.Port:
      const port = rendererState.hoveringEntity.port;
      const connections =
        rendererState.refs.current.connectedPortLUT[port.blockUuid];

      originNodeUuid = port.blockUuid;
      outputId = port.portId;
      if (port.side === PortSide.Output && connections) {
        const connection = connections.find(
          (p) => p.side === PortSide.Output && p.portId === port.portId,
        );
        const linkIndex =
          rendererState.refs.current.linksIndexLUT[connection?.linkUuid || ''];
        const connectedLink = rendererState.refs.current.links[linkIndex];

        if (connectedLink) {
          hoveringLinkId = connectedLink.uuid;
        }
      }

      if (port.side === PortSide.Input && connections) {
        const connection = connections.find(
          (p) => p.side === PortSide.Input && p.portId === port.portId,
        );
        const linkIndex =
          rendererState.refs.current.linksIndexLUT[connection?.linkUuid || ''];
        const connectedLink = rendererState.refs.current.links[linkIndex];

        if (connectedLink && connectedLink.src) {
          hoveringLinkId = connectedLink.uuid;
          originNodeUuid = connectedLink.src.node;
          outputId = connectedLink.src.port;
        }
      }
      break;
  }

  let isAcausalLink = false;

  if (hoveringLinkId) {
    const hoveringActualLinkIndex =
      rendererState.refs.current.linksIndexLUT[hoveringLinkId];
    const actualLink =
      rendererState.refs.current.links[hoveringActualLinkIndex];

    const actualSrcNode =
      rendererState.refs.current.nodes[
        rendererState.refs.current.nodesIndexLUT[actualLink?.src?.node || '']
      ];
    const actualDstNode =
      rendererState.refs.current.nodes[
        rendererState.refs.current.nodesIndexLUT[actualLink?.dst?.node || '']
      ];

    const isSrcAcausal =
      actualLink.src?.port_side === 'inputs'
        ? actualSrcNode.inputs[actualLink.src.port]?.variant?.variant_kind ===
          'acausal'
        : actualLink.src?.port_side === 'outputs'
        ? actualSrcNode.outputs[actualLink.src.port]?.variant?.variant_kind ===
          'acausal'
        : false;
    const isDstAcausal =
      actualLink.dst?.port_side === 'inputs'
        ? actualDstNode.inputs[actualLink.dst.port]?.variant?.variant_kind ===
          'acausal'
        : actualLink.dst?.port_side === 'outputs'
        ? actualDstNode.outputs[actualLink.dst.port]?.variant?.variant_kind ===
          'acausal'
        : false;

    isAcausalLink = isSrcAcausal || isDstAcausal;
  }

  if (isAcausalLink) {
    signalPreview.current = undefined;
    return null;
  }

  // For displaying to user
  const portPathName = getPortPathName(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    {
      parentPath: getCurrentModelRef().submodelPath,
      nodeId: originNodeUuid,
      portIndex: outputId,
    },
    { includePortNameForNonSubmodels: false },
  );
  const portRecordMode = getPortRecordMode(
    getCurrentModelRef().topLevelNodes,
    getCurrentModelRef().submodels,
    {
      parentPath: getCurrentModelRef().submodelPath,
      nodeId: originNodeUuid,
      portIndex: outputId,
    },
  );

  // For requesting signal results from backend
  const fullPortPathName =
    getPortPathName(
      getCurrentModelRef().topLevelNodes,
      getCurrentModelRef().submodels,
      {
        parentPath: getCurrentModelRef().submodelPath,
        nodeId: originNodeUuid,
        portIndex: outputId,
      },
      { includePortNameForNonSubmodels: true },
    ) || undefined;

  signalPreview.current = fullPortPathName
    ? {
        path: fullPortPathName,
        recorded: simRecordModeAll || !!portRecordMode,
      }
    : undefined;

  const { timeMode } = (portPathName &&
    getSimulationRef().compilationData.signalsData[portPathName]) || {
    timeMode: undefined,
    datatypeAndDimensions: undefined,
  };

  const LabelIcon = {
    Discrete,
    Continuous,
    Constant,
    Unknown: Continuous,
    Hybrid: Continuous,
    Acausal: Continuous,
  }[timeMode?.mode || 'Unknown'];
  const fade = timeMode?.mode === 'Unknown' || !timeMode;

  const tooltipLabelName =
    (hoveringLinkId && getLinkName(hoveringLinkId)) || 'No name';

  const signalPreviewImg = (
    <SignalPreviewWrapper>
      {isSignalPreviewUrlFetching ? (
        <div>
          {t({
            id: 'rendererOverlay.signalPreview.loading',
            message: 'Loading signal preview...',
          })}
        </div>
      ) : !isSignalPreviewUrlSuccess || !!signalPreviewUrl?.error ? (
        <div>
          {t({
            id: 'rendererOverlay.signalPreview.unavailable',
            message: 'Preview unavailable',
          })}
        </div>
      ) : (
        signalPreviewUrl && <SignalPreviewImage src={signalPreviewUrl.url} />
      )}
    </SignalPreviewWrapper>
  );

  const tooltipLabelContent = (
    <SignalTooltipContentWrapper>
      {!!signalPreview.current?.recorded && signalPreviewImg}
      <SignalTooltipTextWrapper>
        <SignalTooltipIconContainer>
          <LabelIcon
            fill={timeMode?.mode === 'Discrete' ? undefined : '#fff'}
            stroke={timeMode?.mode === 'Discrete' ? '#fff' : undefined}
            opacity={fade ? 0.4 : 1}
          />
          {tooltipLabelName}
        </SignalTooltipIconContainer>
        <SignalTooltipText>
          {signalPreview.current?.recorded
            ? `Final Value: ${
                isSignalPreviewUrlFetching ? 'is loading...' : finalValue
              }`
            : 'Signal not recorded'}
        </SignalTooltipText>
      </SignalTooltipTextWrapper>
    </SignalTooltipContentWrapper>
  );

  const tooltipTop =
    mouseY - (contentRect?.height || 0) - getThemeValue(theme.spacing.normal);
  const tooltipLeft = mouseX - (contentRect?.width || 0) / 2;

  return (
    <ManualTooltip
      isHidden={!contentEl.current}
      delayMs={400}
      isShowingPointer
      invertedColor
      uninteractable
      top={tooltipTop}
      left={tooltipLeft}
      contentElRef={contentEl}
      placement={TooltipPlacement.TOP_CENTER}
      triggerRect={contentRect}
      contentRect={contentRect}>
      <SimpleTextTooltip invertedColor>{tooltipLabelContent}</SimpleTextTooltip>
    </ManualTooltip>
  );
};

const ColorLegendContainer = styled.div<{
  sidebarOpen: boolean;
}>(({ theme, sidebarOpen }) => ({
  pointerEvents: 'none',
  position: 'absolute',
  bottom: theme.spacing.normal,
  left: sidebarOpen ? theme.sizes.leftSidebarWidth : 0,
  padding: theme.spacing.normal,
  marginLeft: theme.spacing.normal,
  marginBottom: 0,
  background: 'rgba(255, 255, 255, 0.4)',
  transition:
    'left 0.15s ease-in, bottom 0.15s ease-in, margin-bottom 0.15s ease-in',
}));

const ColorLegendItem = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 500,
  height: 16,
  marginBottom: theme.spacing.small,
  '&:last-child': { marginBottom: 0 },
}));

const ColorLegendColor = styled.div<{ color: string }>(({ theme, color }) => ({
  borderRadius: 1,
  height: 8,
  width: 24,
  background: `#${color}`,
  marginRight: theme.spacing.normal,
}));

const ColorLegendName = styled.div(({ theme }) => ({
  color: theme.colors.text.secondary,
}));

const discreteLabel = t({ id: 'colorLegend.discrete', message: 'Discrete' });
const getDiscreteLegendColor = (level: number) => {
  const linkColorKey = `discrete_${level + 1}` as keyof typeof LINE_COLORS;
  return LINE_COLORS[linkColorKey] || LINE_COLORS.discrete_1;
};

const iteratorLegendColors = [
  {
    color: nvgColorToHex(LINE_COLORS.iterator),
    name: t({ id: 'colorLegend.iterator', message: 'Iterator' }),
  },
];

const legendNames: Record<string, string> = {
  constant: t({ id: 'colorLegend.constant', message: 'Constant' }),
  continuous: t({ id: 'colorLegend.continuous', message: 'Continuous' }),
  scalar: t({ id: 'colorLegend.scalar', message: 'Scalar' }),
  vector: t({ id: 'colorLegend.vector', message: 'Vector' }),
  matrix: t({ id: 'colorLegend.matrix', message: 'Matrix' }),
  tensor: t({ id: 'colorLegend.tensor', message: 'Tensor' }),
  multirate: t({ id: 'colorLegend.multirate', message: 'Multi-rate' }),
  electrical: t({ id: 'colorLegend.electrical', message: 'Electrical' }),
  magnetic: t({ id: 'colorLegend.magnetic', message: 'Magnetic' }),
  thermal: t({ id: 'colorLegend.thermal', message: 'Thermal' }),
  rotational: t({ id: 'colorLegend.rotational', message: 'Rotational' }),
  translational: t({
    id: 'colorLegend.translational',
    message: 'Translational',
  }),
  hydraulic: t({ id: 'colorLegend.hydraulic', message: 'Hydraulic' }),
  pneumatic: t({ id: 'colorLegend.pneumatic', message: 'Pneumatic' }),
  // Hybrid means "mixed time modes" (eg. discrete+continuous in the same block)
  // There is no such thing as 'mixed domain' at the moment.
  hybrid: t({ id: 'colorLegend.hybrid', message: 'Hybrid' }),
};

const legendColors = (
  legendStyle: 'domains' | 'dimensions',
  acausalDomains: Set<string>,
  discreteSteps: Array<number>,
  showAllDiscreteLevelsInModel: boolean,
  hasHybridTimeMode: boolean,
) => {
  if (legendStyle === 'dimensions') {
    return [
      { color: nvgColorToHex(LINE_COLORS.scalar), name: legendNames.scalar },
      { color: nvgColorToHex(LINE_COLORS.vector), name: legendNames.vector },
      { color: nvgColorToHex(LINE_COLORS.matrix), name: legendNames.matrix },
      { color: nvgColorToHex(LINE_COLORS.tensor), name: legendNames.tensor },
    ];
  }

  const rates = [
    { color: nvgColorToHex(LINE_COLORS.constant), name: legendNames.constant },
    {
      color: nvgColorToHex(LINE_COLORS.continuous),
      name: legendNames.continuous,
    },
  ];

  if (showAllDiscreteLevelsInModel) {
    // NOTE: currently wildcat advertises mixed time modes as 'Hybrid' and does
    // not currently support multi rate information (specific dt). So this is
    // not tested at the moment (also, Hybrid and Multi-rate look the same but
    // should be different).
    discreteSteps.forEach((stepVal, i) => {
      rates.push({
        color: nvgColorToHex(getDiscreteLegendColor(i)),
        name: `${discreteLabel} ${stepVal}`,
      });
    });
    rates.push({
      color: nvgColorToHex(LINE_COLORS.multirate),
      name: legendNames.multirate,
    });
  } else {
    rates.push({
      color: nvgColorToHex(LINE_COLORS.discrete_1),
      name: discreteLabel,
    });
  }

  if (hasHybridTimeMode) {
    rates.push({
      color: nvgColorToHex(LINE_COLORS.hybrid),
      name: legendNames.hybrid,
    });
  }

  const domainToLegend = (key: LineColorType) => ({
    color: nvgColorToHex(LINE_COLORS[key] || LINE_COLORS.normal),
    name: legendNames[key] || key,
  });

  const domainSortingOrder: Array<keyof typeof LINE_COLORS> = [
    'electrical',
    'magnetic',
    'thermal',
    'rotational',
    'translational',
    'hydraulic',
    'pneumatic',
  ];

  const domains = domainSortingOrder
    .filter((domain) => acausalDomains.has(domain))
    .map(domainToLegend);

  return [...rates, ...domains];
};

// ColorLegend placed relative to the Footer instead of inside the
// RendererOverlay. Hack to make positioning easy.
export const ColorLegend = ({
  legendStyle,
}: {
  legendStyle: 'domains' | 'dimensions';
}) => {
  const { isLeftSidebarOpen } = useAppSelector((state) => ({
    isLeftSidebarOpen: state.uiFlags.isLeftSidebarOpen,
  }));

  const isIterator = useAppSelector(
    (state) =>
      selectCurrentSubdiagramType(state.model.present) === 'core.Iterator',
  );

  const discreteSteps = useAppSelector(
    (state) => state.compilationData.discreteStepsOrderedByLevel,
  );
  const hasHybridTimeMode = useAppSelector(
    (state) => state.compilationData.hasHybridTimeMode,
  );
  const showAllDiscreteLevelsInModel = useAppSelector(
    (state) => state.uiFlags.showAllDiscreteLevelsInModel,
  );

  const acausalDomains = useCurrentDiagramAcausalInfo().visibleAcausalDomains;

  const activeLegendColors = isIterator
    ? iteratorLegendColors
    : legendColors(
        legendStyle,
        acausalDomains,
        discreteSteps,
        showAllDiscreteLevelsInModel,
        hasHybridTimeMode,
      );

  return (
    <ColorLegendContainer sidebarOpen={isLeftSidebarOpen}>
      {activeLegendColors.map(({ color, name }) => (
        <ColorLegendItem key={name}>
          <ColorLegendColor color={color} />
          <ColorLegendName>{name}</ColorLegendName>
        </ColorLegendItem>
      ))}
    </ColorLegendContainer>
  );
};

type Camera = { zoom: number; coord: Coordinate };
type CameraChangeCallback = (newCam: Camera) => void;
let overlayCamChangeCallbacks: CameraChangeCallback[] = [];
const registerOverlayCameraChangeCallback = (
  newCallback: CameraChangeCallback,
) => {
  overlayCamChangeCallbacks.push(newCallback);
};
const unregisterOverlayCameraChangeCallback = (
  cbToUnregister: CameraChangeCallback,
) => {
  overlayCamChangeCallbacks = overlayCamChangeCallbacks.filter(
    (cb) => cb !== cbToUnregister,
  );
};
export const overlayCameraExternalChange = (newCam: Camera) => {
  for (let i = 0; i < overlayCamChangeCallbacks.length; i++) {
    overlayCamChangeCallbacks[i](newCam);
  }
};

const WorldSpaceOverlay = ({
  children,
  parentPath,
}: {
  children: React.ReactNode;
  parentPath: string;
}) => {
  const { zoom: startingZoom, coord: startingCoord } = useAppSelector(
    (state) => state.camera,
  );
  const [camera, setCamera] = React.useState<Camera>({
    zoom: startingZoom,
    coord: startingCoord,
  });

  const setCamRef = React.useRef(setCamera);
  setCamRef.current = setCamera;

  React.useEffect(() => {
    const changer = (newCam: Camera) => setCamRef.current(newCam);
    registerOverlayCameraChangeCallback(changer);

    return () => {
      unregisterOverlayCameraChangeCallback(changer);
    };
  }, [setCamRef]);

  React.useEffect(() => {
    // HACK: easiest way to get the current camera when the subdocument view state changes.
    // settimeout just gives us a couple of ticks in case the renderer's internals are behind
    setTimeout(() => {
      if (!rendererState) return;
      setCamera({
        zoom: rendererState.zoom,
        coord: rendererState.camera,
      });
    }, 50);
  }, [parentPath, setCamera]); // eslint-disable-line

  // this one is for initial load, which needs a longer time to wait.
  // not foolproof but works well enough in practice
  React.useEffect(() => {
    setTimeout(() => {
      if (!rendererState) return;
      setCamera({
        zoom: rendererState.zoom,
        coord: rendererState.camera,
      });
    }, 250);
  }, [setCamera]);

  return (
    <WorldSpaceOverlayOuterContainer>
      <WorldSpaceOverlayScaler scale={camera.zoom}>
        <WorldSpaceOverlayPosAnchor x={camera.coord.x} y={camera.coord.y}>
          {children}
        </WorldSpaceOverlayPosAnchor>
      </WorldSpaceOverlayScaler>
    </WorldSpaceOverlayOuterContainer>
  );
};

export const RendererOverlay = ({
  isDiagramReadonly,
}: {
  isDiagramReadonly: boolean;
}): React.ReactElement => {
  const { showSignalNamesInModel, showingCommandPalette } = useAppSelector(
    (state) => ({
      showSignalNamesInModel: state.uiFlags.showSignalNamesInModel,
      showingCommandPalette: state.uiFlags.showingCommandPalette,
      commandPaletteCoordScreenSpace:
        state.uiFlags.commandPaletteCoordScreenSpace,
    }),
  );

  const editingNodeNameUUID = useAppSelector(
    (state) => state.uiFlags.editingNodeNameUUID,
  );
  const editingAnnoNameUUID = useAppSelector(
    (state) => state.uiFlags.editingAnnotationTextUUID,
  );

  const parentPathRaw = useAppSelector(
    (state) => state.model.present.currentSubmodelPath,
  );
  const parentPath = React.useMemo(
    () => parentPathRaw.join('.'),
    [parentPathRaw],
  );

  return (
    <RendererOverlayContainer>
      <WorldSpaceOverlay parentPath={parentPath}>
        {editingNodeNameUUID != null && (
          <NodeNameEditInput isDiagramReadonly={isDiagramReadonly} />
        )}
        {editingAnnoNameUUID != null && (
          <AnnoNameEditInput isDiagramReadonly={isDiagramReadonly} />
        )}
        <ModelCommentsDisplay parentPath={parentPath} />
      </WorldSpaceOverlay>
      <RCContextMenu />
      {showSignalNamesInModel && <OverlaySignalTooltip />}
      {showingCommandPalette && <CommandPalette />}
      <SimTutorialPopup />
    </RendererOverlayContainer>
  );
};
