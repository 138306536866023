import { modelActions } from 'app/slices/modelSlice';
import type { Coordinate } from 'app/common_types/Coordinate';
import { snapNumberToGrid } from 'app/utils/modelDataUtils';
import { MouseActions } from 'app/common_types/MouseTypes';
import { RendererState } from '../modelRenderer';
import {
  getLinkDestWorldCoordinate,
  getLinkSourceWorldCoordinate,
} from '../linkEndCoordinates';
import { transitionMouseState } from '../transitionMouseState';

type SegmentsDataType = Array<{
  segment_direction: 'horiz' | 'vert';
  coordinate: number;
}>;

export const clickLinkDrawing = (
  rs: RendererState,
  worldCursor: Coordinate,
): void => {
  const { x: worldCursorX, y: worldCursorY } = worldCursor;

  if (
    rs.mouseState.state === MouseActions.DrawingLinkFromStart ||
    rs.mouseState.state === MouseActions.DrawingLinkFromEnd
  ) {
    const { linkUuid } = rs.mouseState;
    // TODO: lines uuid -> index LUT to avoid so many linear searches
    const link = rs.refs.current.links.find((l) => l.uuid === linkUuid);

    if (!link) {
      transitionMouseState(rs, { state: MouseActions.Idle });
      return;
    }

    // add 2 segments for the first canvas-click,
    // so that we get the expected S-shape
    if (link.uiprops.segments.length === 0) {
      let segmentsData: SegmentsDataType = [];

      if (rs.mouseState.state === MouseActions.DrawingLinkFromEnd) {
        const linkSourceWorldCoord =
          getLinkSourceWorldCoordinate(
            rs,
            link,
            rs.refs.current.nodes,
            rs.refs.current.nodesIndexLUT,
          ) || link.uiprops.hang_coord_start;

        const midX = linkSourceWorldCoord
          ? snapNumberToGrid(
              linkSourceWorldCoord.x +
                (worldCursorX - linkSourceWorldCoord.x) / 2,
            )
          : 0;

        segmentsData = [
          { segment_direction: 'vert', coordinate: snapNumberToGrid(midX) },
          {
            segment_direction: 'horiz',
            coordinate: snapNumberToGrid(worldCursorY),
          },
        ];
      }

      if (rs.mouseState.state === MouseActions.DrawingLinkFromStart) {
        const linkDestWorldCoord =
          getLinkDestWorldCoordinate(
            link,
            rs.refs.current.nodes,
            rs.refs.current.nodesIndexLUT,
          ) || link.uiprops.hang_coord_end;

        const midX = linkDestWorldCoord
          ? snapNumberToGrid(
              linkDestWorldCoord.x + (worldCursorX - linkDestWorldCoord.x) / 2,
            )
          : 0;

        segmentsData = [
          {
            segment_direction: 'horiz',
            coordinate: snapNumberToGrid(worldCursorY),
          },
          {
            segment_direction: 'vert',
            coordinate: snapNumberToGrid(midX),
          },
        ];
      }

      rs.dispatch(
        modelActions.addSegmentsToLink({
          linkUuid: link.uuid,
          segmentsData,
          prepend: false,
        }),
      );

      return;
    }

    const finalSegmentIndex =
      rs.mouseState.state === MouseActions.DrawingLinkFromEnd
        ? link.uiprops.segments.length - 1
        : 0;
    const finalSegment = link.uiprops.segments[finalSegmentIndex] || {};
    const finalSegmentDirection = finalSegment.segment_direction;

    // regular link-drawing
    const segment_direction = finalSegmentDirection
      ? finalSegmentDirection === 'horiz'
        ? 'vert'
        : 'horiz'
      : 'vert';

    const coordinate = snapNumberToGrid(
      segment_direction === 'horiz'
        ? snapNumberToGrid(worldCursorY)
        : snapNumberToGrid(worldCursorX),
    );

    rs.dispatch(
      modelActions.addSegmentsToLink({
        linkUuid: link.uuid,
        segmentsData: [{ segment_direction, coordinate }],
        prepend: rs.mouseState.state === MouseActions.DrawingLinkFromStart,
      }),
    );
  }
};
