import type { Coordinate, Geometry } from 'app/common_types/Coordinate';
import {
  HoverEdgeSide,
  HoverEdgeSidePair,
  HoverEntity,
  HoverEntityType,
  MouseActions,
  MouseState,
} from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import {
  AnnotationInstance,
  LinkInstance,
  NodeInstance,
} from 'app/generated_types/SimulationModel';
import {
  getBottomAlignedPortYPos,
  getCenteredPortYPos,
  getSpacedPortYPos,
  getTopAlignedPortYPos,
} from 'app/utils/getPortOffsetCoordinate';
import { LinkRenderData, VertexSegmentType } from 'app/utils/linkToRenderData';
import { renderConstants } from 'app/utils/renderConstants';
import { SPACING } from 'theme/styleConstants';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { calculateTextSize } from 'util/calculateTextSize';
import { getIsNodeSupported } from 'util/portTypeUtils';
import { pythagoreanDistance } from 'util/pythagoreanDistance';
import { getVertexHitboxForIndex } from './getVertexHitboxForIndex';
import { getVisualNodeHeight, PORT_BLOCK_YOFFSET } from './getVisualNodeHeight';
import { getVisualNodeWidth } from './getVisualNodeWidth';
import { getFontSize, NAME_FONTSIZE } from './textRenderUtils';

// This file defines the logic for determining which entities are being hovered
// and can be interacted with.
// Got any bugs with mouse collisions? First, enable debug with Cmd+Shift+K.
//
// Since we don't have a proper scenegraph or even a list of elements to
// render at which positions, we end up re-computing some of the same geometries
// here as in the respective draw functions. This is not ideal and quite
// error-prone. Anyway, it's ad-hoc but it works.

// HoverEntityPriorityOrder defines the rrder of priority for interactions
// from highest to lowest.
// This may differ from the order of rendering because some entities are
// rendered together like a block and its name label, but the name label
// should always be less important than another block. We also don't have
// a clear concept of z-order.
//
// Note:
// There is also a difference between hover, click, double click and hold+drag.
// For example, hold+drag on an "inside" annotation label should drag the
// annotation. This distinction is not implemented at the moment, but would
// be a trivial fix (just define a different order array and use that depending
// on the interaction that is happening).
//
// FIXME: we need to filter out entities that are occluded
// https://collimator.atlassian.net/browse/DASH-1787
const HoverEntityPriorityOrder = [
  HoverEntityType.Port,
  HoverEntityType.NodeResizeEdge,
  HoverEntityType.Node,
  HoverEntityType.TapPoint,
  HoverEntityType.HangingStartPoint,
  HoverEntityType.HangingEndPoint,
  HoverEntityType.FakeLinkSegment,
  HoverEntityType.Link,
  HoverEntityType.SignalPlotter,
  HoverEntityType.NodeName,
  HoverEntityType.AnnotationResizeEdge,
  HoverEntityType.AnnotationText,
  HoverEntityType.Annotation,
];

const HANG_COORD_COLLISION_DISTANCE = 10;
const NODE_EDGEHANDLE_SIZE = renderConstants.GRID_UNIT_PXSIZE / 2;
const BROAD_MARGIN = renderConstants.PORT_SIZE;
const PORT_HIT_MARGIN = renderConstants.PORT_SIZE;

const getTextHitbox = (
  zoom: number,
  text: string,
  x: number,
  y: number,
  isCenterX?: boolean, // for name labels (x is the center of the text)
  isAlignBottom?: boolean, // for 'top' annotations (y is the bottom of the text)
) => {
  const fontSize = getFontSize(zoom, NAME_FONTSIZE);
  const textHitMargin = renderConstants.GRID_UNIT_PXSIZE / 2;

  let { width, height } = calculateTextSize(text, {
    font: 'Archivo',
    fontSize: `${fontSize}px`,
  });

  width /= zoom;
  height /= zoom;
  width += textHitMargin * 2;
  height += textHitMargin / 2;

  const geom = isCenterX
    ? {
        x: x - width / 2,
        y,
        width,
        height,
      }
    : {
        x: x - textHitMargin,
        y,
        width,
        height,
      };

  if (isAlignBottom) {
    geom.y += -height + textHitMargin / 2;
  } else {
    geom.y -= textHitMargin / 2;
  }

  return geom;
};

const mergeHitboxes = (hitboxes: Geometry[]) => {
  const x = Math.max(...hitboxes.map((h) => h.x));
  const y = Math.max(...hitboxes.map((h) => h.y));
  const rx = Math.min(...hitboxes.map((h) => h.x + h.width));
  const by = Math.min(...hitboxes.map((h) => h.y + h.height));
  return { x, y, width: rx - x, height: by - y };
};

// Computes the local Y coordinates of the ports on a given side.
const getPortsYoffsets = (
  portAlignment: 'top' | 'center' | 'bottom' | 'spaced',
  currentNodeHeight: number,
  portsCount: number,
  nodeIsSmall: boolean,
): number[] => {
  if (nodeIsSmall) {
    // Small nodes are only I/O and Constant blocks, which have a single port.
    return [currentNodeHeight / 2];
  }

  const portYoffsets: number[] = [];
  const nodeGridHeight = Math.floor(
    currentNodeHeight / renderConstants.GRID_UNIT_PXSIZE,
  );
  const numPortSlots = nodeGridHeight - 1;

  if (portAlignment === 'top') {
    for (let i = 0; i < portsCount; i++) {
      portYoffsets.push(getTopAlignedPortYPos(i));
    }
  } else if (portAlignment === 'bottom') {
    for (let i = 0; i < portsCount; i++) {
      portYoffsets.push(getBottomAlignedPortYPos(numPortSlots, portsCount, i));
    }
  } else if (portAlignment === 'center') {
    for (let i = 0; i < portsCount; i++) {
      portYoffsets.push(getCenteredPortYPos(numPortSlots, portsCount, i));
    }
  } else if (portAlignment === 'spaced') {
    for (let i = 0; i < portsCount; i++) {
      portYoffsets.push(getSpacedPortYPos(numPortSlots, portsCount, i));
    }
  }

  return portYoffsets;
};

// Returns a somewhat adaptive collision margin for I/O ports so that tightly
// packed ports don't overlap, spaced ports have a larger collision area,
// but still restricted to within 'broadMargin' (same as in the X axis).
const getPortYCollisionMargin = (
  currentNodeHeight: number,
  portsCount: number,
): number => {
  const maxCollisionMargin = Math.min(
    BROAD_MARGIN,
    (currentNodeHeight - 2 * renderConstants.GRID_UNIT_PXSIZE) / portsCount / 2,
  );
  const yPortCollisionMargin = Math.max(
    renderConstants.GRID_UNIT_PXSIZE,
    maxCollisionMargin,
  );
  return yPortCollisionMargin;
};

export const getHoveringEntities = (
  mouseState: MouseState,
  worldCursor: Coordinate,
  camera: Coordinate,
  zoom: number,
  nodes: NodeInstance[],
  links: LinkInstance[],
  annotations: AnnotationInstance[],
  linksIndexLUT: { [k: string]: number },
  linksRenderFrameData: LinkRenderData[],
  parentPath: string[],
): HoverEntity[] => {
  // Build up a list of all hovered entities so we can select the best match at
  // the end. For entities of same type, use reverse order to respect z-order.
  const allHoveredEntities: HoverEntity[] = [];

  // Blocks & ports mouse collisions.
  for (let i = nodes.length - 1; i >= 0; i--) {
    const currentNode = nodes[i];
    const currentNodeHeight = getVisualNodeHeight(currentNode);
    const currentNodeWidth = getVisualNodeWidth(currentNode);
    const nodeReversed = currentNode.uiprops.directionality === 'left';
    const nodeIsIOPort =
      currentNode.type === 'core.Inport' || currentNode.type === 'core.Outport';
    const nodeIsSmall =
      nodeIsIOPort ||
      (currentNode.type === 'core.Constant' &&
        currentNodeHeight < renderConstants.GRID_UNIT_PXSIZE * 5);

    const broadBoxX = currentNode.uiprops.x - BROAD_MARGIN;
    const broadBoxXWithSignalToggle = nodeReversed
      ? currentNode.uiprops.x -
        renderConstants.SIGNAL_PLOTTER_WIDTH -
        renderConstants.SIGNAL_PLOTTER_COLLISION_OFFSET_X
      : broadBoxX;
    const fixedNodeY = nodeIsIOPort
      ? currentNode.uiprops.y + PORT_BLOCK_YOFFSET
      : currentNode.uiprops.y;
    const broadBoxY =
      currentNode.uiprops.label_position === 'top'
        ? fixedNodeY - 20
        : fixedNodeY - renderConstants.SIGNAL_PLOTTER_COLLISION_OFFSET_Y;
    const broadBoxWidth = currentNodeWidth + BROAD_MARGIN * 2;
    const broadBoxWidthWithSignalToggle =
      broadBoxWidth +
      renderConstants.SIGNAL_PLOTTER_WIDTH +
      renderConstants.SIGNAL_PLOTTER_COLLISION_OFFSET_X;
    // broad height not currently affected by ports
    const broadBoxHeight =
      currentNodeHeight +
      renderConstants.SIGNAL_PLOTTER_COLLISION_OFFSET_Y +
      20;
    const broadBox: Geometry = {
      x: broadBoxX,
      y: broadBoxY,
      width: broadBoxWidth,
      height: broadBoxHeight,
    };

    // Broad box check which includes block & ports
    // to see if we should even bother checking against all ports.
    if (
      worldCursor.x > broadBoxXWithSignalToggle &&
      worldCursor.y > broadBoxY &&
      worldCursor.x < broadBoxX + broadBoxWidthWithSignalToggle &&
      worldCursor.y < broadBoxY + broadBoxHeight
    ) {
      // Check for signal plotter collision
      const signalPlotterX = nodeReversed
        ? broadBoxXWithSignalToggle
        : currentNode.uiprops.x +
          currentNodeWidth +
          renderConstants.SIGNAL_PLOTTER_COLLISION_OFFSET_X;
      const signalPlotterY =
        fixedNodeY - renderConstants.SIGNAL_PLOTTER_COLLISION_OFFSET_Y;
      const canSignalPlot = getIsNodeSupported(currentNode.uuid, parentPath);
      if (
        canSignalPlot &&
        worldCursor.x > signalPlotterX &&
        worldCursor.y > signalPlotterY &&
        worldCursor.x < signalPlotterX + renderConstants.SIGNAL_PLOTTER_WIDTH &&
        worldCursor.y < signalPlotterY + renderConstants.SIGNAL_PLOTTER_HEIGHT
      ) {
        allHoveredEntities.push({
          entityType: HoverEntityType.SignalPlotter,
          block: currentNode,
          geom: {
            x: signalPlotterX,
            y: signalPlotterY,
            width: renderConstants.SIGNAL_PLOTTER_WIDTH,
            height: renderConstants.SIGNAL_PLOTTER_HEIGHT,
          },
        });
      }

      // Check for left & right port collisions.
      const localY = worldCursor.y - fixedNodeY;
      const portAlignment = currentNode.uiprops.port_alignment || 'center';

      // Check for left port collision.
      const leftPortsCount = nodeReversed
        ? currentNode.outputs.length
        : currentNode.inputs.length;

      if (
        leftPortsCount &&
        localY > 0 &&
        localY < currentNodeHeight &&
        worldCursor.x < currentNode.uiprops.x + PORT_HIT_MARGIN &&
        worldCursor.x > currentNode.uiprops.x - PORT_HIT_MARGIN
      ) {
        const leftPortType = nodeReversed ? PortSide.Output : PortSide.Input;
        const leftPortsOrder = nodeReversed
          ? currentNode.uiprops.outport_order
          : currentNode.uiprops.inport_order;
        const yOffsets = getPortsYoffsets(
          portAlignment,
          currentNodeHeight,
          leftPortsCount,
          nodeIsSmall,
        );
        const yPortCollisionMargin = getPortYCollisionMargin(
          currentNodeHeight,
          leftPortsCount,
        );
        const portVisualSlot = yOffsets.findIndex(
          (offset) =>
            localY >= offset - yPortCollisionMargin &&
            localY <= offset + yPortCollisionMargin,
        );
        if (portVisualSlot !== -1) {
          const portId = leftPortsOrder
            ? leftPortsOrder[portVisualSlot]
            : portVisualSlot;
          allHoveredEntities.push({
            entityType: HoverEntityType.Port,
            port: {
              side: leftPortType,
              blockUuid: currentNode.uuid,
              portId,
            },
            geom: {
              x: broadBoxX,
              y: fixedNodeY + yOffsets[portVisualSlot] - yPortCollisionMargin,
              width: PORT_HIT_MARGIN * 2,
              height: yPortCollisionMargin * 2,
            },
          });
        }
      }

      // Check for right port collision.
      const rightPortsCount = nodeReversed
        ? currentNode.inputs.length
        : currentNode.outputs.length;

      if (
        rightPortsCount &&
        localY > 0 &&
        localY < currentNodeHeight &&
        worldCursor.x >
          currentNode.uiprops.x + currentNodeWidth - PORT_HIT_MARGIN &&
        worldCursor.x <
          currentNode.uiprops.x + currentNodeWidth + PORT_HIT_MARGIN
      ) {
        const rightPortType = nodeReversed ? PortSide.Input : PortSide.Output;
        const rightPortsOrder = nodeReversed
          ? currentNode.uiprops.inport_order
          : currentNode.uiprops.outport_order;
        const yOffsets = getPortsYoffsets(
          portAlignment,
          currentNodeHeight,
          rightPortsCount,
          nodeIsSmall,
        );
        const yPortCollisionMargin = getPortYCollisionMargin(
          currentNodeHeight,
          rightPortsCount,
        );
        const portVisualSlot = yOffsets.findIndex(
          (offset) =>
            localY >= offset - yPortCollisionMargin &&
            localY <= offset + yPortCollisionMargin,
        );
        if (portVisualSlot !== -1) {
          const portId = rightPortsOrder
            ? rightPortsOrder[portVisualSlot]
            : portVisualSlot;
          allHoveredEntities.push({
            entityType: HoverEntityType.Port,
            port: {
              side: rightPortType,
              blockUuid: currentNode.uuid,
              portId,
            },
            geom: {
              x: currentNode.uiprops.x + currentNodeWidth - PORT_HIT_MARGIN,
              y: fixedNodeY + yOffsets[portVisualSlot] - yPortCollisionMargin,
              width: PORT_HIT_MARGIN * 2,
              height: yPortCollisionMargin * 2,
            },
          });
        }
      }

      // Check for block name label collision.
      const labelY =
        currentNode.uiprops.label_position === 'top'
          ? broadBoxY + renderConstants.GRID_UNIT_PXSIZE / 2
          : fixedNodeY + currentNodeHeight;
      const rawLabelGeom = getTextHitbox(
        zoom,
        currentNode.name,
        currentNode.uiprops.x + currentNodeWidth / 2,
        labelY + renderConstants.GRID_UNIT_PXSIZE / 2,
        true,
      );
      const labelGeom = mergeHitboxes([rawLabelGeom, broadBox]);
      if (
        worldCursor.x > labelGeom.x &&
        worldCursor.x < labelGeom.x + labelGeom.width &&
        worldCursor.y > labelGeom.y &&
        worldCursor.y < labelGeom.y + labelGeom.height
      ) {
        allHoveredEntities.push({
          entityType: HoverEntityType.NodeName,
          block: currentNode,
          geom: labelGeom,
        });
      }

      // Check for direct block box collision.
      const blockRightEdge = currentNode.uiprops.x + currentNodeWidth;
      const blockBottomEdge = fixedNodeY + currentNodeHeight;
      if (
        worldCursor.x > currentNode.uiprops.x &&
        worldCursor.y > fixedNodeY &&
        worldCursor.x < blockRightEdge &&
        worldCursor.y < blockBottomEdge
      ) {
        let onEdge = false;
        const handleSides: HoverEdgeSide[] = [];
        const geom = {
          x: currentNode.uiprops.x,
          y: fixedNodeY,
          width: currentNodeWidth,
          height: currentNodeHeight,
        };
        const edgeGeom = { ...geom };

        // I/O ports can not be resized
        if (
          currentNode.type !== 'core.Inport' &&
          currentNode.type !== 'core.Outport'
        ) {
          if (worldCursor.x < currentNode.uiprops.x + NODE_EDGEHANDLE_SIZE) {
            onEdge = true;
            edgeGeom.width = NODE_EDGEHANDLE_SIZE;
            handleSides.push(HoverEdgeSide.Left);
          }
          if (worldCursor.x > blockRightEdge - NODE_EDGEHANDLE_SIZE) {
            onEdge = true;
            edgeGeom.x = blockRightEdge - NODE_EDGEHANDLE_SIZE;
            edgeGeom.width = NODE_EDGEHANDLE_SIZE;
            handleSides.push(HoverEdgeSide.Right);
          }
          if (worldCursor.y < fixedNodeY + NODE_EDGEHANDLE_SIZE) {
            onEdge = true;
            edgeGeom.height = NODE_EDGEHANDLE_SIZE;
            handleSides.push(HoverEdgeSide.Top);
          }
          if (worldCursor.y > blockBottomEdge - NODE_EDGEHANDLE_SIZE) {
            onEdge = true;
            edgeGeom.y = blockBottomEdge - NODE_EDGEHANDLE_SIZE;
            edgeGeom.height = NODE_EDGEHANDLE_SIZE;
            handleSides.push(HoverEdgeSide.Bottom);
          }

          if (
            onEdge &&
            handleSides[0] !== undefined &&
            (handleSides.length === 1 || handleSides.length === 2)
          ) {
            allHoveredEntities.push({
              entityType: HoverEntityType.NodeResizeEdge,
              nodeUuid: currentNode.uuid,
              handleSides: handleSides as HoverEdgeSidePair,
              geom: edgeGeom,
            });
          }
        }

        allHoveredEntities.push({
          entityType: HoverEntityType.Node,
          block: currentNode,
          geom,
        });
      }
    }
  }

  // Link mouse collisions.
  for (
    let ci = (linksRenderFrameData ? linksRenderFrameData.length : 0) - 1;
    ci >= 0;
    ci--
  ) {
    const linkRenderData = linksRenderFrameData[ci];
    if (!linkRenderData) continue;
    const realLinkIndex = linksIndexLUT[linkRenderData.linkUuid];
    const { vertexData } = linkRenderData;
    const currentLink = links[realLinkIndex];
    if (!currentLink) continue;

    if (
      (mouseState.state === MouseActions.DrawingLinkFromEnd ||
        mouseState.state === MouseActions.DrawingLinkFromStart) &&
      mouseState.linkUuid === currentLink.uuid
    ) {
      continue;
    }

    const isTappingLink =
      currentLink.uiprops.link_type.connection_method === 'link_tap';
    // if this is a tapping link, we first check for tap point collision
    // to shortcut the rest of the segment checks if we hit it
    if (isTappingLink) {
      const firstVertex = vertexData[0];
      const [fvX, fvY] = firstVertex ? firstVertex.coordinate : [0, 0];

      if (
        firstVertex &&
        fvX - HANG_COORD_COLLISION_DISTANCE < worldCursor.x &&
        fvX + HANG_COORD_COLLISION_DISTANCE > worldCursor.x &&
        fvY - HANG_COORD_COLLISION_DISTANCE < worldCursor.y &&
        fvY + HANG_COORD_COLLISION_DISTANCE > worldCursor.y
      ) {
        const tappedLinkUuid =
          currentLink.uiprops.link_type.connection_method == 'link_tap'
            ? currentLink.uiprops.link_type.tapped_link_uuid
            : '';
        const tappedSegmentId =
          currentLink.uiprops.link_type.connection_method == 'link_tap' &&
          currentLink.uiprops.link_type.tapped_segment.segment_type === 'real'
            ? currentLink.uiprops.link_type.tapped_segment.tapped_segment_index
            : 0;

        allHoveredEntities.push({
          entityType: HoverEntityType.TapPoint,
          linkUuid: currentLink.uuid,
          tappedLinkUuid,
          tappedSegmentId,
          geom: {
            x: fvX - HANG_COORD_COLLISION_DISTANCE,
            y: fvY - HANG_COORD_COLLISION_DISTANCE,
            width: HANG_COORD_COLLISION_DISTANCE * 2,
            height: HANG_COORD_COLLISION_DISTANCE * 2,
          },
        });
      }
    }

    const rs = rendererState;
    const srcNode = currentLink.src
      ? rs?.refs.current.nodes[
          rs.refs.current.nodesIndexLUT[currentLink.src.node]
        ]
      : undefined;
    const dstNode = currentLink.dst
      ? rs?.refs.current.nodes[
          rs?.refs.current.nodesIndexLUT[currentLink.dst.node]
        ]
      : undefined;
    const srcDisconnected =
      !currentLink.src ||
      (srcNode && srcNode.outputs.length - 1 < (currentLink.src?.port || -1));
    const dstDisconnected =
      !currentLink.dst ||
      (dstNode && dstNode.inputs.length - 1 < (currentLink.dst?.port || -1));

    const currentLinkRenderDataIdx =
      rs?.linksRenderFrameDataIndexLUT[currentLink.uuid];
    const currentLinkRenderData =
      rs?.linksRenderFrameData[currentLinkRenderDataIdx ?? -1];

    if (
      !isTappingLink &&
      srcDisconnected &&
      currentLinkRenderData &&
      currentLinkRenderData.vertexData[0]?.coordinate
    ) {
      const startVertex = currentLinkRenderData.vertexData[0];
      const startCoord =
        currentLink.uiprops.hang_coord_start || startVertex
          ? { x: startVertex.coordinate[0], y: startVertex.coordinate[1] }
          : { x: 0, y: 0 };

      const distance = pythagoreanDistance(worldCursor, startCoord);
      if (distance < HANG_COORD_COLLISION_DISTANCE) {
        allHoveredEntities.push({
          entityType: HoverEntityType.HangingStartPoint,
          linkUuid: linkRenderData.linkUuid,
          geom: {
            x: startCoord.x - HANG_COORD_COLLISION_DISTANCE,
            y: startCoord.y - HANG_COORD_COLLISION_DISTANCE,
            width: HANG_COORD_COLLISION_DISTANCE * 2,
            height: HANG_COORD_COLLISION_DISTANCE * 2,
          },
        });
      }
    }

    if (
      dstDisconnected &&
      currentLinkRenderData &&
      currentLinkRenderData.vertexData[
        currentLinkRenderData.vertexData.length - 1
      ]?.coordinate
    ) {
      const endVertex =
        currentLinkRenderData.vertexData[
          currentLinkRenderData.vertexData.length - 1
        ];
      const endCoord =
        currentLink.uiprops.hang_coord_start || endVertex
          ? { x: endVertex.coordinate[0], y: endVertex.coordinate[1] }
          : { x: 0, y: 0 };

      const distance = pythagoreanDistance(worldCursor, endCoord);
      if (distance < HANG_COORD_COLLISION_DISTANCE) {
        allHoveredEntities.push({
          entityType: HoverEntityType.HangingEndPoint,
          linkUuid: linkRenderData.linkUuid,
          geom: {
            x: endCoord.x - HANG_COORD_COLLISION_DISTANCE,
            y: endCoord.y - HANG_COORD_COLLISION_DISTANCE,
            width: HANG_COORD_COLLISION_DISTANCE * 2,
            height: HANG_COORD_COLLISION_DISTANCE * 2,
          },
        });
      }
    }

    if (currentLink) {
      // final vertex's hitbox does not exist
      // so we skip it (subtract 1 from length)
      for (let j = 0; j < vertexData.length - 1; j++) {
        const hitbox = getVertexHitboxForIndex(vertexData, j);
        const vertex = vertexData[j];

        if (
          hitbox.x1 < worldCursor.x &&
          hitbox.x2 > worldCursor.x &&
          hitbox.y1 < worldCursor.y &&
          hitbox.y2 > worldCursor.y
        ) {
          switch (vertex.segmentType) {
            case VertexSegmentType.Fake:
              allHoveredEntities.push({
                entityType: HoverEntityType.FakeLinkSegment,
                link: currentLink,
                linkUuid: linkRenderData.linkUuid,
                fakeSegmentType: vertex.fakeSegmentType,
                vertexDataIndex: j,
                geom: {
                  x: hitbox.x1,
                  y: hitbox.y1,
                  width: hitbox.x2 - hitbox.x1,
                  height: hitbox.y2 - hitbox.y1,
                },
              });
              break;
            case VertexSegmentType.Real:
              allHoveredEntities.push({
                entityType: HoverEntityType.Link,
                link: currentLink,
                linkUuid: linkRenderData.linkUuid,
                segmentId: vertex.segmentIndex,
                geom: {
                  x: hitbox.x1,
                  y: hitbox.y1,
                  width: hitbox.x2 - hitbox.x1,
                  height: hitbox.y2 - hitbox.y1,
                },
              });
              break;
          }
        }
      }
    }
  }

  for (let i = annotations.length - 1; i >= 0; i--) {
    const currentAnnotation = annotations[i];

    const annotWidth =
      currentAnnotation.grid_width * renderConstants.GRID_UNIT_PXSIZE;
    const annoHeight =
      currentAnnotation.grid_height * renderConstants.GRID_UNIT_PXSIZE;
    const annoRightEdge =
      currentAnnotation.x +
      currentAnnotation.grid_width * renderConstants.GRID_UNIT_PXSIZE;
    const annoBottomEdge = currentAnnotation.y + annoHeight;

    const annotGeom = {
      x: currentAnnotation.x,
      y: currentAnnotation.y,
      width: annotWidth,
      height: annoHeight,
    };

    const labelGeom =
      currentAnnotation.label_position === 'top'
        ? getTextHitbox(
            zoom,
            currentAnnotation.text,
            currentAnnotation.x,
            currentAnnotation.y - SPACING / 3,
            false,
            true,
          )
        : currentAnnotation.label_position === 'inside'
        ? getTextHitbox(
            zoom,
            currentAnnotation.text,
            currentAnnotation.x + SPACING / 2,
            currentAnnotation.y + SPACING / 2,
          )
        : getTextHitbox(
            zoom,
            currentAnnotation.text,
            currentAnnotation.x,
            currentAnnotation.y +
              (currentAnnotation.grid_height + 1) *
                renderConstants.GRID_UNIT_PXSIZE,
          );

    if (
      worldCursor.x > labelGeom.x &&
      worldCursor.y > labelGeom.y &&
      worldCursor.x < labelGeom.x + labelGeom.width &&
      worldCursor.y < labelGeom.y + labelGeom.height
    ) {
      allHoveredEntities.push({
        entityType: HoverEntityType.AnnotationText,
        uuid: currentAnnotation.uuid,
        geom: labelGeom,
      });
    }

    if (
      worldCursor.x > annotGeom.x &&
      worldCursor.y > annotGeom.y &&
      worldCursor.x < annotGeom.x + annotGeom.width &&
      worldCursor.y < annotGeom.y + annotGeom.height
    ) {
      let onEdge = false;
      const handleSides: HoverEdgeSide[] = [];
      const handleGeom = { ...annotGeom };
      if (worldCursor.x < currentAnnotation.x + NODE_EDGEHANDLE_SIZE) {
        onEdge = true;
        handleGeom.width = NODE_EDGEHANDLE_SIZE;
        handleSides.push(HoverEdgeSide.Left);
      }
      if (worldCursor.x > annoRightEdge - NODE_EDGEHANDLE_SIZE) {
        onEdge = true;
        handleGeom.x = annoRightEdge - NODE_EDGEHANDLE_SIZE;
        handleGeom.width = NODE_EDGEHANDLE_SIZE;
        handleSides.push(HoverEdgeSide.Right);
      }
      if (worldCursor.y < currentAnnotation.y + NODE_EDGEHANDLE_SIZE) {
        onEdge = true;
        handleGeom.height = NODE_EDGEHANDLE_SIZE;
        handleSides.push(HoverEdgeSide.Top);
      }
      if (worldCursor.y > annoBottomEdge - NODE_EDGEHANDLE_SIZE) {
        onEdge = true;
        handleGeom.y = annoBottomEdge - NODE_EDGEHANDLE_SIZE;
        handleGeom.height = NODE_EDGEHANDLE_SIZE;
        handleSides.push(HoverEdgeSide.Bottom);
      }

      if (
        onEdge &&
        handleSides[0] !== undefined &&
        (handleSides.length === 1 || handleSides.length === 2)
      ) {
        allHoveredEntities.push({
          entityType: HoverEntityType.AnnotationResizeEdge,
          uuid: currentAnnotation.uuid,
          handleSides: handleSides as
            | [HoverEdgeSide]
            | [HoverEdgeSide, HoverEdgeSide],
          geom: handleGeom,
        });
      }

      allHoveredEntities.push({
        entityType: HoverEntityType.Annotation,
        uuid: currentAnnotation.uuid,
        geom: annotGeom,
      });
    }
  }

  return allHoveredEntities.sort(
    (a, b) =>
      HoverEntityPriorityOrder.indexOf(a.entityType) -
      HoverEntityPriorityOrder.indexOf(b.entityType),
  );
};

export const getHoveringEntity = (
  mouseState: MouseState,
  worldCursor: Coordinate,
  camera: Coordinate,
  zoom: number,
  nodes: NodeInstance[],
  links: LinkInstance[],
  annotations: AnnotationInstance[],
  linksIndexLUT: { [k: string]: number },
  linksRenderFrameData: LinkRenderData[],
  parentPath: string[],
): HoverEntity | undefined => {
  const entities = getHoveringEntities(
    mouseState,
    worldCursor,
    camera,
    zoom,
    nodes,
    links,
    annotations,
    linksIndexLUT,
    linksRenderFrameData,
    parentPath,
  );

  return entities[0];
};
