import { Port } from '@collimator/model-schemas-ts';
import { t } from '@lingui/macro';
import { PortSide } from 'app/common_types/PortTypes';
import { blockClassLookup } from 'app/generated_blocks/';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import {
  nodeTypeIsAdder,
  nodeTypeIsArithmetic,
  nodeTypeIsDynamicBlock,
  nodeTypeIsProduct,
  nodeTypeIsSubdiagram,
} from 'app/helpers';
import { useAppDispatch } from 'app/hooks';
import { modelActions } from 'app/slices/modelSlice';
import { getPortDisplayName } from 'app/utils/portConditionUtils';
import {
  canAddPort,
  canDeletePort,
  canRenamePort,
} from 'app/utils/portOperationUtils';
import { getDefaultPortParameters } from 'app/utils/portUtils';
import React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Remove } from 'ui/common/Icons/Standard';
import { isValidBlockPortNameRuleSet } from 'ui/common/Input/inputValidationForModels';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import { DetailsInput, DetailsSection } from 'ui/modelEditor/DetailsComponents';
import BlockInportSwitch from './BlockInportSwitch';
import {
  PortDragHandle,
  PortDropArea,
  PortList,
  PortRow,
} from './BlockOutportDetails';
import BlockPortParameters from './BlockPortParameter';

type Props = {
  parentPath: string[];
  selectedNode: NodeInstance;
  canEdit: boolean;
};

const BlockInportDetails: React.FC<Props> = ({
  parentPath,
  selectedNode,
  canEdit,
}) => {
  const dispatch = useAppDispatch();

  const blockClass = blockClassLookup(selectedNode.type);

  const minDynamicInputCount = blockClass.ports.inputs?.dynamic?.min_count || 0;

  const canShowAddButton =
    canEdit &&
    !nodeTypeIsSubdiagram(selectedNode.type) &&
    !nodeTypeIsDynamicBlock(selectedNode);
  const canAddInput = canEdit && canAddPort(selectedNode, PortSide.Input);

  const isAdderBlock = nodeTypeIsAdder(selectedNode.type);
  const isProductBlock = nodeTypeIsProduct(selectedNode.type);
  const isArithmeticBlock = nodeTypeIsArithmetic(selectedNode.type);

  const addInput = React.useCallback(() => {
    const parameters = getDefaultPortParameters(
      blockClass.ports.inputs?.dynamic?.parameter_definitions,
    );
    dispatch(
      modelActions.addPort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Input,
        parameters,
      }),
    );

    // TODO refactor these operations so that the hidden operator
    // parameter change can be changed atomically with the add port change
    if (isAdderBlock || isArithmeticBlock) {
      const operatorsParameter = selectedNode.parameters.operators;
      if (!operatorsParameter) {
        console.error('no operators parameter found for block', selectedNode);
        return;
      }
      const newOperators = `${operatorsParameter.value}+`;
      dispatch(
        modelActions.changeBlockParameter({
          parentPath,
          nodeUuid: selectedNode.uuid,
          paramName: 'operators',
          value: newOperators,
        }),
      );
    }
    if (isProductBlock) {
      const operatorsParameter = selectedNode.parameters.operators;
      if (!operatorsParameter) {
        console.error('no operators parameter found for block', selectedNode);
        return;
      }
      const newOperators = `${operatorsParameter.value}*`;
      dispatch(
        modelActions.changeBlockParameter({
          parentPath,
          nodeUuid: selectedNode.uuid,
          paramName: 'operators',
          value: newOperators,
        }),
      );
    }
  }, [
    blockClass.ports.inputs?.dynamic?.parameter_definitions,
    dispatch,
    parentPath,
    selectedNode,
    isAdderBlock,
    isProductBlock,
    isArithmeticBlock,
  ]);

  const removeInput = (inputId: number) => () => {
    dispatch(
      modelActions.removePort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Input,
        portId: inputId,
      }),
    );

    // TODO refactor these operations so that the hidden operator
    // parameter change can be changed atomically with the add port change
    if (isAdderBlock || isProductBlock || isArithmeticBlock) {
      const operatorsParameter = selectedNode.parameters.operators;
      if (!operatorsParameter) {
        console.error('no operators parameter found for block', selectedNode);
        return;
      }
      const newOperators =
        operatorsParameter.value.slice(0, inputId) +
        operatorsParameter.value.slice(inputId + 1);
      dispatch(
        modelActions.changeBlockParameter({
          parentPath,
          nodeUuid: selectedNode.uuid,
          paramName: 'operators',
          value: newOperators,
        }),
      );
    }
  };

  const renameInput = (inputId: number, newPortName: string) => {
    dispatch(
      modelActions.renamePort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        portSide: PortSide.Input,
        portId: inputId,
        newPortName,
      }),
    );
  };

  const canDragPorts =
    canEdit &&
    (selectedNode.type === 'core.Group' ||
      selectedNode.type === 'core.PythonScript');

  const sortedPorts: Array<{ port: Port; portIndex: number }> =
    React.useMemo(() => {
      const inputs = [];

      for (let i = 0; i < selectedNode.inputs.length; i++) {
        let portIndex = 0;

        // if these ports aren't draggable
        // or we don't have any port-ordering data (not likely to ever happen, but technically possible in the typedef)
        // we default to the underlying port order.
        if (!canDragPorts || !selectedNode.uiprops.inport_order) portIndex = i;
        // otherwise, of course, we use the port ordering data we have.
        else portIndex = selectedNode.uiprops.inport_order[i];

        if (
          portIndex !== undefined &&
          portIndex < selectedNode.inputs.length &&
          selectedNode.inputs[portIndex]?.variant?.variant_kind !== 'acausal'
        ) {
          inputs.push({ port: selectedNode.inputs[portIndex], portIndex });
        }
      }

      return inputs;
    }, [canDragPorts, selectedNode.inputs, selectedNode.uiprops.inport_order]);

  // Always show Inputs/Outputs header for Submodels
  const showInputsHeader =
    canAddInput ||
    nodeTypeIsSubdiagram(selectedNode.type) ||
    sortedPorts.length > 0;

  const onDragEnd: OnDragEndResponder = (dragInfo) => {
    if (
      !dragInfo.destination ||
      dragInfo.source.index === dragInfo.destination.index
    )
      return;

    const portIndex = parseInt(dragInfo.draggableId);

    dispatch(
      modelActions.reorderNodePort({
        parentPath,
        nodeUuid: selectedNode.uuid,
        side: PortSide.Input,
        portIndex,
        fromOrderIndex: dragInfo.source.index,
        toOrderIndex: dragInfo.destination.index,
      }),
    );
  };

  return (
    <>
      {showInputsHeader && (
        <SectionHeading
          testId="inputs"
          onButtonClick={canShowAddButton ? addInput : undefined}
          isButtonEnabled={canAddInput && canEdit}
          buttonTooltip={t({
            id: 'blockDetails.addInputButtonTooltip',
            message: 'Add input',
          })}>
          {t({
            id: 'blockDetails.PortInputsTitle',
            message: 'Inputs',
          })}
        </SectionHeading>
      )}
      {(sortedPorts.length > 0 || canAddInput) && (
        <DragDropContext onDragEnd={onDragEnd}>
          <PortList>
            <Droppable droppableId="droppable-inports" type="inports">
              {(providedDroppable, _) => (
                <PortDropArea
                  ref={providedDroppable.innerRef}
                  {...providedDroppable.droppableProps}>
                  {sortedPorts.map(({ portIndex, port: input }, orderIndex) => (
                    <Draggable
                      draggableId={`${portIndex}`}
                      index={orderIndex}
                      key={`drop-input-${portIndex}`}>
                      {(providedDraggable, _) => (
                        <PortRow
                          ref={providedDraggable.innerRef}
                          key={input.name || `row-input[${portIndex}]`}
                          {...providedDraggable.draggableProps}>
                          <DetailsSection>
                            <PortDragHandle
                              canDrag={canDragPorts}
                              {...providedDraggable.dragHandleProps}
                            />
                            <DetailsInput
                              grow
                              testId={`block-input-name-${portIndex}`}
                              value={getPortDisplayName(
                                selectedNode.type,
                                input.name,
                              )}
                              placeholder={`Unnamed input ${portIndex}`}
                              onSubmitValue={(newName) =>
                                renameInput(portIndex, newName)
                              }
                              disabled={
                                !canEdit || !canRenamePort(selectedNode)
                              }
                              validationRules={isValidBlockPortNameRuleSet(
                                selectedNode,
                                {
                                  id: portIndex,
                                  side: PortSide.Input,
                                },
                              )}
                            />
                            {(isAdderBlock || isProductBlock) && (
                              <BlockInportSwitch
                                parentPath={parentPath}
                                selectedNode={selectedNode}
                                portIndex={portIndex}
                                disabled={!canEdit}
                              />
                            )}
                            {canEdit &&
                              canDeletePort(
                                selectedNode,
                                minDynamicInputCount,
                                input,
                                PortSide.Input,
                              ) && (
                                <Button
                                  testId={`block-input-remove-button-${portIndex}`}
                                  variant={ButtonVariants.LargeTertiary}
                                  Icon={Remove}
                                  onClick={removeInput(portIndex)}
                                  disabled={!canEdit}
                                />
                              )}
                          </DetailsSection>
                          <BlockPortParameters
                            parentPath={parentPath}
                            selectedNode={selectedNode}
                            canEdit={canEdit}
                            port={input}
                            index={portIndex}
                            paramDefinitions={
                              blockClass.ports.inputs?.dynamic
                                ?.parameter_definitions
                            }
                            portSide={PortSide.Input}
                          />
                        </PortRow>
                      )}
                    </Draggable>
                  ))}
                  {providedDroppable.placeholder}
                </PortDropArea>
              )}
            </Droppable>
          </PortList>
        </DragDropContext>
      )}
    </>
  );
};

export default BlockInportDetails;
