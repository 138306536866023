import { Coordinate } from 'app/common_types/Coordinate';
import { MAXIMUM_ZOOM, MINIMUM_ZOOM } from 'app/slices/cameraSlice';
import { modelActions } from 'app/slices/modelSlice';
import { snapNumberToGrid } from 'app/utils/modelDataUtils';
import { MutableRefObject } from 'react';
import {
  scrollModifierConfig,
  scrollModifierPressed,
} from 'ui/modelRendererInternals/shortcutKeyConfig';
import { detectedOS, OpSys } from 'util/detectOS';
import { pythagoreanDistance } from 'util/pythagoreanDistance';
import {
  SMECamera,
  SMEInteractionTag,
  SMEInteractionTy,
  SMERefsObjTy,
} from './SMETypes';
import {
  coordsToCoordBox,
  createNewStateNode,
  getEntitiesInBox,
} from './SMEUtils';

let singleClickTimer: number | undefined;

export type SMEAllMouseEvent = MouseEvent | React.MouseEvent;
type MouseFn = (e: SMEAllMouseEvent) => void;
const rafIdCache: { [cacheId: string]: number } = {};
export const frameLimitSMEMouseFn =
  (cacheId: string, mouseFn: MouseFn) => (e: SMEAllMouseEvent) => {
    if (rafIdCache[cacheId]) window.cancelAnimationFrame(rafIdCache[cacheId]);
    rafIdCache[cacheId] = window.requestAnimationFrame(() => mouseFn(e));
  };

export type CameraSetter = (newCam: SMECamera) => void;
export const onWheel = (
  event: WheelEvent,
  camRef: SMECamera,
  setCamera: CameraSetter,
) => {
  event.preventDefault();
  event.stopPropagation();
  if (!camRef) return;

  const macOS = detectedOS === OpSys.macOS;

  const newCamera: SMECamera = { ...camRef };

  if (
    macOS &&
    scrollModifierPressed(scrollModifierConfig.panVerticalMacOS, event)
  ) {
    newCamera.y -= event.deltaY / camRef.zoom;
  } else if (scrollModifierPressed(scrollModifierConfig.panHorizontal, event)) {
    newCamera.x -= event.deltaY / camRef.zoom;
  } else if (event.ctrlKey) {
    const zoomAmount =
      (event.deltaY * 0.01) / (!macOS ? 8 / Math.min(1, camRef.zoom) : 1);

    const nextZoom = Math.min(
      Math.max(camRef.zoom - zoomAmount, MINIMUM_ZOOM),
      MAXIMUM_ZOOM,
    );

    const previousCursorX = event.offsetX / camRef.zoom;
    const previousCursorY = event.offsetY / camRef.zoom;

    const newCursorX = event.offsetX / nextZoom;
    const newCursorY = event.offsetY / nextZoom;

    const cameraAdjustX = newCursorX - previousCursorX;
    const cameraAdjustY = newCursorY - previousCursorY;

    newCamera.x = camRef.x + cameraAdjustX;
    newCamera.y = camRef.y + cameraAdjustY;

    newCamera.zoom = nextZoom;
  } else {
    newCamera.x = camRef.x - event.deltaX / camRef.zoom;
    newCamera.y = camRef.y - event.deltaY / camRef.zoom;
  }

  setCamera(newCamera);
};

let previousMouseWorldCoord: Coordinate = { x: 0, y: 0 };
let previousMouseScreenCoord: Coordinate = { x: 0, y: 0 };

export const getMouseWorldAndScreenCoords = (
  e: SMEAllMouseEvent,
  refsObj: MutableRefObject<SMERefsObjTy>,
) => {
  const { camera, stateMachineBgRef } = refsObj.current;

  if (!stateMachineBgRef?.current) {
    return {
      screen: { x: 0, y: 0 },
      world: { x: 0, y: 0 },
    };
  }

  const offRect = stateMachineBgRef.current.getBoundingClientRect();

  const offX = offRect?.left || 0;
  const offY = offRect?.top || 0;

  const mouseScreenCoord = {
    x: e.clientX - offX,
    y: e.clientY - offY,
  };

  return {
    screen: mouseScreenCoord,
    world: {
      x: mouseScreenCoord.x / camera.zoom - camera.x,
      y: mouseScreenCoord.y / camera.zoom - camera.y,
    },
  };
};

export const registerAllSMEMouseInput = (
  refsObj: MutableRefObject<SMERefsObjTy>,
): (() => void) => {
  const stateMachineBgRef = refsObj.current.stateMachineBgRef;

  const wheelHandler = (e: WheelEvent) => {
    onWheel(e, refsObj.current.camera, refsObj.current.setCamera);
  };

  const mouseMoveHandler = frameLimitSMEMouseFn(
    'mouseMoveHandler',
    (e: SMEAllMouseEvent) => {
      if (!stateMachineBgRef?.current) return;

      const camera = refsObj.current.camera;
      const { screen: mouseScreenCoord, world: mouseWorldCoord } =
        getMouseWorldAndScreenCoords(e, refsObj);

      refsObj.current.setMouseCoords(mouseWorldCoord);

      const mouseWorldMovement = {
        x: mouseWorldCoord.x - previousMouseWorldCoord.x,
        y: mouseWorldCoord.y - previousMouseWorldCoord.y,
      };
      const mouseScreenMovement = {
        x: mouseScreenCoord.x - previousMouseScreenCoord.x,
        y: mouseScreenCoord.y - previousMouseScreenCoord.y,
      };

      const interaction = refsObj.current.interactionState;

      if (interaction.tag === SMEInteractionTag.Panning) {
        refsObj.current.setCamera({
          x: mouseScreenMovement.x / camera.zoom + camera.x,
          y: mouseScreenMovement.y / camera.zoom + camera.y,
          zoom: camera.zoom,
        });
      }

      if (
        interaction.tag === SMEInteractionTag.ReadyToDragSelect &&
        pythagoreanDistance(mouseWorldCoord, interaction.startCoord) > 4
      ) {
        const newInteractionState: SMEInteractionTy = {
          tag: SMEInteractionTag.SelectDragRect,
          startCoord: interaction.startCoord,
        };
        refsObj.current.setInteraction(newInteractionState);
      }

      // always true because of the above, but we need to type-safely access the state
      if (interaction.tag === SMEInteractionTag.SelectDragRect) {
        const { nodeIds, linkIds } = getEntitiesInBox(
          coordsToCoordBox(interaction.startCoord, mouseWorldCoord),
          refsObj.current.stateNodes,
          refsObj.current.stateLinks,
          refsObj.current.nodeLUT,
        );
        refsObj.current.setSelectedNodeIds(nodeIds);
        refsObj.current.setSelectedLinkIds(linkIds);
      }

      if (interaction.tag === SMEInteractionTag.DraggingNodes) {
        if (refsObj.current.readOnly || !refsObj.current.dispatch) return;
        refsObj.current.dispatch(
          modelActions.moveStateNodesByDelta({
            stateMachineUuid: refsObj.current.stateMachineId,
            nodeIds: refsObj.current.selectedNodeIds,
            delta: {
              x: mouseWorldMovement.x,
              y: mouseWorldMovement.y,
            },
          }),
        );
      }

      previousMouseWorldCoord = mouseWorldCoord;
      previousMouseScreenCoord = mouseScreenCoord;
    },
  );

  const bgMouseDownHandler = (e: MouseEvent) => {
    if (!stateMachineBgRef?.current) return;
    if (e.target !== stateMachineBgRef.current) return;

    const { world: mouseWorldCoord } = getMouseWorldAndScreenCoords(e, refsObj);

    // this is a check for left-click
    if (e.button == 0) {
      refsObj.current.setInteraction({
        tag: SMEInteractionTag.ReadyToDragSelect,
        startCoord: mouseWorldCoord,
      });
    }

    // this is a check for middle-click
    if (e.button === 1) {
      refsObj.current.setInteraction({
        tag: SMEInteractionTag.Panning,
      });
    }
  };

  const bgMouseUpHandler = (e: MouseEvent) => {
    if (e.button === 1) {
      refsObj.current.setInteraction({
        tag: SMEInteractionTag.None,
      });
    }
  };

  const bgClickHandler = (e: MouseEvent) => {
    if (refsObj.current.readOnly || !stateMachineBgRef?.current) return;

    const isIdleInteraction =
      refsObj.current.interactionState.tag === SMEInteractionTag.None ||
      refsObj.current.interactionState.tag ===
        SMEInteractionTag.ReadyToDragSelect;
    const isContinuousState =
      refsObj.current.interactionState.tag === SMEInteractionTag.DrawingLink ||
      refsObj.current.interactionState.tag ===
        SMEInteractionTag.ReDraggingLink ||
      refsObj.current.interactionState.tag ===
        SMEInteractionTag.DraggingEntryPoint;

    if (e.detail === 1) {
      singleClickTimer = window.setTimeout(() => {
        // handle BG single-click
        if (isIdleInteraction) {
          if (e.target !== stateMachineBgRef.current) return;
          refsObj.current.setSelectedNodeIds([]);
          refsObj.current.setSelectedLinkIds([]);
        }
      }, 200);
    }
    if (e.detail === 2) {
      clearTimeout(singleClickTimer);
      if (isIdleInteraction) {
        if (e.target !== stateMachineBgRef.current) return;
        const { world: mouseWorldCoord } = getMouseWorldAndScreenCoords(
          e,
          refsObj,
        );

        const stateMachineId = refsObj.current.stateMachineId;
        if (stateMachineId && refsObj.current.dispatch) {
          refsObj.current.dispatch(
            modelActions.addStateNode({
              stateMachineUuid: stateMachineId,
              newStateNode: createNewStateNode(
                'state',
                snapNumberToGrid(mouseWorldCoord.x),
                snapNumberToGrid(mouseWorldCoord.y),
              ),
            }),
          );
        }
      }
    }

    if (!isContinuousState) {
      refsObj.current.setInteraction({
        tag: SMEInteractionTag.None,
      });
    }
  };

  document.addEventListener('mousemove', mouseMoveHandler);
  if (stateMachineBgRef?.current) {
    stateMachineBgRef.current.addEventListener('mousedown', bgMouseDownHandler);
    stateMachineBgRef.current.addEventListener('mouseup', bgMouseUpHandler);
    stateMachineBgRef.current.addEventListener('click', bgClickHandler);
    stateMachineBgRef.current.addEventListener('wheel', wheelHandler);
  }

  return () => {
    document.removeEventListener('mousemove', mouseMoveHandler);
    if (stateMachineBgRef?.current) {
      stateMachineBgRef.current.removeEventListener(
        'mousedown',
        bgMouseDownHandler,
      );
      stateMachineBgRef.current.removeEventListener(
        'mouseup',
        bgMouseUpHandler,
      );
      stateMachineBgRef.current.removeEventListener('click', bgClickHandler);
      stateMachineBgRef.current.removeEventListener('wheel', wheelHandler);
    }
  };
};
