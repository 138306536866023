import styled from '@emotion/styled';
import { t } from '@lingui/macro';
import { getModel } from 'app/apiData';
import { generatedApi } from 'app/apiGenerated/generatedApi';
import {
  DownsamplingAlgorithm,
  ModelWithSubmodels,
} from 'app/apiGenerated/generatedApiTypes';
import { SimulationModel } from 'app/generated_types/SimulationModel';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { visualizerActions } from 'app/slices/visualizerSlice';
import { downloadSimulationDump } from 'app/utils/simulationDump';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EditorMode,
  useModelEditorInfo,
} from 'ui/appBottomBar/assistant/useModelEditorInfo';
import { isAdminUser } from 'ui/auth/utils';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import {
  TreeArrowCollapsed,
  TreeArrowExpanded,
} from 'ui/common/Icons/Standard';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput from 'ui/common/SelectInput';
import { useNotifications } from 'ui/common/notifications/useNotifications';
import { detectedOS, OpSys } from 'util/detectOS';
import { useAppParams } from 'util/useAppParams';
import { writeToClipboard } from 'util/writeToClipboard';
import {
  DetailInputRowsSection,
  DetailsLabel,
  DetailsSection,
} from './DetailsComponents';
import ImportModelFromClipboardButton from './ImportModelFromClipboard';

const DevButton = styled(Button)`
  padding: 0 ${({ theme }) => theme.spacing.normal};
`;

const developerOptionsVisSampleAlgorithm: DownsamplingAlgorithm[] = [
  'LTTB',
  'LTD',
  'uniform',
  'none',
];
const developerOptionsVisSampleAlgorithmOptions =
  developerOptionsVisSampleAlgorithm.map((o) => ({
    value: o,
    label: o,
  }));

const DeveloperOptionsDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showError } = useNotifications();
  const simulationId = useAppSelector(
    (state) => state.project.simulationSummary?.uuid,
  );
  const { developerModeEnabled } = useAppSelector(
    (state) => state.userOptions.options,
  );
  const userId = useAppSelector((state) => state.user.userId);

  const downsamplingAlgorithm = useAppSelector(
    (state) => state.visualizer.downsamplingAlgorithm,
  );

  const config = useAppSelector((state) => state.model.present.configuration);
  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );
  const model = useAppSelector((state) => state.model.present);

  const { editorMode, modelInEditorUuid } = useModelEditorInfo();

  const { projectId } = useAppParams();

  const [modelWithSubmodelsTrigger] =
    generatedApi.endpoints.getModelWithSubmodelsReadByUuid.useLazyQuery();

  const [submodelWithSubmodelsTrigger] =
    generatedApi.endpoints.getSubmodelWithSubmodels.useLazyQuery();

  const changeDeveloperOptionSampleAlgorithm = (
    value: DownsamplingAlgorithm,
  ) => {
    if (developerOptionsVisSampleAlgorithm.includes(value)) {
      dispatch(visualizerActions.setDownsamplingAlgorithm(value));
    }
  };

  const copyToClipboard = async () => {
    writeToClipboard(JSON.stringify(model, undefined, 2));
  };

  const exportFullModel = async () => {
    if (!loadedModelId) return;
    getModel(
      loadedModelId,
      (modelData: SimulationModel) => {
        writeToClipboard(JSON.stringify(modelData, undefined, 2));
      },
      (e) => console.error('could not export full model:', e),
    );
  };

  const exportFullModelWithSubmodels = async () => {
    if (!loadedModelId) return;
    if (editorMode === EditorMode.Submodel) {
      if (!projectId) return;
      await submodelWithSubmodelsTrigger({
        submodelUuid: modelInEditorUuid,
        projectUuid: projectId,
      })
        .unwrap()
        .then((result: ModelWithSubmodels) =>
          writeToClipboard(JSON.stringify(result, undefined, 2)),
        )
        .catch((e) => console.error('could not export full model:', e));
    } else {
      await modelWithSubmodelsTrigger({ modelUuid: modelInEditorUuid })
        .unwrap()
        .then((result: ModelWithSubmodels) =>
          writeToClipboard(JSON.stringify(result, undefined, 2)),
        )
        .catch((e) => console.error('could not export full model:', e));
    }
  };

  const downloadSimDump = async () => {
    if (!simulationId) return;
    try {
      downloadSimulationDump(simulationId);
    } catch (e) {
      showError('Failed to download the simulation files archive', e);
    }
  };

  const [isExpanded, setExpanded] = React.useState(false);

  const macOS = detectedOS === OpSys.macOS;
  const isRenderDebug = useAppSelector((state) => state.uiFlags.renderDebug);
  const toggleRenderDebug = () =>
    dispatch(uiFlagsActions.setUIFlag({ renderDebug: !isRenderDebug }));

  if (!isAdminUser()) return null;

  return developerModeEnabled ? (
    <>
      <SectionHeading
        testId="developer-options"
        ButtonIcon={isExpanded ? TreeArrowExpanded : TreeArrowCollapsed}
        onButtonClick={() => setExpanded(!isExpanded)}>
        {t({
          id: 'modelRenderer.propertiesSidebar.developerOptions.heading',
          message: 'Developer options',
        })}
      </SectionHeading>
      {isExpanded ? (
        <DetailInputRowsSection key="sim_developer_options">
          <>
            <DetailsSection>
              <DevButton
                testId="enable-keypress-debug"
                variant={ButtonVariants.SmallTertiary}
                onClick={() => navigate(`/admin/users/${userId}`)}>
                Change your user options
              </DevButton>
            </DetailsSection>
            <DetailsSection>
              <DetailsLabel>
                {t({
                  id: 'modelRenderer.propertiesSidebar.developerOptions.visAlgoritm.label',
                  message: 'Visualizer sample algorithm',
                })}
              </DetailsLabel>
              <SelectInput
                options={developerOptionsVisSampleAlgorithmOptions}
                currentValue={downsamplingAlgorithm}
                onSelectValue={(v) =>
                  changeDeveloperOptionSampleAlgorithm(
                    v as DownsamplingAlgorithm,
                  )
                }
              />
            </DetailsSection>
            <DetailsSection>
              <DevButton
                variant={ButtonVariants.SmallTertiary}
                onClick={copyToClipboard}>
                Export model from React state
              </DevButton>
            </DetailsSection>
            <DetailsSection>
              <DevButton
                variant={ButtonVariants.SmallTertiary}
                onClick={exportFullModel}>
                Export model from backend
              </DevButton>
            </DetailsSection>
            <DetailsSection>
              <DevButton
                variant={ButtonVariants.SmallTertiary}
                onClick={exportFullModelWithSubmodels}>
                Export model with submodels from backend
              </DevButton>
            </DetailsSection>
            <DetailsSection>
              <ImportModelFromClipboardButton />
            </DetailsSection>
            <DetailsSection>
              <DevButton
                testId="download-last-sim-artifacts-button"
                variant={ButtonVariants.SmallTertiary}
                onClick={downloadSimDump}
                disabled={!simulationId}>
                Download last simulation artifacts
              </DevButton>
            </DetailsSection>
            <DetailsSection>
              <DevButton
                variant={ButtonVariants.SmallTertiary}
                onClick={toggleRenderDebug}>
                {macOS
                  ? 'Toggle render debug (Cmd+Shift+K)'
                  : 'Toggle render debug (Alt+Shift+K)'}
              </DevButton>
            </DetailsSection>
          </>
        </DetailInputRowsSection>
      ) : null}
    </>
  ) : null;
};

export default DeveloperOptionsDetails;
