import {
  LinkInstance,
  NodeInstance,
  ModelDiagram,
  LinkSegmentType,
} from 'app/generated_types/SimulationModel';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';
import { WritableDraft } from 'immer/dist/types/types-external';
import { getPortWorldCoordinate } from 'app/utils/getPortOffsetCoordinate';
import { PortSide } from 'app/common_types/PortTypes';
import { getLinkTapCoordinate } from 'app/utils/tapLinkToRenderData';
import { FakeSegmentType } from 'app/common_types/SegmentTypes';
import {
  linkToRenderData,
  VertexSegmentType,
} from 'app/utils/linkToRenderData';
import { snapNumberToGrid } from 'app/utils/modelDataUtils';

// This function takes a link with any amount of fake segements,
// finds its "render data" for the current frame,
// and then turns all of those fake segments in to real segment data
// using the position and metadata in the link's "render data".
// You can optionally "re-calculate" that render data if the link itself has changed
// since the previous frame. (common when changing the structure of a link,
// e.g. when a block is inserted into a link and it's split in half. Necessary because
// link tap points need a link to be "real" in order to behave in a stable way.)
export function reifyLink_mut(
  link: WritableDraft<LinkInstance>,
  fromEnd?: boolean,
  recalcRenderData?: boolean,
  overrideNodes?: NodeInstance[],
) {
  const rs = rendererState;
  if (!rs) return;

  const renderDataIndex = rs.linksRenderFrameDataIndexLUT[link.uuid];
  let linkRenderData = rs.linksRenderFrameData[renderDataIndex];

  if (!linkRenderData || recalcRenderData) {
    linkRenderData = linkToRenderData(
      rs,
      link,
      overrideNodes || rs.refs.current.nodes,
      rs.refs.current.nodesIndexLUT,
      rs.camera.x,
      rs.camera.y,
    );
    rs.linksRenderFrameData[renderDataIndex] = linkRenderData;
  }

  let fakeSegmentType = FakeSegmentType.SStart;
  if (fromEnd) {
    fakeSegmentType =
      link.uiprops.segments.length == 0
        ? FakeSegmentType.SEnd
        : FakeSegmentType.End;
  } else {
    fakeSegmentType =
      link.uiprops.segments.length == 0
        ? FakeSegmentType.SStart
        : FakeSegmentType.Start;
  }

  const shouldPrepend = fakeSegmentType === FakeSegmentType.Start;
  const limitToSameFakeType =
    fakeSegmentType === FakeSegmentType.Start ||
    fakeSegmentType === FakeSegmentType.End;
  let metSameType = false;

  let newSegments: LinkSegmentType[] = [];
  let previousReificationDir: 'horiz' | 'vert' | undefined;

  for (let i = 0; i < linkRenderData.vertexData.length - 1; i++) {
    const curVertex = linkRenderData.vertexData[i];

    if (limitToSameFakeType && !metSameType) {
      metSameType =
        curVertex.segmentType === VertexSegmentType.Fake &&
        curVertex.fakeSegmentType === fakeSegmentType;
    }

    if (
      limitToSameFakeType &&
      metSameType &&
      (curVertex.segmentType !== VertexSegmentType.Fake ||
        curVertex.fakeSegmentType !== fakeSegmentType)
    ) {
      break;
    }

    if (
      curVertex.segmentType !== VertexSegmentType.Fake ||
      (limitToSameFakeType && !metSameType)
    ) {
      continue;
    }

    for (let j = 0; j < curVertex.reificationData.length; j++) {
      const reificationData = curVertex.reificationData[j];
      if (previousReificationDir === reificationData.segment_direction) {
        continue;
      } else {
        newSegments.push(reificationData);
      }

      previousReificationDir = reificationData.segment_direction;
    }
  }

  if (shouldPrepend) {
    link.uiprops.segments = newSegments.concat(link.uiprops.segments);
  } else {
    link.uiprops.segments = link.uiprops.segments.concat(newSegments);
  }
}

export function setLinkSourceAndDependentTapSources(
  linkUuid: string,
  newSrc:
    | { node: string; port: number; port_side?: 'inputs' | 'outputs' }
    | undefined,
  modelLinks: LinkInstance[],
  linksIndexLUT?: { [k: string]: number },
) {
  // this function heavily uses the refs from rendererState
  // which saves us a lot of redundant calculation, thus improving performance.
  // it probably seems hacky but it's actually fine,
  // rendererState is a singleton and the refs are created by react.
  // (same lifecycle consequences as passing them directly to a reducer function)
  if (!rendererState) return;
  const renRefs = rendererState.refs.current;

  const resourcingLinkUuids = [linkUuid];
  // this prevents any possibility of infinite loop.
  // this should not be necessary as our circular-tap-dependency checks
  // will improve soon, though it's better to be safe than sorry!
  const resourcedLinkUuids = new Set();

  while (resourcingLinkUuids.length) {
    const resourcingLinkUuid = resourcingLinkUuids.pop();
    if (!resourcingLinkUuid) continue;
    const resourcingLink = linksIndexLUT
      ? modelLinks[linksIndexLUT[resourcingLinkUuid]]
      : modelLinks.find((l) => l.uuid === resourcingLinkUuid);

    resourcedLinkUuids.add(resourcingLinkUuid);
    if (!resourcingLink) continue;

    resourcingLink.src = newSrc;

    const dependentTapLinkUuids =
      renRefs.linksRenderingDependencyTree[resourcingLinkUuid];
    if (!dependentTapLinkUuids) continue;

    for (let i = 0; i < dependentTapLinkUuids.length; i++) {
      if (!resourcedLinkUuids.has(dependentTapLinkUuids[i])) {
        resourcingLinkUuids.push(dependentTapLinkUuids[i]);
      }
    }
  }
}

export function setTappingLinkTapUuidAndSource(
  tappingLink: WritableDraft<LinkInstance>,
  tappedLink: LinkInstance,
  modelLinks: LinkInstance[],
) {
  if (tappingLink.uiprops.link_type.connection_method !== 'link_tap') return;

  tappingLink.uiprops.link_type.tapped_link_uuid = tappedLink.uuid;
  setLinkSourceAndDependentTapSources(
    tappingLink.uuid,
    tappedLink.src,
    modelLinks,
    rendererState?.refs?.current?.linksIndexLUT,
  );
}

function autoRelocateTapToRealSegmentIndex(
  tap: WritableDraft<LinkInstance> | undefined,
  newSegIdx: number,
  tappedLinkSegments: {
    segment_direction: 'horiz' | 'vert';
    coordinate: number;
  }[],
) {
  if (!tap) return;

  if (
    tap.uiprops.link_type.connection_method === 'link_tap' &&
    tap.uiprops.link_type.tapped_segment.segment_type === 'real'
  ) {
    const oldSegIdx = tap.uiprops.link_type.tapped_segment.tapped_segment_index;
    const oldSeg = tappedLinkSegments[oldSegIdx];
    const newSeg = tappedLinkSegments[newSegIdx];

    // tap coordinate changes from X to Y (or vice versa)
    // if the new tapped segment's directionality is different.
    // the only valid value we can pull for this
    // is the old segment's coordinate.
    if (oldSeg.segment_direction !== newSeg.segment_direction) {
      tap.uiprops.link_type.tap_coordinate = oldSeg.coordinate;
    }

    tap.uiprops.link_type.tapped_segment.tapped_segment_index = newSegIdx;
  }
}

export function removeAlignedLinkSegments(
  link: WritableDraft<LinkInstance>,
  linkSrcNode: NodeInstance | undefined,
  linkDstNode: NodeInstance | undefined,
  taps: WritableDraft<LinkInstance>[],
) {
  let removedSegments = 0;

  const firstSegmentsHaveTaps =
    taps.find(
      (tap) =>
        tap.uiprops.link_type.connection_method === 'link_tap' &&
        tap.uiprops.link_type.tapped_segment.segment_type === 'real' &&
        (tap.uiprops.link_type.tapped_segment.tapped_segment_index === 0 ||
          tap.uiprops.link_type.tapped_segment.tapped_segment_index === 1),
    ) !== undefined;

  const lastSegmentsHaveTaps =
    taps.find(
      (tap) =>
        tap.uiprops.link_type.connection_method === 'link_tap' &&
        tap.uiprops.link_type.tapped_segment.segment_type === 'real' &&
        (tap.uiprops.link_type.tapped_segment.tapped_segment_index ===
          link.uiprops.segments.length - 1 ||
          tap.uiprops.link_type.tapped_segment.tapped_segment_index ===
            link.uiprops.segments.length - 2),
    ) !== undefined;

  for (let i = 0; i < link.uiprops.segments.length; i++) {
    const maxIndex = link.uiprops.segments.length - 1 - removedSegments;
    if (i > maxIndex) break;

    const curSegIdx = i;
    const curDir = link.uiprops.segments[curSegIdx].segment_direction;
    const curCoord = link.uiprops.segments[curSegIdx].coordinate;

    const nextSegIdx = curSegIdx + 1;
    const nextAlignSegIdx = nextSegIdx + 1;
    const nextAlignSeg = link.uiprops.segments[nextAlignSegIdx];
    const nextSeg = link.uiprops.segments[nextSegIdx];

    let nextSegmentsToRemove = 0;

    if (nextSeg && nextSeg.segment_direction === curDir) {
      console.warn(
        `Caution: consecutive same-direction segments detected in link: ${link.uuid}\n` +
          'This is invalid, so they will be automatically removed.',
      );

      nextSegmentsToRemove = 1;
    }

    if (
      nextSeg &&
      nextAlignSeg &&
      nextSeg.segment_direction !== curDir &&
      nextAlignSeg.segment_direction === curDir &&
      nextAlignSeg.coordinate === curCoord
    ) {
      nextSegmentsToRemove = 2;
    }

    if (nextSegmentsToRemove > 0) {
      for (let j = 0; j < taps.length; j++) {
        const relocTap = taps[j];

        if (
          relocTap &&
          relocTap.uiprops.link_type.connection_method === 'link_tap' &&
          relocTap.uiprops.link_type.tapped_segment.segment_type === 'real' &&
          relocTap.uiprops.link_type.tapped_segment.tapped_segment_index >
            curSegIdx
        ) {
          const tsi =
            relocTap.uiprops.link_type.tapped_segment.tapped_segment_index;
          const newSegIdx =
            tsi - curSegIdx === 1 ? tsi - 1 : tsi - nextSegmentsToRemove;

          autoRelocateTapToRealSegmentIndex(
            taps[j],
            newSegIdx,
            link.uiprops.segments,
          );
        }
      }

      removedSegments += nextSegmentsToRemove;
      link.uiprops.segments.splice(i + 1, nextSegmentsToRemove);
    }
  }

  const linkIsTapping = link.uiprops.link_type.connection_method === 'link_tap';
  const tappedSegmentDirection =
    link.uiprops.link_type.connection_method === 'link_tap'
      ? link.uiprops.link_type.tapped_segment.tapped_segment_direction
      : 'vert';

  const linkStartCoordinate = linkIsTapping
    ? rendererState
      ? getLinkTapCoordinate(rendererState, link)
      : { x: 0, y: 0 }
    : linkSrcNode
    ? getPortWorldCoordinate(linkSrcNode, PortSide.Output, link.src)
    : link.uiprops.hang_coord_start;

  const linkEndCoordinate = linkDstNode
    ? getPortWorldCoordinate(linkDstNode, PortSide.Input, link.dst)
    : link.uiprops.hang_coord_start;

  // handle second (first alignable) segment's alignment
  // with link start coordinate
  const firstSegment = link.uiprops.segments[0];
  const secondSegment = link.uiprops.segments[1];

  const onlyOneSegmentStraightLineAndNotTapping =
    !linkIsTapping &&
    link.uiprops.segments.length === 1 &&
    linkStartCoordinate &&
    linkEndCoordinate &&
    linkStartCoordinate.y === linkEndCoordinate.y;

  const firstSegmentAlignedWithStartAndTappingHoriz =
    linkIsTapping &&
    firstSegment &&
    tappedSegmentDirection == 'horiz' &&
    firstSegment.segment_direction == 'vert' &&
    firstSegment.coordinate === linkStartCoordinate?.x;

  const secondSegmentAlignedWithStart =
    (!linkIsTapping &&
      secondSegment &&
      secondSegment.coordinate === linkStartCoordinate?.y &&
      secondSegment.segment_direction == 'horiz') ||
    (linkIsTapping &&
      secondSegment &&
      (tappedSegmentDirection == 'horiz'
        ? secondSegment.segment_direction == 'vert' &&
          secondSegment.coordinate === linkStartCoordinate?.x
        : secondSegment.segment_direction == 'horiz' &&
          secondSegment.coordinate === linkStartCoordinate?.y));

  const tapIdxAdjust = secondSegmentAlignedWithStart
    ? 2
    : onlyOneSegmentStraightLineAndNotTapping ||
      firstSegmentAlignedWithStartAndTappingHoriz
    ? 1
    : 0;

  if (
    !firstSegmentsHaveTaps &&
    !lastSegmentsHaveTaps &&
    (onlyOneSegmentStraightLineAndNotTapping ||
      firstSegmentAlignedWithStartAndTappingHoriz ||
      secondSegmentAlignedWithStart)
  ) {
    for (let j = 0; j < taps.length; j++) {
      const relocTap = taps[j];
      if (
        relocTap &&
        relocTap.uiprops.link_type.connection_method === 'link_tap' &&
        relocTap.uiprops.link_type.tapped_segment.segment_type === 'real' &&
        relocTap.uiprops.link_type.tapped_segment.tapped_segment_index >
          tapIdxAdjust - 1
      ) {
        const tsi =
          relocTap.uiprops.link_type.tapped_segment.tapped_segment_index;
        const newSegIdx = tsi - tapIdxAdjust;

        autoRelocateTapToRealSegmentIndex(
          taps[j],
          newSegIdx,
          link.uiprops.segments,
        );
      }
    }

    link.uiprops.segments.splice(0, tapIdxAdjust);
  }

  // handle second to last (last alignable) segment's alignment
  // with link end coordinate
  const secondToLastSegment =
    link.uiprops.segments[link.uiprops.segments.length - 2];

  if (
    !firstSegmentsHaveTaps &&
    !lastSegmentsHaveTaps &&
    secondToLastSegment &&
    secondToLastSegment.coordinate === linkEndCoordinate?.y &&
    secondToLastSegment.segment_direction == 'horiz'
  ) {
    link.uiprops.segments.splice(link.uiprops.segments.length - 2, 2);
  }
}

export function connectTwoLinks_mut(
  model: ModelDiagram,
  inputIconSideLinkUuid: string,
  outputIconSideLinkUuid: string,
) {
  let inputIconLink = model.links.find((l) => l.uuid === inputIconSideLinkUuid);
  let outputIconLink = model.links.find(
    (l) => l.uuid === outputIconSideLinkUuid,
  );

  if (!inputIconLink || !outputIconLink) return;

  reifyLink_mut(inputIconLink, true);
  reifyLink_mut(outputIconLink);

  const finalInputSegment =
    inputIconLink.uiprops.segments[inputIconLink.uiprops.segments.length - 1];
  const firstOutputSegment = outputIconLink.uiprops.segments[0];

  let extraRemovedSegments = 0;
  if (
    finalInputSegment &&
    firstOutputSegment &&
    finalInputSegment.segment_direction === firstOutputSegment.segment_direction
  ) {
    outputIconLink.uiprops.segments.shift();
    extraRemovedSegments += 1;
  }

  const tapLinks = model.links.filter(
    (l) =>
      l.uiprops.link_type.connection_method === 'link_tap' &&
      l.uiprops.link_type.tapped_link_uuid === outputIconLink?.uuid,
  );

  for (let i = 0; i < tapLinks.length; i++) {
    const tapLink = tapLinks[i];

    setTappingLinkTapUuidAndSource(tapLink, inputIconLink, model.links);

    if (
      tapLink.uiprops.link_type.connection_method === 'link_tap' &&
      tapLink.uiprops.link_type.tapped_segment.segment_type === 'real'
    ) {
      tapLink.uiprops.link_type.tapped_segment.tapped_segment_index +=
        inputIconLink.uiprops.segments.length + extraRemovedSegments;
    }
  }

  inputIconLink.dst = outputIconLink.dst;
  inputIconLink.uiprops.hang_coord_end = outputIconLink.uiprops.hang_coord_end;

  inputIconLink.uiprops.segments = inputIconLink.uiprops.segments.concat(
    outputIconLink.uiprops.segments,
  );

  const segments = inputIconLink.uiprops.segments;

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    segment.coordinate = snapNumberToGrid(segment.coordinate);
  }

  const linkSrcNode = model.nodes.find(
    (l) => l.uuid === inputIconLink?.src?.node,
  );
  const linkDstNode = model.nodes.find(
    (l) => l.uuid === inputIconLink?.dst?.node,
  );
  const finalTaps = model.links.filter(
    (l) =>
      l.uiprops.link_type.connection_method === 'link_tap' &&
      l.uiprops.link_type.tapped_link_uuid === inputIconLink?.uuid,
  );

  removeAlignedLinkSegments(inputIconLink, linkSrcNode, linkDstNode, finalTaps);
  removeAlignedLinkSegments(inputIconLink, linkSrcNode, linkDstNode, finalTaps);

  model.links = model.links.filter((l) => l.uuid !== outputIconLink?.uuid);
}
