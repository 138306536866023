import blockTypeNameToInstanceDefaults from 'app/blockClassNameToInstanceDefaults';
import type { Coordinate } from 'app/common_types/Coordinate';
import { modelActions } from 'app/slices/modelSlice';
import { AppDispatch } from 'app/store';
import hotkeys from 'hotkeys-js';
import * as NVG from 'nanovg-js';
import { MutableRefObject } from 'react';
import {
  RendererRefsType,
  TransformFunc,
} from 'ui/modelEditor/ModelRendererWrapper';
import { drawScene } from 'ui/modelRendererInternals/drawScene';
import { mouseInput } from 'ui/modelRendererInternals/mouseInput';

import { userPreferencesActions } from 'app/slices/userPreferencesSlice';
import { FPS60_MILLIS } from 'app/utils/GeneralConstants';
import { onBrowserFocusChange } from 'util/onBrowserFocusChange';
import { pythagoreanDistance } from 'util/pythagoreanDistance';

import { LinkInstance, NodeInstance } from '@collimator/model-schemas-ts';
import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import {
  ClickStates,
  HoverEntity,
  MouseActions,
  MouseClickState,
  MouseState,
} from 'app/common_types/MouseTypes';
import { PortSide } from 'app/common_types/PortTypes';
import { SubmodelInstance } from 'app/generated_types/SimulationModel';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { rcMenuActions } from 'app/slices/rcMenuSlice';
import { uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { LinkRenderData, linkToRenderData } from 'app/utils/linkToRenderData';
import { updateSubmodelInstanceForReferenceChanges } from 'app/utils/modelSubmodelFixupUtils';
import { renderConstants } from 'app/utils/renderConstants';
import { calculateTextSize } from 'util/calculateTextSize';
import { lineIntersect90Deg } from 'util/lineIntersectPoint';
import { blockIconIDsList } from './blockIconIDsList';
import { mouseInputClick } from './clickHandlers/mouseInputClick';
import { mouseInputClickHold } from './clickHandlers/mouseInputClickHold';
import { mouseInputDoubleClick } from './clickHandlers/mouseInputDoubleClick';
import { mouseInputMiddleClick } from './clickHandlers/mouseInputMiddleClick';
import { mouseInputMiddleClickHold } from './clickHandlers/mouseInputMiddleClickHold';
import { mouseInputRightClick } from './clickHandlers/mouseInputRightClick';
import { convertZoomedScreenToWorldCoordinates } from './convertScreenToWorldCoordinates';
import { drawNode } from './drawNode';
import { getCursorVisualState } from './getCursorVisualState';
import { getVertexHitboxForIndex } from './getVertexHitboxForIndex';
import { PORT_BLOCK_YOFFSET, getVisualNodeHeight } from './getVisualNodeHeight';
import { getVisualNodeWidth } from './getVisualNodeWidth';
import {
  RasterLoadState,
  allocMemImageAndIntoStoreFromImageBuffer,
  deleteAllImagesFromMemAndStore,
  getOrInitLoadImageFromStore,
  loadArrayBufferPromise,
  rasterMetaStore,
} from './rasterTextureStore';
import {
  SingleShortcutConfig,
  clickModifierConfig,
  shortcutsConfig,
} from './shortcutKeyConfig';
import { transitionMouseState } from './transitionMouseState';
import { findNextZoomLevel } from './zoom';

// Distance below which down+up are only clicks.
const MIN_DISTANCE_FOR_DRAG = renderConstants.GRID_UNIT_PXSIZE / 2;

const preloadedRastersPromises = [
  ...blockIconIDsList.map((id) => `renderer_icon_rasters/${id}`),
  'text_fader',
  'plotter_toggle_active',
  'plotter_toggle_inactive',
  'plotter_toggle_partial', // ? does this work correctly? The rasters have suffixes and have 4 versions.
  'input_port',
  'input_port_trigger',
  'link_end_input_blank',
  'link_occlusion_v',
  'link_occlusion_h',
  'continuous_signal_label_icon',
  'discrete_signal_label_icon',
  'matrix_signal_type_icon',
  'scalar_signal_type_icon',
  'vector_signal_type_icon',
  'tuned_indicator',
].reduce(
  (
    acc: { iconID: string; bufferPromise: Promise<ArrayBuffer | undefined> }[],
    iconID: string,
  ) => {
    const scales = [1, 2, 4];

    return [
      ...acc,
      ...scales.map((scale) => {
        const scaledIconID = `${iconID}_${scale}x`;
        rasterMetaStore[scaledIconID] = {
          loadState: RasterLoadState.Loading,
        };

        return {
          iconID: scaledIconID,
          bufferPromise: loadArrayBufferPromise(
            `${process.env.PUBLIC_URL}/assets/${scaledIconID}.png`,
          ),
        };
      }),
    ];
  },
  [],
);

let gl: WebGLRenderingContext | null = null;
const done = false;
let windowResizer = (_event: UIEvent) => {};

export type PortConnListType = Array<{
  fullyConnected: boolean;
  side: PortSide;
  portId: number;
  linkUuid: string;
}>;

export interface PortConnLUTType {
  [k: string]: PortConnListType;
}

export interface RendererState {
  camera: Coordinate;
  zoom: number;
  cursorOverCanvas: boolean;
  screenCursorRaw: Coordinate;
  screenCursorZoomed: Coordinate;
  clickState: MouseClickState;
  mouseState: MouseState;
  linksRenderFrameData: LinkRenderData[];
  linksRenderFrameDataIndexLUT: { [uuid: string]: number };
  linksOcclusionPointLUT: { [uuid: string]: Array<LinkIntersectionCoordinate> };
  refs: MutableRefObject<RendererRefsType>;
  setTransform: TransformFunc;
  dispatch: AppDispatch;
  hoveringEntity: HoverEntity | undefined;
}

// Mutation is required for performance of graphics relative to redux:
// eslint-disable-next-line import/no-mutable-exports
export let rendererState: RendererState | null = null;

let nvg: NVG.Context | null = null;

// FIXME: This may not be properly kept up-to-date. Browser key, mouse/pointer
// events contain the proper info about which modifier keys are pressed.
// Non-modifier keys will trigger their own keydown/keyup events, and if we
// really need to keep track of them, we should merge this into rendererState.
export const keysPressed: { [k: string]: boolean } = {};

const onBlurOrFocus = () => {
  const keyKeys = Object.keys(keysPressed);
  for (let i = 0; i < keyKeys.length; i++) {
    keysPressed[keyKeys[i]] = false;
  }
};

onBrowserFocusChange(onBlurOrFocus, onBlurOrFocus);

function keyDown(event: KeyboardEvent): void {
  let keyName = event.code;

  if (keyName.indexOf('Arrow') === -1) {
    keyName = keyName.replace('Left', '').replace('Right', '');
  }

  keysPressed[keyName] = true;
}

function keyUp(event: KeyboardEvent): void {
  // the alt key seems to be problematic in getting stuck,
  // so we'll take an aggressive approach on it for now.
  if (!event.altKey) {
    keysPressed.Alt = false;
  }

  let keyName = event.code;

  if (keyName.indexOf('Arrow') === -1) {
    keyName = keyName.replace('Left', '').replace('Right', '');
  }

  keysPressed[keyName] = false;

  // on macOS, it is impossible to get keyUp events for keys while "cmd" is pressed.
  // this is well-documented, and there is no way to get around it.
  // so, we broadly emulate keyups that happened during the "cmd" key being held
  // by just clearing every pressed key. this should have minimal side effects
  // because there is no real UX case for keeping other keys held
  // after cmd is released.
  if (keyName === 'Meta') {
    const pressedKeyNames = Object.keys(keysPressed);
    for (let i = 0; i < pressedKeyNames.length; i++) {
      keysPressed[pressedKeyNames[i]] = false;
    }
  }
}

let canvasBounds: { x: number; y: number };
const getCanvasBounds = (): { x: number; y: number } => {
  if (canvasBounds) return canvasBounds;

  if (!rendererState || !rendererState.refs.current.canvas) {
    return { x: 0, y: 0 };
  }

  if (rendererState.refs.current.canvas) {
    canvasBounds = rendererState.refs.current.canvas.getBoundingClientRect();
  }

  return canvasBounds;
};

const multiSelection = () => {
  if (!rendererState) return;

  if (rendererState.mouseState.state === MouseActions.MakingSelection) {
    const worldCursor = convertZoomedScreenToWorldCoordinates(
      rendererState.camera,
      rendererState.screenCursorZoomed,
    );

    const { rawScreenCursorStartX, rawScreenCursorStartY } =
      rendererState.mouseState;

    const worldStart = convertZoomedScreenToWorldCoordinates(
      rendererState.camera,
      {
        x: rawScreenCursorStartX / rendererState.zoom,
        y: rawScreenCursorStartY / rendererState.zoom,
      },
    );

    // faster than Math.min/max 4 times
    let startX;
    let startY;
    let endX;
    let endY = 0;
    if (worldCursor.x < worldStart.x) {
      startX = worldCursor.x;
      endX = worldStart.x;
    } else {
      startX = worldStart.x;
      endX = worldCursor.x;
    }
    if (worldCursor.y < worldStart.y) {
      startY = worldCursor.y;
      endY = worldStart.y;
    } else {
      startY = worldStart.y;
      endY = worldCursor.y;
    }

    const nodes = rendererState.refs.current.nodes;
    const annotations = rendererState.refs.current.annotations;
    const selectedBlockUuids = [];
    const selectedLinkUuids = [];
    const selectedAnnotationUuids = [];

    // Just doing a brute-force "AABB" collision to keep it simple for now.
    // This is applied for both blocks AND links during multi-select.
    // We don't have any collision optimization data structures right now,
    // so this is the simplest way to do this currently.
    // TODO: update this block when we have grid-based collision
    // for links + selection area.

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      const nodeHeight = getVisualNodeHeight(node);
      const nodeWidth = getVisualNodeWidth(node);
      const nodeIsIOPort =
        node.type === 'core.Inport' || node.type === 'core.Outport';
      const fixedNodeY = nodeIsIOPort
        ? node.uiprops.y + PORT_BLOCK_YOFFSET
        : node.uiprops.y;
      if (
        node.uiprops.x < endX &&
        node.uiprops.x + nodeWidth > startX &&
        fixedNodeY < endY &&
        fixedNodeY + nodeHeight > startY
      ) {
        selectedBlockUuids.push(node.uuid);
      }
    }

    for (let i = 0; i < annotations.length; i++) {
      const anno = annotations[i];
      const annoHeight = anno.grid_height * renderConstants.GRID_UNIT_PXSIZE;
      const annoWidth = anno.grid_width * renderConstants.GRID_UNIT_PXSIZE;
      if (
        anno.x < endX &&
        anno.x + annoWidth > startX &&
        anno.y < endY &&
        anno.y + annoHeight > startY
      ) {
        selectedAnnotationUuids.push(anno.uuid);
      }
    }

    // see above comment about brute-force AABB
    const linksRenderFrameData = rendererState.linksRenderFrameData;
    const hitLinksMap: { [k: string]: boolean } = {};

    for (let i = 0; i < linksRenderFrameData.length; i++) {
      const linkRenderData = linksRenderFrameData[i];

      if (hitLinksMap[linkRenderData.linkUuid]) continue;

      const { vertexData } = linkRenderData;

      // final vertex's hitbox does not exist
      // so we skip it (subtract 1 from length)
      for (let j = 0; j < vertexData.length - 1; j++) {
        if (hitLinksMap[linkRenderData.linkUuid]) break;

        const hitbox = getVertexHitboxForIndex(vertexData, j);

        if (
          hitbox.x1 < endX &&
          hitbox.x2 > startX &&
          hitbox.y1 < endY &&
          hitbox.y2 > startY
        ) {
          hitLinksMap[linkRenderData.linkUuid] = true;

          selectedLinkUuids.push(linkRenderData.linkUuid);
        }
      }
    }

    const st = rendererState.refs.current;
    const selectionChanged =
      selectedBlockUuids.length !== st.selectedNodeIds.length ||
      selectedLinkUuids.length !== st.selectedLinkIds.length ||
      selectedAnnotationUuids.length !== st.selectedAnnotationIds.length ||
      selectedBlockUuids.some((uuid) => !st.selectedNodeIds.includes(uuid)) ||
      selectedLinkUuids.some((uuid) => !st.selectedLinkIds.includes(uuid)) ||
      selectedAnnotationUuids.some(
        (uuid) => !st.selectedAnnotationIds.includes(uuid),
      );

    if (selectionChanged) {
      // FIXME the modifier key should not be looked up from unreliable keysPressed
      if (
        keysPressed[clickModifierConfig.selectMultiple] ||
        keysPressed[clickModifierConfig.selectMultipleMacOS]
      ) {
        rendererState.dispatch(
          modelActions.setSelections({
            selectionParentPath: getCurrentModelRef().submodelPath,
            selectedBlockIds: selectedBlockUuids,
            selectedLinkIds: selectedLinkUuids,
            selectedAnnotationIds: selectedAnnotationUuids,
          }),
        );
        // these two cases are the same
      } else {
        rendererState.dispatch(
          modelActions.setSelections({
            selectionParentPath: getCurrentModelRef().submodelPath,
            selectedBlockIds: selectedBlockUuids,
            selectedLinkIds: selectedLinkUuids,
            selectedAnnotationIds: selectedAnnotationUuids,
          }),
        );
      }
    }
  }
};

let mousemoveRollingTimeout: number | null = null;
let mousemoveFinalTimeout: number | null = null;
const mouseMove = (event: MouseEvent): void => {
  if (!rendererState) return;

  const canvas = rendererState.refs.current.canvas;
  if (!canvas) return;

  const { x: canvasOffsetX, y: canvasOffsetY } = getCanvasBounds();
  const canvasScreenX = event.x - canvasOffsetX;
  const canvasScreenY = event.y - canvasOffsetY;

  // We care whether we're inside the canvas only if the mouse is held down.
  // If inside, all good. If outside, we could be hovering over another UI
  // element, or even outside the window. We'll only get mouse events when
  // the mouse is outside the window if the mouse is held down. In this case,
  // we care about them. If we're hovering over another UI element, we
  // should ignore move events unless the mouse is down.
  // const isStillInsideCanvas =
  //   canvasScreenX >= 0 &&
  //   canvasScreenX < canvas.offsetWidth &&
  //   canvasScreenY >= 0 &&
  //   canvasScreenY < canvas.offsetHeight;

  rendererState.cursorOverCanvas = event.target === canvas;
  rendererState.screenCursorZoomed.x = canvasScreenX / rendererState.zoom;
  rendererState.screenCursorZoomed.y = canvasScreenY / rendererState.zoom;
  rendererState.screenCursorRaw.x = canvasScreenX;
  rendererState.screenCursorRaw.y = canvasScreenY;

  if (
    rendererState.clickState.state === ClickStates.ClickHeld &&
    (rendererState.mouseState.state === MouseActions.Idle ||
      rendererState.mouseState.state === MouseActions.ReadyToDefineAnnotation)
  ) {
    const movedDistance = pythagoreanDistance(
      rendererState.clickState.rawCoord,
      rendererState.screenCursorRaw,
    );
    if (movedDistance >= MIN_DISTANCE_FOR_DRAG) {
      // FIXME: keysPressed was not updated in this event handler
      mouseInputClickHold(
        rendererState,
        rendererState.clickState.zoomedCoord,
        rendererState.clickState.rawCoord,
        keysPressed,
      );
    }
  }

  // FIXME: The problem is that selection (single and multiple) causes too many
  // React app re-renders.
  //
  // This "interlocking" timeout pattern is to allow us to throttle certain actions
  // to a lower framerate if they are performance-intensive.
  // This is because the mouse events fire faster than our render loop.
  // We can avoid smashing the CPU with a bunch of stuff
  // that would otherwise cause frame drops for everything
  // due to slowing the app down.
  if (!mousemoveRollingTimeout) {
    mousemoveRollingTimeout = window.setTimeout(
      () => {
        mousemoveRollingTimeout = null;
        multiSelection();
      },
      // throttling the FPS to 30 for selection since it's a bit expensive right now
      // TODO: make multiSelection() more efficient (but don't remove this throttle)
      FPS60_MILLIS * 2,
    );
  } else {
    if (mousemoveFinalTimeout) clearTimeout(mousemoveFinalTimeout);
    mousemoveFinalTimeout = window.setTimeout(
      () => {
        multiSelection();
      },
      // totally ensure that this never calls until the end
      // by making it over 2x as slow
      FPS60_MILLIS * 4.5,
    );
  }
};

const mouseDown = (event: MouseEvent): void => {
  // FIXME: see note about keysPressed - not reliable (funnily enough, the
  // below code is actually correct)
  // the alt key seems to be problematic in getting stuck,
  // so we'll take an aggressive approach on it for now.
  if (!event.altKey) {
    keysPressed.Alt = false;
  }

  if (!rendererState) return;

  rendererState.dispatch(rcMenuActions.close());

  const canvas = rendererState.refs.current.canvas;
  if (!canvas) return;

  const { x: canvasOffsetX, y: canvasOffsetY } = getCanvasBounds();
  const canvasScreenX = event.x - canvasOffsetX;
  const canvasScreenY = event.y - canvasOffsetY;

  if (event.target === canvas) {
    document.getSelection()?.removeAllRanges();

    const zoomedClickCoord = {
      x: canvasScreenX / rendererState.zoom,
      y: canvasScreenY / rendererState.zoom,
    };
    const rawClickCoord = {
      x: canvasScreenX,
      y: canvasScreenY,
    };

    // Start moving camera on middle button down
    if (event.button === 1) {
      mouseInputMiddleClickHold(rendererState, zoomedClickCoord);
      return;
    }

    // Context menu shows up immediately on mouseDown (not mouseUp)
    if (event.button === 2) {
      mouseInputRightClick(rendererState, zoomedClickCoord, rawClickCoord);
      event.preventDefault();
      return;
    }

    rendererState.clickState = {
      state: ClickStates.ClickHeld,
      zoomedCoord: zoomedClickCoord,
      rawCoord: rawClickCoord,
    };
  }
};

const mouseUp = (event: MouseEvent): void => {
  if (!rendererState) return;

  const canvas = rendererState.refs.current.canvas;
  if (!canvas) return;

  // If not on canvas, release all mouse actions
  if (event.target !== canvas) {
    rendererState.clickState = { state: ClickStates.Idle };
    transitionMouseState(rendererState, { state: MouseActions.Idle });
    return;
  }

  // Right click is handled only in mouseDown
  if (event.button === 2) return;

  // Middle button: click simply releases the camera move
  if (event.button === 1) {
    mouseInputMiddleClick(rendererState);
    return;
  }

  if (event.button === 0 && event.target === canvas) {
    const zoomedClickCoord: Coordinate = {
      ...rendererState.screenCursorZoomed,
    };

    rendererState.clickState = {
      state: ClickStates.Idle,
    };

    rendererState.clickState = {
      state: ClickStates.Click,
      ...zoomedClickCoord,
    };

    mouseInputClick(rendererState, zoomedClickCoord, keysPressed);
  }
};

const contextMenuEvent = (event: MouseEvent): void => {
  if (!rendererState) return;

  const canvas = rendererState.refs.current.canvas;
  if (!canvas) return;

  if (event.target === canvas) {
    event.preventDefault();
  }
};

const mouseDoubleClick = (event: MouseEvent): void => {
  if (!rendererState) return;

  const canvas = rendererState.refs.current.canvas;
  if (!canvas) return;
  if (event.target !== canvas) return;

  const { x: canvasOffsetX, y: canvasOffsetY } = getCanvasBounds();
  const canvasScreenX = event.x - canvasOffsetX;
  const canvasScreenY = event.y - canvasOffsetY;

  document.getSelection()?.removeAllRanges();

  const zoomedClickCoord = {
    x: canvasScreenX / rendererState.zoom,
    y: canvasScreenY / rendererState.zoom,
  };

  mouseInputDoubleClick(rendererState, zoomedClickCoord);
};

const wheel = (event: WheelEvent) => {
  event.preventDefault();

  if (!rendererState) return;
  if (rendererState.refs.current.rcMenuState.open) return;

  let rawDeltaX = 0;
  let rawDeltaY = 0;
  let rawZoomDelta = 0;

  // macOS will modify Shift+VScroll into deltaX (instead of deltaY),
  // but MOS breaks this behavior, so we still explicitly check for
  // modifiers even on mac.
  if (event.ctrlKey || event.metaKey) {
    rawZoomDelta = -event.deltaY;
  } else if (event.shiftKey && event.deltaY !== 0) {
    rawDeltaX = event.deltaY;
  } else {
    rawDeltaX = event.deltaX;
    rawDeltaY = event.deltaY;
  }

  if (rawZoomDelta !== 0) {
    const nextZoom = findNextZoomLevel(rendererState.zoom, rawZoomDelta);

    const previousCursorX = event.offsetX / rendererState.zoom;
    const previousCursorY = event.offsetY / rendererState.zoom;

    const newCursorX = event.offsetX / nextZoom;
    const newCursorY = event.offsetY / nextZoom;

    const cameraAdjustX = newCursorX - previousCursorX;
    const cameraAdjustY = newCursorY - previousCursorY;

    rendererState.camera.x += cameraAdjustX;
    rendererState.camera.y += cameraAdjustY;

    rendererState.screenCursorZoomed.x = newCursorX;
    rendererState.screenCursorZoomed.y = newCursorY;

    rendererState.zoom = nextZoom;
  } else {
    rendererState.camera.x -= rawDeltaX / rendererState.zoom;
    rendererState.camera.y -= rawDeltaY / rendererState.zoom;
  }

  rendererState.setTransform({
    x: rendererState.camera.x,
    y: rendererState.camera.y,
    zoom: rendererState.zoom,
  });
};

export const externallySetRendererTransform = (
  x: number,
  y: number,
  zoom: number,
): void => {
  if (!rendererState) return;

  rendererState.camera.x = x;
  rendererState.camera.y = y;
  rendererState.zoom = zoom;
};

async function loadFont(nvgContext: NVG.Context) {
  const loadArrayBuffer = async (url: string): Promise<ArrayBuffer> => {
    const response: Response = await fetch(url);
    return response.arrayBuffer();
  };

  const fontUrl = `${process.env.PUBLIC_URL}/assets/Archivo-Regular.ttf`;
  const buffer = await loadArrayBuffer(fontUrl);
  const fontArchivo = NVG.nvgCreateFontMem(
    nvgContext.ctx,
    'archivo',
    new Uint8Array(buffer),
  );

  if (fontArchivo === -1) {
    console.error('Could not load font from', fontUrl);
    return -1;
  }
  return 0;
}

let backFramebuffer: NVG.NVGLUframebuffer | null = null;
let bgDotsFramebuffer: NVG.NVGLUframebuffer | null = null;

export const startNanovg = async (canvas: HTMLCanvasElement): Promise<any> => {
  await NVG.default();

  gl = canvas.getContext('webgl', {
    stencil: true,
    preserveDrawingBuffer: true,
  });

  if (gl) {
    nvg = NVG.createWebGL(
      gl,
      NVG.CreateFlags.ANTIALIAS | NVG.CreateFlags.STENCIL_STROKES,
    );

    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);

    backFramebuffer = NVG.nvgluCreateFramebuffer(
      nvg.ctx,
      5000,
      5000,
      NVG.NVGimageFlags.PREMULTIPLIED,
    );
    bgDotsFramebuffer = NVG.nvgluCreateFramebuffer(
      nvg.ctx,
      64,
      64,
      NVG.NVGimageFlags.PREMULTIPLIED |
        NVG.ImageFlags.REPEATX |
        NVG.ImageFlags.REPEATY,
    );

    preloadedRastersPromises.forEach(({ bufferPromise, iconID }) =>
      bufferPromise.then((buffer) => {
        if (buffer && nvg) {
          allocMemImageAndIntoStoreFromImageBuffer(nvg, buffer, iconID, 4);
        }
      }),
    );
  } else {
    nvg = null;
  }

  if (!nvg) {
    console.error('Could not init nanovg.');
    return -1;
  }

  if ((await loadFont(nvg)) === -1) return -1;
};

export const endNanovg = async (): Promise<any> => {
  if (nvg) {
    deleteAllImagesFromMemAndStore(nvg);
    if (backFramebuffer) {
      NVG.nvgluDeleteFramebuffer(backFramebuffer);
      backFramebuffer = null;
    }
    if (bgDotsFramebuffer) {
      NVG.nvgluDeleteFramebuffer(bgDotsFramebuffer);
      bgDotsFramebuffer = null;
    }

    NVG.deleteWebGL(nvg);
  }

  nvg = null;
};

let tickRafId = window.requestAnimationFrame(() => {});
let startMillis = 0;
let fadeInLength = 200;

type LinkIntersectionCoordinate = Coordinate & {
  orientation: 'vertical' | 'horizontal';
};

const getTwoLinksIntersectionPoints = (
  linkRenderDataOne: LinkRenderData,
  linkRenderDataTwo: LinkRenderData,
): LinkIntersectionCoordinate[] => {
  const intersections: LinkIntersectionCoordinate[] = [];

  for (let i = 0; i < linkRenderDataOne.vertexData.length - 1; i++) {
    const segmentOneStart = linkRenderDataOne.vertexData[i];
    const segmentOneEnd = linkRenderDataOne.vertexData[i + 1];

    const orientation =
      segmentOneStart.coordinate[0] === segmentOneEnd.coordinate[0]
        ? 'vertical'
        : 'horizontal';

    for (let j = 0; j < linkRenderDataTwo.vertexData.length - 1; j++) {
      const segmentTwoStart = linkRenderDataTwo.vertexData[j];
      const segmentTwoEnd = linkRenderDataTwo.vertexData[j + 1];

      const intersectionPoint = lineIntersect90Deg(
        segmentOneStart.coordinate[0],
        segmentOneStart.coordinate[1],
        segmentOneEnd.coordinate[0],
        segmentOneEnd.coordinate[1],
        segmentTwoStart.coordinate[0],
        segmentTwoStart.coordinate[1],
        segmentTwoEnd.coordinate[0],
        segmentTwoEnd.coordinate[1],
      );

      if (intersectionPoint !== null)
        intersections.push({ ...intersectionPoint, orientation });
    }
  }

  return intersections;
};

const setLinksOcclusionPoints = (rs: RendererState) => {
  rs.linksOcclusionPointLUT = {};
  const renderFrameData: LinkRenderData[] = rs.linksRenderFrameData;

  for (let i = 0; i < renderFrameData.length - 1; i++) {
    const renderDataOne = renderFrameData[i];

    for (let j = i + 1; j < renderFrameData.length; j++) {
      const renderDataTwo = renderFrameData[j];

      // TODO: add aabb check on renderdata's bounding box

      const occlusionPoints = getTwoLinksIntersectionPoints(
        renderDataOne,
        renderDataTwo,
      );
      if (occlusionPoints.length > 0) {
        if (!rs.linksOcclusionPointLUT[renderDataOne.linkUuid]) {
          rs.linksOcclusionPointLUT[renderDataOne.linkUuid] = occlusionPoints;
        } else {
          for (let k = 0; k < occlusionPoints.length; k++) {
            rs.linksOcclusionPointLUT[renderDataOne.linkUuid].push(
              occlusionPoints[k],
            );
          }
        }
      }
    }
  }
};

export const regenLinkRenderFrameData = (
  rendererState: RendererState,
  overrideNodes?: NodeInstance[],
  overrideLinks?: LinkInstance[],
) => {
  // TODO: This is temporary while we wait to prioritize developing a
  // more robust method for pre-calculating the link render data
  // that doesn't happen every frame.
  rendererState.linksRenderFrameData = [];
  rendererState.linksRenderFrameDataIndexLUT = {};

  const usingLinksList = overrideLinks || rendererState.refs.current.links;
  const usingNodesList = overrideNodes || rendererState.refs.current.nodes;

  const baseLevelLinks =
    rendererState.refs.current.linksRenderingDependencyTree.__no_dependency ||
    [];

  // this is performance-wise the fastest way to get a copy of this
  const iteratingLinkUUIDs: string[] = [];
  for (let i = 0; i < baseLevelLinks.length; i++) {
    iteratingLinkUUIDs.push(baseLevelLinks[i]);
  }

  let linkRenderIndex = 0;
  while (iteratingLinkUUIDs.length) {
    const linkUUID = iteratingLinkUUIDs.pop() || '';
    const realLinkIndex = rendererState.refs.current.linksIndexLUT[linkUUID];
    const link = usingLinksList[realLinkIndex];

    if (!link) continue;

    const dependentLinkUUIDs =
      rendererState.refs.current.linksRenderingDependencyTree[linkUUID];

    if (dependentLinkUUIDs && dependentLinkUUIDs.length > 0) {
      for (let i = 0; i < dependentLinkUUIDs.length; i++) {
        iteratingLinkUUIDs.push(dependentLinkUUIDs[i]);
      }
    }

    rendererState.linksRenderFrameData.push(
      linkToRenderData(
        rendererState,
        link,
        usingNodesList,
        rendererState.refs.current.nodesIndexLUT,
        rendererState.camera.x,
        rendererState.camera.y,
      ),
    );

    rendererState.linksRenderFrameDataIndexLUT[link.uuid] = linkRenderIndex;
    linkRenderIndex++;
  }
};

let earlyCheckFailedCount = 0;
let lateCheckFailedCount = 0;

const _tick = (time: number): void => {
  if (!rendererState || !nvg) {
    earlyCheckFailedCount++;
    if (earlyCheckFailedCount < 120) {
      tickRafId = window.requestAnimationFrame(_tick);
    }
    return;
  }

  earlyCheckFailedCount = 0;

  let renderWidth = 0;
  let renderHeight = 0;
  let framebufWidth = 0;
  let framebufHeight = 0;

  const parent = rendererState.refs.current.parent;
  const canvas = rendererState.refs.current.canvas;

  if (parent) {
    renderWidth = parent.clientWidth;
    renderHeight = parent.clientHeight;

    if (canvas) {
      // NOTE: there is probably something rogue setting canvas width/height and causing it to "disappear",
      // so putting this here for now (and will consider removal
      // if we can diagnose the "disappearing" problem's source).
      // this won't affect performance as the value isn't actually changing every frame.
      canvas.width = parent.clientWidth * window.devicePixelRatio;
      canvas.height = parent.clientHeight * window.devicePixelRatio;
    }
  }

  if (gl) {
    framebufWidth = gl.drawingBufferWidth;
    framebufHeight = gl.drawingBufferHeight;

    gl.viewport(0, 0, framebufWidth, framebufHeight);

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    gl.enable(gl.CULL_FACE);
    gl.disable(gl.DEPTH_TEST);
  }

  mouseInput(rendererState, rendererState.dispatch, rendererState.setTransform);

  if (canvas) {
    if (time - startMillis < fadeInLength) {
      canvas.style.opacity = `${(time - startMillis) / fadeInLength}`;
    } else {
      canvas.style.opacity = '1';
    }
  }

  const cursorVisualStyle = getCursorVisualState(rendererState);
  if (canvas && canvas.style.cursor !== cursorVisualStyle) {
    canvas.style.cursor = cursorVisualStyle;
  }

  // FIXME: the below two function calls become prohibitively expensive when
  // there are more and more links in the diagram.
  regenLinkRenderFrameData(rendererState);
  setLinksOcclusionPoints(rendererState);

  if (gl) {
    if (bgDotsFramebuffer !== null) {
      const fboWidth: [number] = [0];
      const fboHeight: [number] = [0];
      nvg.imageSize(bgDotsFramebuffer.image, fboWidth, fboHeight);

      NVG.nvgluBindFramebuffer(bgDotsFramebuffer);
      gl.viewport(0, 0, fboWidth[0], fboHeight[0]);
      gl.clearColor(0, 0, 0, 0);
      gl.clear(
        gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT | gl.STENCIL_BUFFER_BIT,
      );

      nvg.beginFrame(fboWidth[0], fboHeight[0], window.devicePixelRatio);
      nvg.beginPath();
      nvg.circle(
        fboWidth[0] / 2,
        fboHeight[0] / 2,
        fboWidth[0] / renderConstants.GRID_UNIT_PXSIZE,
      );
      nvg.fillColor(nvg.RGBA(200, 200, 200, 255));
      nvg.fill();
      nvg.endFrame();
      NVG.nvgluBindFramebuffer(null);
    }

    if (backFramebuffer !== null) {
      const fboWidth: [number] = [0];
      const fboHeight: [number] = [0];
      nvg.imageSize(backFramebuffer.image, fboWidth, fboHeight);

      NVG.nvgluBindFramebuffer(backFramebuffer);
      gl.viewport(0, 0, fboWidth[0], fboHeight[0]);
      gl.clearColor(0, 0, 0, 0);
      gl.clear(
        gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT | gl.STENCIL_BUFFER_BIT,
      );

      nvg.beginFrame(framebufWidth, framebufHeight, window.devicePixelRatio);
      if (
        rendererState.mouseState.state === MouseActions.DragDropLibraryBlock
      ) {
        const blockData = blockTypeNameToInstanceDefaults(
          rendererState.mouseState.blockClassName,
          undefined,
          rendererState.mouseState.referenceSubmodel?.id,
        );

        const referenceSubmodel = rendererState.mouseState
          .referenceSubmodel as SubmodelInfoUI;
        if (referenceSubmodel && referenceSubmodel.portDefinitionsInputs) {
          updateSubmodelInstanceForReferenceChanges(
            blockData as SubmodelInstance,
            referenceSubmodel,
          );
        }

        drawNode(
          nvg,
          rendererState,
          blockData,
          [],
          false,
          rendererState.screenCursorZoomed.x -
            rendererState.mouseState.cursorOffset.x,
          rendererState.screenCursorZoomed.y -
            rendererState.mouseState.cursorOffset.y,
        );
      }

      nvg.endFrame();
      NVG.nvgluBindFramebuffer(null);
    }

    gl.viewport(0, 0, framebufWidth, framebufHeight);
    gl.enable(gl.BLEND);

    if (rendererState.refs.current.currentSubdiagramType === 'core.Iterator') {
      gl.clearColor(248 / 255, 245 / 255, 227 / 255, 1);
    } else if (
      rendererState.refs.current.currentSubdiagramType ===
      'core.LinearizedSystem' // TODO: proper color. also this approach doesnt scale
    ) {
      gl.clearColor(0, 245 / 255, 227 / 255, 1);
    } else {
      gl.clearColor(0xf1 / 255, 0xf3 / 255, 0xf3 / 255, 1.0);
    }

    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT | gl.STENCIL_BUFFER_BIT);

    // on local, unmounting gets tripped up sometimes and very frequently chokes right here (non-catastrophically, though annoyingly).
    // this seems to fix it
    if (!nvg) {
      lateCheckFailedCount++;

      if (lateCheckFailedCount < 120) {
        tickRafId = window.requestAnimationFrame(_tick);
      }

      return;
    }

    lateCheckFailedCount = 0;

    nvg.beginFrame(renderWidth, renderHeight, window.devicePixelRatio);

    const bgOpacity = Math.max(0, Math.min(1, rendererState.zoom - 0.5)) / 2;
    if (bgDotsFramebuffer !== null && bgOpacity > 0) {
      nvg.fillPaint(
        nvg.imagePattern(
          (renderConstants.GRID_UNIT_PXSIZE / 2 + rendererState.camera.x) *
            rendererState.zoom,
          (renderConstants.GRID_UNIT_PXSIZE / 2 + rendererState.camera.y) *
            rendererState.zoom,
          renderConstants.GRID_UNIT_PXSIZE * rendererState.zoom,
          renderConstants.GRID_UNIT_PXSIZE * rendererState.zoom,
          0,
          bgDotsFramebuffer.image,
          bgOpacity,
        ),
      );
      nvg.fillRect(0, 0, renderWidth, renderHeight);
    }

    drawScene(
      nvg,
      rendererState,
      rendererState.refs.current.connectedPortLUT,
      rendererState.refs.current.selectedNodeIds,
      framebufWidth,
      framebufHeight,
    );

    if (backFramebuffer !== null) {
      nvg.fillPaint(
        nvg.imagePattern(
          0,
          0,
          framebufWidth,
          framebufHeight,
          0,
          backFramebuffer.image,
          0.6,
        ),
      );
      nvg.fillRect(0, 0, renderWidth, renderHeight);
    }

    const { multiplayerMouses } = rendererState.refs.current;
    const mouseUsers = Object.keys(multiplayerMouses);
    for (let i = 0; i < mouseUsers.length; i++) {
      const userName = mouseUsers[i];
      const userMouse = multiplayerMouses[userName];

      const rx =
        (userMouse.x - 3 + rendererState.camera.x) * rendererState.zoom;
      const ry =
        (userMouse.y - 3 + rendererState.camera.y) * rendererState.zoom;

      const scale = 2; // it's a 2x dpi png which never needs to scale up or down
      const rasterMeta = getOrInitLoadImageFromStore(
        nvg,
        `${process.env.PUBLIC_URL}/assets/multiplayer_mouse.png`,
        'multiplayer_mouse',
        scale,
      );
      if (rasterMeta?.loadState === RasterLoadState.Loaded) {
        const rw = rasterMeta.width / scale;
        const rh = rasterMeta.height / scale;
        const imgPaint = nvg.imagePattern(
          rx,
          ry,
          rw,
          rh,
          0,
          rasterMeta.imageId,
          1,
        );
        nvg.beginPath();
        nvg.rect(rx, ry, rw, rh);
        nvg.fillPaint(imgPaint);
        nvg.fill();
      }

      const tx = rx + 10;
      const ty = ry + 15;

      const fontSize = 14;
      const { width: nameWidth, height: nameHeight } = calculateTextSize(
        userName,
        {
          font: 'Archivo',
          fontSize: `${fontSize}px`,
        },
      );

      nvg.beginPath();
      nvg.roundedRect(tx, ty, nameWidth, nameHeight, 3);
      nvg.fillColor(nvg.RGB(0, 0, 0));
      nvg.fill();

      nvg.fontSize(fontSize);
      nvg.fontFace('archivo');
      nvg.textAlign(NVG.Align.LEFT | NVG.Align.TOP);
      nvg.fillColor(nvg.RGB(255, 255, 255));
      nvg.text(tx + 4, ty + 1, userName, null);
    }

    nvg.endFrame();

    gl.enable(gl.DEPTH_TEST);
  }

  // Clicks should only persist for 1 frame
  if (
    rendererState.clickState.state === ClickStates.Click ||
    rendererState.clickState.state === ClickStates.DoubleClick
  ) {
    rendererState.clickState = { state: ClickStates.Idle };
  }

  if (typeof window !== 'undefined') {
    if (done) {
      tickRafId = window.requestAnimationFrame(endNanovg);
    } else {
      tickRafId = window.requestAnimationFrame(_tick);
    }
  }
};

const triggerShortcut = (configItem: SingleShortcutConfig) => {
  if (rendererState !== null) {
    const uiFlags = rendererState.refs.current.uiFlags;
    const shouldIgnore =
      configItem.ignoreIfTextFocused &&
      (uiFlags.textInputFocused || uiFlags.htmlTextSelected);
    if (!shouldIgnore) configItem.handler(rendererState);
  }
};

const specialKeyCallbacks: ((e: KeyboardEvent) => void)[] = [];
const specialKeyMainCallback = (e: KeyboardEvent) => {
  for (let j = 0; j < specialKeyCallbacks.length; j++) {
    specialKeyCallbacks[j](e);
  }
};

// necessary because for some of our alt keys we get the wrong "key"
// which is the recommended layout-independent (!) value to use
const macosAltKeyMap: { [k: string]: string } = {
  '∂': 'd',
  '¬': 'l',
  '®': 'r',
};

const registerHotkeyEvents = () => {
  for (let i = 0; i < shortcutsConfig.length; i++) {
    const configItem = shortcutsConfig[i];

    if (configItem.variant === 'special') {
      /* eslint-disable */
      specialKeyCallbacks.push((e: KeyboardEvent) => {
        if (rendererState == null) return;

        const uiFlags = rendererState.refs.current.uiFlags;
        const shouldIgnore =
          configItem.ignoreIfTextFocused &&
          (uiFlags.textInputFocused || uiFlags.htmlTextSelected);

        if (shouldIgnore) return;

        const modKeys = configItem.specialConfig.modKeys;
        let modKeysPressed = true;
        for (let j = 0; j < modKeys.length; j++) {
          switch (modKeys[j]) {
            case 'CtrlCmd':
              modKeysPressed = e.ctrlKey || e.metaKey;
              break;
            case 'AltOption':
              modKeysPressed = e.altKey;
              break;
            case 'Shift':
              modKeysPressed = e.shiftKey;
              break;
          }
        }

        const configKey = configItem.specialConfig.key;
        const pressingMainKey =
          configKey == e.key || configKey == macosAltKeyMap[e.key];

        if (modKeysPressed && pressingMainKey) {
          if (configItem.preventDefault) {
            e.preventDefault();
          }
          triggerShortcut(configItem);
        }
      });
      /* eslint-enable */
    } else {
      /* eslint-disable */
      hotkeys(configItem.hotkeyString, (e) => {
        if (rendererState == null) return;

        const uiFlags = rendererState.refs.current.uiFlags;
        const shouldIgnore =
          configItem.ignoreIfTextFocused &&
          (uiFlags.textInputFocused || uiFlags.htmlTextSelected);

        if (shouldIgnore) return;

        if (configItem.preventDefault) {
          e.preventDefault();
        }
        triggerShortcut(configItem);
      });
      /* eslint-enable */
    }
  }

  document.addEventListener('keydown', specialKeyMainCallback);
};

function onTextSelectionChange() {
  if (rendererState === null) return;

  const dispatch = rendererState.dispatch;
  const { anchorOffset, focusOffset } = document.getSelection() || {
    anchorOffset: 0,
    focusOffset: 0,
  };
  const minPos = Math.min(anchorOffset, focusOffset);
  const maxPos = Math.max(anchorOffset, focusOffset);
  if (maxPos - minPos > 0) {
    dispatch(uiFlagsActions.setUIFlag({ htmlTextSelected: true }));
  } else {
    dispatch(uiFlagsActions.setUIFlag({ htmlTextSelected: false }));
  }
}

// necessary for re-mounting
export function unregisterRendererEvents(): void {
  if (!rendererState) return;

  const { canvas } = rendererState.refs.current;
  if (typeof window !== 'undefined') {
    window.removeEventListener('keydown', keyDown);
    window.removeEventListener('keyup', keyUp);
    window.removeEventListener('pointermove', mouseMove);
    window.removeEventListener('mousedown', mouseDown);
    window.removeEventListener('contextmenu', contextMenuEvent);
    window.removeEventListener('mouseup', mouseUp);

    if (canvas) {
      canvas.removeEventListener('wheel', wheel);
      canvas.removeEventListener('dblclick', mouseDoubleClick);
    }

    hotkeys.unbind();
    document.removeEventListener('keydown', specialKeyMainCallback);
    document.removeEventListener('selectionchange', onTextSelectionChange);
  }
}

export async function initModelRenderer(
  refs: MutableRefObject<RendererRefsType>,
  setTransform: TransformFunc,
  dispatch: AppDispatch,
): Promise<void> {
  await startNanovg(refs.current.canvas as HTMLCanvasElement);

  rendererState = {
    refs,
    setTransform,
    dispatch,
    linksRenderFrameData: rendererState?.linksRenderFrameData || [],
    linksRenderFrameDataIndexLUT: {},
    linksOcclusionPointLUT: {},
    camera: rendererState?.camera || { x: 0, y: 0 },
    cursorOverCanvas: false,
    screenCursorRaw: rendererState?.screenCursorRaw || { x: 0, y: 0 },
    screenCursorZoomed: rendererState?.screenCursorZoomed || { x: 0, y: 0 },
    clickState: rendererState?.clickState || { state: ClickStates.Idle },
    mouseState: rendererState?.mouseState || { state: MouseActions.Idle },
    zoom: rendererState?.zoom || 1,
    hoveringEntity: undefined,
  };

  registerHotkeyEvents();

  document.addEventListener('selectionchange', onTextSelectionChange);

  const canvas = refs.current.canvas;
  const parent = refs.current.parent;
  if (typeof window !== 'undefined' && canvas && parent) {
    window.addEventListener('keydown', keyDown);
    window.addEventListener('keyup', keyUp);
    window.addEventListener('pointermove', mouseMove);
    window.addEventListener('mousedown', mouseDown);
    window.addEventListener('contextmenu', contextMenuEvent);
    window.addEventListener('mouseup', mouseUp);
    canvas.addEventListener('dblclick', mouseDoubleClick);
    canvas.addEventListener('wheel', wheel);
    canvas.tabIndex = 1;
    canvas.style.left = '0';
    canvas.style.right = '0';
    canvas.style.top = '0';
    canvas.style.bottom = '0';
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    canvas.style.position = 'absolute';
    canvas.width = parent.clientWidth * window.devicePixelRatio;
    canvas.height = parent.clientHeight * window.devicePixelRatio;

    window.removeEventListener('resize', windowResizer);

    windowResizer = (_event: UIEvent): void => {
      canvas.width = parent.clientWidth * window.devicePixelRatio;
      canvas.height = parent.clientHeight * window.devicePixelRatio;
    };

    window.addEventListener('resize', windowResizer);

    // see useModelEditorPreferences.ts for an explanation on why we're using
    // setTimeout() here.
    // not the perfect solution, but it works. -jackson
    window.setTimeout(() => {
      dispatch(userPreferencesActions.setLoadModelEditor());
      dispatch(userPreferencesActions.setLoadVisualizer());
    }, 50);

    dispatch(uiFlagsActions.setUIFlag({ rendererStateInitialized: true }));
  }

  if (typeof window !== 'undefined') {
    window.cancelAnimationFrame(tickRafId);
    tickRafId = window.requestAnimationFrame((t: number) => {
      startMillis = t;
      _tick(t);
    });
  }
}
