import {
  getWriteableDeepCopy,
  SubmodelInstance,
  transformBackendConfiguration,
} from '@collimator/model-schemas-ts';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModelLevelParameters } from 'app/apiData';
import {
  ModelConfiguration,
  ParameterDefinition,
  SubmodelConfiguration,
  SubmodelPortDefinition,
} from 'app/apiGenerated/generatedApiTypes';
import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import { Coordinate } from 'app/common_types/Coordinate';
import { FakeSegmentType } from 'app/common_types/SegmentTypes';
import {
  defaultModelConfiguration,
  FakeTappedSegmentType,
  LinkSegmentType,
  ModelDiagram,
  ReferenceSubmodels,
  StateMachineDiagram,
  SubmodelsSection,
} from 'app/generated_types/SimulationModel';
import { getSubmodelPortIdOfIoNode } from 'app/helpers';
import {
  getCurrentlyEditingModelFromState,
  getCurrentParentSubmodelNodeFromState,
  ModelState,
} from 'app/modelState/ModelState';
import { getUniqueIdentifier } from 'app/transformers/uniqueNameGenerators';
import {
  addPremadeEntitiesToModel,
  confirmReferenceSubmodelCreatedFromSelection,
  createSubdiagramFromSelection,
} from 'app/utils/insertNodeUtils';
import { connectTwoLinks_mut } from 'app/utils/linkMutationUtils';
import { LinkVertex } from 'app/utils/linkToRenderData';
import {
  addNewLinkToModel,
  addSegmentsToLink,
  adjustTapLinkSegments,
  connectLinkToNode,
  insertNodeIntoLink,
  moveFakeSegmentTapsToRealSegment,
  setLinkTap,
  simplifyBlocksLinkSegments,
  simplifyLinkSegments,
} from 'app/utils/linkUtils';
import { getNestedNode } from 'app/utils/modelDiagramUtils';
import { updateModelForSubmodelReferenceChanges } from 'app/utils/modelSubmodelFixupUtils';
import {
  addParameterDefinition,
  removeParameterDefinition,
  updateParameterDefinitionDefaultValue,
  updateParameterDefinitionDescription,
  updateParameterDefinitionName,
} from 'app/utils/parameterDefinitionUtils';
import {
  addNodeExtraParameter,
  changeBlockCommonParameter,
  changeBlockParameter,
  deleteBlockParameter,
  removeNodeExtraParameter,
  renameNodeExtraParameter,
  updateNodeExtraParameter,
} from 'app/utils/parameterUtils';
import {
  addPort,
  connectNodesPorts,
  disconnectLinkFromSourceOrDest,
  removeAllInputPorts,
  removeAllPorts,
  removePort,
  renamePort,
  setOutputPortRecord,
  setPortParameters,
} from 'app/utils/portUtils';
import {
  changeSegmentCoordinate,
  moveAnnotationsTo,
  moveBlocksAndLinksByDelta,
  moveBlocksTo,
  snapEntitiesToGrid,
} from 'app/utils/positionUtils';
import {
  disconnectNodeFromAllLinks,
  removeEntitiesFromModel,
} from 'app/utils/removeBlockUtils';
import { wrapPayloadOnlyFnAsReducer } from 'app/utils/rtk';
import {
  NavigationAndSelectionRequest,
  selectAdditionalAnnotations,
  selectAdditionalLinks,
  selectAdditionalNodes,
  SelectionRequest,
  setSelections,
  unselectAll,
  unselectAnnotation,
  unselectLink,
  unselectNode,
} from 'app/utils/selectionUtils';
import {
  addStateLink,
  addStateNode,
  changeStateNodeName,
  createNewStateMachineWithUuid,
  deleteStateMachineEntities,
  moveStateLinkCurveDeviationByDelta,
  moveStateNodesByDelta,
  repositionLink,
  setStateLinkAction,
  setStateLinkGuard,
  setStateMachineEntryPointAction,
  setStateMachineEntryPointConnection,
  snapStateNodesToGrid,
} from 'app/utils/stateMachineUtils';
import {
  setDiscreteStep,
  setInitScriptName,
  setIsAtomic,
  unsetDiscreteStep,
} from 'app/utils/submodelConfiguration';
import {
  configureDynamicBlock,
  resetDynamicBlock,
} from 'util/dynamicBlockUtils';
import { v4 as makeUuid } from 'uuid';

import { PortSide } from 'app/common_types/PortTypes';
import {
  consumeAutoLayout,
  eraseModelLayout,
  postAutoLayout,
} from 'app/utils/autolayoutUtils';
import { renderConstants } from 'app/utils/renderConstants';

export const initialState: ModelState = {
  name: '',
  rootModel: {
    nodes: [],
    links: [],
    uuid: '',
  },
  submodels: { references: {}, diagrams: {} },
  selectionParentPath: [],
  selectedBlockIds: [],
  selectedLinkIds: [],
  selectedAnnotationIds: [],
  currentSubmodelPath: [],
  configuration: defaultModelConfiguration,
  parameters: [],
  parameterDefinitions: [],
  portDefinitionsInputs: [],
  portDefinitionsOutputs: [],
  submodelConfiguration: {},
  preventSendingUpdateData: false,
  blockPositionCache: {},
};

const modelSlice = createSlice({
  name: 'modelSlice',
  initialState,
  reducers: {
    resetModelState: () => initialState,

    updateOpenModelName(state, action: PayloadAction<string>) {
      if (state.name !== action.payload) {
        state.name = action.payload;
      }
    },

    // FIXME(anthony): Ideally the payload type should be generated
    // from the openapi.yaml definition
    loadModelContent(
      state,
      action: PayloadAction<{
        diagram: ModelDiagram;
        submodels?: SubmodelsSection;
        parameters?: ModelLevelParameters;
        configuration?: ModelConfiguration;
        reference_submodels?: ReferenceSubmodels;
        state_machines?: {
          [k: string]: StateMachineDiagram | undefined;
        };
        name?: string;
        kind?: 'Model';
        preventSendingUpdateData?: boolean;
      }>,
    ) {
      // prevents multiplayer and "initial data loading" loops
      // "import model from clipboard" and chat build_model should send an update.
      state.preventSendingUpdateData =
        action.payload.preventSendingUpdateData ?? true;

      const rootModel = action.payload.diagram;
      const submodels = action.payload.submodels || {
        diagrams: {},
        references: {},
      };
      const stateMachines = action.payload.state_machines || {};
      const parameters = action.payload.parameters;
      const configuration = action.payload.configuration;

      // FIXME: why copy but not migrate?
      const mutableRootModel = getWriteableDeepCopy(rootModel);
      const mutableSubmodels = getWriteableDeepCopy(submodels);
      const mutableStateMachines = getWriteableDeepCopy(stateMachines);

      state.rootModel = mutableRootModel;
      state.submodels = mutableSubmodels;
      state.stateMachines = mutableStateMachines;

      if (parameters) {
        state.parameters = Object.keys(parameters).map((name, _idx, _ar) => {
          const value = parameters[name]?.value;
          return {
            name,
            value: `${value === undefined ? '' : value}`,
          };
        });
      } else if (!state.parameters) {
        state.parameters = [];
      }

      if (configuration) {
        state.configuration = transformBackendConfiguration(configuration);
      } else if (!state.configuration) {
        state.configuration = defaultModelConfiguration;
      }

      if (action.payload.name) {
        state.name = action.payload.name;
      }
    },

    loadGroupContent(
      state,
      action: PayloadAction<{
        diagram: ModelDiagram;
        groupBlockUuid: string;
      }>,
    ) {
      if (!action.payload.diagram.uuid) {
        throw new Error('diagram.uuid is required');
      }
      state.submodels.references[action.payload.groupBlockUuid] = {
        diagram_uuid: action.payload.diagram.uuid,
      };
      state.submodels.diagrams[action.payload.diagram.uuid] =
        action.payload.diagram;
    },

    loadSubmodelContent(
      state,
      action: PayloadAction<{
        diagram: ModelDiagram;
        submodels?: SubmodelsSection;
        state_machines?: {
          [k: string]: StateMachineDiagram | undefined;
        };
        parameterDefinitions: ParameterDefinition[];
        portDefinitionsInputs?: SubmodelPortDefinition[];
        portDefinitionsOutputs?: SubmodelPortDefinition[];
        submodelConfiguration?: SubmodelConfiguration;
        name: string;
        preventSendingUpdateData?: boolean;
      }>,
    ) {
      // prevents multiplayer and "initial data loading" loops
      // "import model from clipboard" and chat build_model should send an update.
      state.preventSendingUpdateData =
        action.payload.preventSendingUpdateData ?? true;

      const rootModel = action.payload.diagram;
      const submodels = action.payload.submodels || {
        diagrams: {},
        references: {},
      };
      const stateMachines = action.payload.state_machines || {};

      const mutableRootModel = getWriteableDeepCopy(rootModel);
      const mutableSubmodels = getWriteableDeepCopy(submodels);
      const mutableStateMachines = getWriteableDeepCopy(stateMachines);

      state.rootModel = mutableRootModel;
      state.submodels = mutableSubmodels;
      state.stateMachines = mutableStateMachines;

      state.parameterDefinitions = action.payload.parameterDefinitions;
      if (action.payload.portDefinitionsInputs) {
        state.portDefinitionsInputs = action.payload.portDefinitionsInputs;
      }
      if (action.payload.portDefinitionsOutputs) {
        state.portDefinitionsOutputs = action.payload.portDefinitionsOutputs;
      }
      if (action.payload.submodelConfiguration) {
        state.submodelConfiguration = action.payload.submodelConfiguration;
      }
      state.name = action.payload.name;
    },

    updateReferencedSubmodelInstances(
      state,
      action: PayloadAction<{
        idToVersionIdToSubmodelInfo: Record<
          string,
          Record<string, SubmodelInfoUI>
        >;
      }>,
    ) {
      if (state.rootModel) {
        const { idToVersionIdToSubmodelInfo } = action.payload;

        updateModelForSubmodelReferenceChanges(
          state.rootModel,
          state.submodels,
          idToVersionIdToSubmodelInfo,
        );
      }
    },

    addParameterDefinition,
    updateParameterDefinitionName,
    updateParameterDefinitionDefaultValue,
    removeParameterDefinition,
    updateParameterDefinitionDescription,

    addPremadeEntitiesToModel,

    removeEntitiesFromModel(
      state,
      action: PayloadAction<{
        blockUuids?: string[];
        linkUuids?: string[];
        annotationUuids?: string[];
        autoRemoveBlocksLinks?: boolean;
      }>,
    ) {
      const {
        blockUuids: rawBlockUuids,
        linkUuids: rawLinkUuids,
        annotationUuids: rawAnnotationUuids,
        autoRemoveBlocksLinks,
      } = action.payload;
      removeEntitiesFromModel(
        state,
        rawBlockUuids,
        rawLinkUuids,
        rawAnnotationUuids,
        autoRemoveBlocksLinks,
      );
    },

    disconnectNodeFromAllLinks(
      state,
      action: PayloadAction<{
        nodeUuid: string;
        connectSingleIO: boolean;
      }>,
    ) {
      const { nodeUuid, connectSingleIO } = action.payload;
      disconnectNodeFromAllLinks(state, nodeUuid, connectSingleIO);
    },

    addNewLinkToModel: wrapPayloadOnlyFnAsReducer(addNewLinkToModel),

    setLinkTap(
      state,
      action: PayloadAction<{
        linkUuid: string;
        tapped_link_uuid: string;
        tapped_segment:
          | {
              segment_type: 'real';
              tapped_segment_index: number;
              tapped_segment_direction: 'horiz' | 'vert';
            }
          | {
              segment_type: FakeTappedSegmentType;
              tapped_segment_direction: 'horiz' | 'vert';
            };
        tap_coordinate: number;
      }>,
    ) {
      const { linkUuid, tapped_link_uuid, tapped_segment, tap_coordinate } =
        action.payload;
      setLinkTap(
        state,
        linkUuid,
        tapped_link_uuid,
        tapped_segment,
        tap_coordinate,
      );
    },

    addSegmentsToLink(
      state,
      action: PayloadAction<{
        linkUuid: string;
        segmentsData: LinkSegmentType[];
        prepend: boolean;
      }>,
    ) {
      const { linkUuid, segmentsData, prepend } = action.payload;
      addSegmentsToLink(state, linkUuid, segmentsData, prepend);
    },

    simplifyLinkSegments(
      state,
      action: PayloadAction<{
        linkUuid: string;
      }>,
    ) {
      const { linkUuid } = action.payload;
      simplifyLinkSegments(state, linkUuid);
    },

    simplifyAllLinkSegments(state) {
      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      for (let i = 0; i < model.links.length; i++) {
        simplifyLinkSegments(state, model.links[i].uuid);
      }
    },

    simplifyBlocksLinkSegments(
      state,
      action: PayloadAction<{
        blockUuids: string[];
      }>,
    ) {
      const { blockUuids } = action.payload;
      simplifyBlocksLinkSegments(state, blockUuids);
    },

    changeSegmentCoordinate(
      state,
      action: PayloadAction<{
        linkUuid: string;
        segmentIndex: number;
        newCoordinate: number;
      }>,
    ) {
      const { linkUuid, segmentIndex, newCoordinate } = action.payload;
      changeSegmentCoordinate(state, linkUuid, segmentIndex, newCoordinate);
    },

    adjustTapLinkSegments(
      state,
      action: PayloadAction<{
        tappedLinkUuid: string;
        adjustAmount: number;
      }>,
    ) {
      const { tappedLinkUuid, adjustAmount } = action.payload;
      adjustTapLinkSegments(state, tappedLinkUuid, adjustAmount);
    },

    moveFakeSegmentTapsToRealSegment(
      state,
      action: PayloadAction<{
        tappedLinkUuid: string;
        fakeSegmentType: FakeSegmentType;
        realSegmentIndex: number;
      }>,
    ) {
      const { tappedLinkUuid, realSegmentIndex, fakeSegmentType } =
        action.payload;
      moveFakeSegmentTapsToRealSegment(
        state,
        tappedLinkUuid,
        realSegmentIndex,
        fakeSegmentType,
      );
    },

    connectTwoLinks(
      state,
      action: PayloadAction<{
        inputIconSideLinkUuid: string;
        outputIconSideLinkUuid: string;
        atCoordinate: Coordinate;
      }>,
    ) {
      const { inputIconSideLinkUuid, outputIconSideLinkUuid } = action.payload;

      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      connectTwoLinks_mut(model, inputIconSideLinkUuid, outputIconSideLinkUuid);
    },

    connectLinkToNode: wrapPayloadOnlyFnAsReducer(connectLinkToNode),

    changeBlockName(
      state,
      action: PayloadAction<{
        blockUuid: string;
        newName: string;
        parentPath: string[];
      }>,
    ) {
      const { blockUuid, newName, parentPath } = action.payload;

      const model = getCurrentlyEditingModelFromState(state, parentPath);
      if (!model) return;

      const node = model.nodes.find((n) => n.uuid === blockUuid);
      if (!node) return;

      node.name = newName;

      // adjust io port name if this is a submodel io port
      if (node.type === 'core.Inport' || node.type === 'core.Outport') {
        const parentSubmodelNode = getCurrentParentSubmodelNodeFromState(state);
        const portId = getSubmodelPortIdOfIoNode(node);
        if (parentSubmodelNode && !isNaN(portId)) {
          if (
            node.type === 'core.Inport' &&
            parentSubmodelNode.inputs.length > portId
          ) {
            parentSubmodelNode.inputs[portId].name = node.name;
          } else if (
            node.type === 'core.Outport' &&
            parentSubmodelNode.outputs.length > portId
          ) {
            parentSubmodelNode.outputs[portId].name = node.name;
          }
        }
      }
    },

    changeLinkName(
      state,
      action: PayloadAction<{
        linkUuid: string;
        newName: string;
      }>,
    ) {
      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const { linkUuid, newName } = action.payload;

      const link = model.links.find((l) => l.uuid === linkUuid);
      if (!link) return;

      link.name = newName;
    },

    moveBlocksTo(
      state,
      action: PayloadAction<{
        blockUuids: string[];
        x: number;
        y: number;
      }>,
    ) {
      const { blockUuids, x, y } = action.payload;
      moveBlocksTo(state, blockUuids, x, y);
    },

    moveAnnotationsTo(
      state,
      action: PayloadAction<{
        uuids: string[];
        x: number;
        y: number;
      }>,
    ) {
      const { uuids, x, y } = action.payload;
      moveAnnotationsTo(state, uuids, x, y);
    },

    moveEntitiesByDelta(
      state,
      action: PayloadAction<{
        deltaX: number;
        deltaY: number;
        blockUuids?: string[];
        linkUuids?: string[];
        annotationUuids?: string[];
      }>,
    ) {
      const { deltaX, deltaY, blockUuids, linkUuids, annotationUuids } =
        action.payload;
      moveBlocksAndLinksByDelta(
        state,
        deltaX,
        deltaY,
        blockUuids,
        linkUuids,
        annotationUuids,
      );
    },

    finalizeMoveEntities(
      state,
      action: PayloadAction<{
        deltaX?: number;
        deltaY?: number;
        blockUuids?: string[];
        linkUuids?: string[];
        annotationUuids?: string[];
        snap?: boolean;
        simplifyLinks?: boolean;
      }>,
    ) {
      const {
        deltaX,
        deltaY,
        blockUuids,
        linkUuids,
        annotationUuids,
        snap,
        simplifyLinks,
      } = action.payload;
      if (deltaX !== undefined && deltaY !== undefined) {
        moveBlocksAndLinksByDelta(
          state,
          deltaX,
          deltaY,
          blockUuids,
          linkUuids,
          annotationUuids,
        );
      }

      if (snap) {
        snapEntitiesToGrid(state, blockUuids, linkUuids, annotationUuids);
      }

      if (simplifyLinks && blockUuids) {
        simplifyBlocksLinkSegments(state, blockUuids);
      }
    },

    moveSelectedEntitiesByDelta(
      state,
      action: PayloadAction<{
        deltaX: number;
        deltaY: number;
      }>,
    ) {
      const { deltaX, deltaY } = action.payload;
      moveBlocksAndLinksByDelta(
        state,
        deltaX,
        deltaY,
        state.selectedBlockIds,
        state.selectedLinkIds,
        state.selectedAnnotationIds,
      );
    },

    addBlockToPositionCache(
      state,
      action: PayloadAction<{ nodeUuid: string }>,
    ) {
      const { nodeUuid } = action.payload;

      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const node = model.nodes.find((l) => l.uuid === nodeUuid);
      if (!node) return;

      state.blockPositionCache[nodeUuid] = {
        x: node.uiprops.x,
        y: node.uiprops.y,
      };
    },

    resizeSelectedNodesByGridAmount(
      state,
      action: PayloadAction<{
        deltaGridX: number;
        deltaGridY: number;
      }>,
    ) {
      const { deltaGridX, deltaGridY } = action.payload;

      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      for (let i = 0; i < model.nodes.length; i++) {
        const node = model.nodes[i];
        if (state.selectedBlockIds.includes(node.uuid)) {
          node.uiprops.grid_width = Math.max(
            renderConstants.BLOCK_MIN_GRID_WIDTH,
            (node.uiprops.grid_width || 0) + deltaGridX,
          );
          node.uiprops.grid_height = Math.max(
            renderConstants.BLOCK_MIN_GRID_HEIGHT,
            (node.uiprops.grid_height || 0) + deltaGridY,
          );
        }
      }
    },

    resizeNode(
      state,
      action: PayloadAction<{
        nodeUuid: string;
        gridWidth: number;
        gridHeight: number;
        x?: number;
        y?: number;
      }>,
    ) {
      const { nodeUuid, gridWidth, gridHeight, x, y } = action.payload;

      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const node = model.nodes.find((l) => l.uuid === nodeUuid);
      if (!node) return;

      node.uiprops.grid_width = gridWidth;
      node.uiprops.grid_height = gridHeight;

      if (x !== undefined) {
        node.uiprops.x = x;
      }

      if (y !== undefined) {
        node.uiprops.y = y;
      }

      if (state.blockPositionCache[node.uuid]) {
        state.blockPositionCache[node.uuid] = {
          x: node.uiprops.x,
          y: node.uiprops.y,
        };
      }
    },

    snapEntitiesToGrid(
      state,
      action: PayloadAction<{
        blockUuids?: string[];
        linkUuids?: string[];
        annotationUuids?: string[];
      }>,
    ) {
      const { blockUuids, linkUuids, annotationUuids } = action.payload;
      snapEntitiesToGrid(state, blockUuids, linkUuids, annotationUuids);
    },

    changeBlockParameter,
    changeBlockCommonParameter,
    deleteBlockParameter,
    addNodeExtraParameter: wrapPayloadOnlyFnAsReducer(addNodeExtraParameter),
    removeNodeExtraParameter: wrapPayloadOnlyFnAsReducer(
      removeNodeExtraParameter,
    ),
    renameNodeExtraParameter,
    updateNodeExtraParameter,

    changeNodeLabelPosition(
      state,
      action: PayloadAction<{
        blockUuid: string;
        newPosition: 'bottom' | 'top';
      }>,
    ) {
      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const { blockUuid, newPosition } = action.payload;
      const node = model.nodes.find((n) => n.uuid === blockUuid);
      if (!node) return;

      node.uiprops.label_position = newPosition;
    },

    swapNodeLabelPosition(
      state,
      action: PayloadAction<{
        blockUuid: string;
      }>,
    ) {
      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const { blockUuid } = action.payload;
      const node = model.nodes.find((n) => n.uuid === blockUuid);
      if (!node) return;

      node.uiprops.label_position =
        node.uiprops.label_position === 'top' ? 'bottom' : 'top';
    },

    togglePortLabelDisplay(
      state,
      action: PayloadAction<{
        blockUuid: string;
      }>,
    ) {
      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const { blockUuid } = action.payload;
      const node = model.nodes.find((n) => n.uuid === blockUuid);
      if (!node) return;

      node.uiprops.show_port_name_labels = !node.uiprops.show_port_name_labels;
    },

    changeNodeDirectionality(
      state,
      action: PayloadAction<{
        blockUuid: string;
        newDirectionality: 'left' | 'right';
      }>,
    ) {
      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const { blockUuid, newDirectionality: newPosition } = action.payload;
      const node = model.nodes.find((n) => n.uuid === blockUuid);
      if (!node) return;

      node.uiprops.directionality = newPosition;
    },

    changePortAlignment(
      state,
      action: PayloadAction<{
        blockUuid: string;
        newAlign: 'spaced' | 'top' | 'center' | 'bottom';
      }>,
    ) {
      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const { blockUuid, newAlign } = action.payload;
      const node = model.nodes.find((n) => n.uuid === blockUuid);
      if (!node) return;

      node.uiprops.port_alignment = newAlign;
    },

    flipNodeDirectionality(
      state,
      action: PayloadAction<{
        blockUuid: string;
      }>,
    ) {
      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const { blockUuid } = action.payload;
      const node = model.nodes.find((n) => n.uuid === blockUuid);
      if (!node) return;

      node.uiprops.directionality =
        node.uiprops.directionality === 'left' ? 'right' : 'left';
    },

    selectAdditionalNodes,
    selectAdditionalLinks,
    selectAdditionalAnnotations,
    unselectNode,
    unselectLink,
    unselectAll,
    unselectAnnotation,

    setLinkHangCoordStart(
      state,
      action: PayloadAction<{ linkUuid: string; coord: Coordinate }>,
    ) {
      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const { linkUuid, coord } = action.payload;
      const link = model.links.find((l) => l.uuid === linkUuid);

      if (link) {
        link.uiprops.hang_coord_start = coord;
      }
    },

    setLinkHangCoordEnd(
      state,
      action: PayloadAction<{ linkUuid: string; coord: Coordinate }>,
    ) {
      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const { linkUuid, coord } = action.payload;
      const link = model.links.find((l) => l.uuid === linkUuid);

      if (link) {
        link.uiprops.hang_coord_end = coord;
      }
    },

    /**
     * WARNING: Do not call this method except from the URLParameterTracker component.
     * //// Instead, dispatch a request to the navigation slice:
     * ////`navigationActions.requestToSetNavigationAndSelection`
     * (Nav under construction:
     * The `requestToSetNavigationAndSelection` is no more since was adding needless layers of indireciton.
     * Use react-router's API to update the URL.
     * The tracker component detailed below is still here.
     * -- jj 2023-09-01)
     * which will be picked up by URLParameterTracker which will then update the URL.
     * Once the new parent path is in the URL, the URLParameterTracker will detect that change
     * and then update the parent path here (and update the current selections).
     * This data flow is deliberately URL-first and only operates in one direction to
     * ensure that the path in the URL always stays in sync with the redux state.
     */
    setPathWithSelections(
      state,
      action: PayloadAction<NavigationAndSelectionRequest>,
    ) {
      const { parentPath } = action.payload;
      if (parentPath) {
        state.currentSubmodelPath = parentPath;
      }

      setSelections(state, action.payload);
    },

    setSelections(state, action: PayloadAction<SelectionRequest>) {
      setSelections(state, action.payload);
    },

    createSubdiagramFromSelection: wrapPayloadOnlyFnAsReducer(
      createSubdiagramFromSelection,
    ),

    confirmReferenceSubmodelCreatedFromSelection(
      state,
      action: PayloadAction<{
        submodelInstanceId: string;
        referenceSubmodelId: string;
        submodel: SubmodelInfoUI;
      }>,
    ) {
      const { submodelInstanceId, referenceSubmodelId, submodel } =
        action.payload;
      confirmReferenceSubmodelCreatedFromSelection(
        state,
        submodelInstanceId,
        referenceSubmodelId,
        submodel,
      );
    },

    insertNodeIntoLink(
      state,
      action: PayloadAction<{
        linkUuid: string;
        nodeUuid: string;
        vertexData: LinkVertex;
        nextVertexData: LinkVertex;
      }>,
    ) {
      const { linkUuid, nodeUuid, vertexData, nextVertexData } = action.payload;
      insertNodeIntoLink(state, linkUuid, nodeUuid, vertexData, nextVertexData);
    },

    disconnectLinkFromSourceOrDest,
    connectNodesPorts,
    addPort: wrapPayloadOnlyFnAsReducer(addPort),
    removePort: wrapPayloadOnlyFnAsReducer(removePort),
    removeAllInputPorts: wrapPayloadOnlyFnAsReducer(removeAllInputPorts),
    removeAllPorts: wrapPayloadOnlyFnAsReducer(removeAllPorts),
    renamePort,
    setPortParameters,
    setOutputPortRecord,

    resetDynamicBlock: wrapPayloadOnlyFnAsReducer(resetDynamicBlock),
    configureDynamicBlock: wrapPayloadOnlyFnAsReducer(configureDynamicBlock),

    // NOTE: reintroducing this action in order to have proper typing unlike
    // changeModelConfigurationValue and changeModelConfigurationSolverValue
    updateModelConfiguration(state, action: PayloadAction<ModelConfiguration>) {
      state.configuration = transformBackendConfiguration(action.payload);
    },

    changeModelConfigurationValue(
      state,
      action: PayloadAction<{
        name: keyof ModelConfiguration;
        value: number | string | boolean | undefined;
      }>,
    ) {
      const { name, value } = action.payload;
      const configuration = { ...state.configuration, [name]: value };
      state.configuration = transformBackendConfiguration(configuration);
    },

    changeModelConfigurationSolverValue(
      state,
      action: PayloadAction<{
        name: any;
        value: number | string | undefined;
      }>,
    ) {
      const { name, value } = action.payload;
      const configuration = {
        ...state.configuration,
        solver: { ...state.configuration.solver, [name]: value },
      };
      state.configuration = transformBackendConfiguration(configuration);
    },

    // MODEL LEVEL PARAMETERS

    addModelParameter(
      state,
      action: PayloadAction<{
        name?: string;
        value?: string;
      }>,
    ) {
      const { name, value } = action.payload;

      let paramName: string;
      if (name) {
        paramName = name;
      } else {
        const paramNames = state.parameters.map((p) => p.name);
        const nodeNames = state.rootModel.nodes
          .map((n) => n.name)
          .filter((n) => n) as string[];
        const linkNames = state.rootModel.links
          .map((n) => n.name)
          .filter((n) => n) as string[];

        const existingNames = [...paramNames, ...nodeNames, ...linkNames];
        paramName = getUniqueIdentifier('param_0', existingNames);
      }
      state.parameters.push({ name: paramName, value: value || '0' });
    },

    setModelParameterName(
      state,
      action: PayloadAction<{
        index: number;
        name: string;
      }>,
    ) {
      const { index, name } = action.payload;
      state.parameters[index].name = name;
    },

    setModelParameterValue(
      state,
      action: PayloadAction<{
        index: number;
        value: string;
      }>,
    ) {
      const { index, value } = action.payload;
      if (!state.parameters[index]) return;

      state.parameters[index].value = value;
    },

    removeModelParameter(state, action: PayloadAction<{ index: number }>) {
      const { index } = action.payload;
      state.parameters = state.parameters.filter((_, idx) => idx !== index);
    },

    setInitScriptName,
    setIsAtomic,
    setDiscreteStep,
    unsetDiscreteStep,

    addNewAnnotation(
      state,
      action: PayloadAction<{
        x: number;
        y: number;
        gridWidth: number;
        gridHeight: number;
      }>,
    ) {
      const { gridWidth, gridHeight, x, y } = action.payload;
      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      if (!model.annotations) model.annotations = [];

      const annotationID = makeUuid();

      model.annotations.push({
        uuid: annotationID,
        text: 'New Annotation',
        x,
        y,
        grid_width: gridWidth,
        grid_height: gridHeight,
        color_id: 'green',
      });

      state.selectedAnnotationIds = [annotationID];
      state.selectedBlockIds = [];
      state.selectedLinkIds = [];
    },

    resizeAnnotation(
      state,
      action: PayloadAction<{
        uuid: string;
        gridWidth: number;
        gridHeight: number;
        x?: number;
        y?: number;
      }>,
    ) {
      const { uuid, gridWidth, gridHeight, x, y } = action.payload;

      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const annotation = (model.annotations || []).find((a) => a.uuid === uuid);
      if (!annotation) return;

      annotation.grid_width = Math.max(2, gridWidth);
      annotation.grid_height = Math.max(2, gridHeight);

      if (x !== undefined) {
        annotation.x = x;
      }

      if (y !== undefined) {
        annotation.y = y;
      }
    },

    setAnnotationText(
      state,
      action: PayloadAction<{
        uuid: string;
        text: string;
      }>,
    ) {
      const { uuid, text } = action.payload;

      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const annotation = (model.annotations || []).find((a) => a.uuid === uuid);
      if (!annotation) return;

      annotation.text = text;
    },

    setAnnotationColor(
      state,
      action: PayloadAction<{
        uuid: string;
        colorId: string;
      }>,
    ) {
      const { uuid, colorId } = action.payload;

      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const annotation = (model.annotations || []).find((a) => a.uuid === uuid);
      if (!annotation) return;

      annotation.color_id = colorId;
    },

    setAnnotationLabelPos(
      state,
      action: PayloadAction<{
        uuid: string;
        pos: string;
      }>,
    ) {
      const { uuid, pos } = action.payload;

      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const annotation = (model.annotations || []).find((a) => a.uuid === uuid);
      if (!annotation) return;

      if (pos === 'top' || pos === 'bottom' || pos == 'inside') {
        annotation.label_position = pos;
      }
    },

    setDeveloperOptions(state, action: PayloadAction<{ options: any }>) {
      const { options } = action.payload;
      state.configuration.__developer_options = options;
    },

    setNodeAutotuned(
      state,
      action: PayloadAction<{
        uuid: string;
        tuned: boolean;
      }>,
    ) {
      const model = getCurrentlyEditingModelFromState(state);
      if (!model) return;

      const { uuid, tuned } = action.payload;

      const node = model.nodes.find((n) => n.uuid === uuid);

      if (!node) return;

      node.uiprops.is_autotuned = tuned;
    },

    reorderNodePort(
      state,
      action: PayloadAction<{
        parentPath: string[];
        nodeUuid: string;
        side: PortSide;
        portIndex: number;
        fromOrderIndex: number;
        toOrderIndex: number;
      }>,
    ) {
      const {
        nodeUuid,
        side,
        portIndex,
        fromOrderIndex,
        toOrderIndex,
        parentPath,
      } = action.payload;

      const model = getCurrentlyEditingModelFromState(state, parentPath);
      if (!model) return;

      const node = model.nodes.find((n) => n.uuid === nodeUuid);

      if (!node) return;

      const maxMovingOrder = Math.max(fromOrderIndex, toOrderIndex);

      if (!node.uiprops.inport_order) {
        node.uiprops.inport_order = node.inputs.map((_, i) => i);
      }
      if (!node.uiprops.inport_order_index_map) {
        node.uiprops.inport_order_index_map = node.inputs.map((_, i) => i);
      }
      if (!node.uiprops.outport_order) {
        node.uiprops.outport_order = node.outputs.map((_, i) => i);
      }
      if (!node.uiprops.outport_order_index_map) {
        node.uiprops.outport_order_index_map = node.outputs.map((_, i) => i);
      }

      if (
        side === PortSide.Input &&
        node.uiprops.inport_order.length <= maxMovingOrder
      ) {
        node.uiprops.inport_order = node.inputs.map((_, i) => i);
        console.warn('ERROR WITH INPUT PORT REORDER: out of range');
        return;
      }
      if (
        side === PortSide.Output &&
        node.uiprops.outport_order.length <= maxMovingOrder
      ) {
        node.uiprops.outport_order = node.outputs.map((_, i) => i);
        console.warn('ERROR WITH OUTPUT PORT REORDER: out of range');
        return;
      }

      const modifyingOrder =
        side === PortSide.Input
          ? node.uiprops.inport_order
          : node.uiprops.outport_order;

      if (fromOrderIndex < toOrderIndex) {
        for (let i = fromOrderIndex; i < toOrderIndex; i++) {
          modifyingOrder[i] = parseInt(`${modifyingOrder[i + 1]}`);
        }
        modifyingOrder[toOrderIndex] = portIndex;
      } else {
        for (let i = fromOrderIndex; i > toOrderIndex; i--) {
          modifyingOrder[i] = parseInt(`${modifyingOrder[i - 1]}`);
        }
        modifyingOrder[toOrderIndex] = portIndex;
      }

      const modifyingMap =
        side === PortSide.Input
          ? node.uiprops.inport_order_index_map
          : node.uiprops.outport_order_index_map;

      for (let i = 0; i < modifyingOrder.length; i++) {
        const portIndex = parseInt(`${modifyingOrder[i]}`);
        modifyingMap[portIndex] = i;
      }
    },

    addStateNode,
    addStateLink,
    repositionLink,
    setStateMachineEntryPointConnection,
    setStateMachineEntryPointAction,
    addNewStateMachineEntryPointAction: (
      state: ModelState,
      action: PayloadAction<{
        stateMachineUuid: string;
      }>,
    ) => {
      const { stateMachineUuid } = action.payload;
      const stateMachine = state.stateMachines?.[stateMachineUuid];
      if (!stateMachine) return;

      if (!stateMachine.entry_point.actions)
        stateMachine.entry_point.actions = [''];
      stateMachine.entry_point.actions.push('');
    },
    deleteStateMachineEntryPointAction: (
      state: ModelState,
      action: PayloadAction<{
        stateMachineUuid: string;
        index: number;
      }>,
    ) => {
      const { stateMachineUuid, index } = action.payload;
      const stateMachine = state.stateMachines?.[stateMachineUuid];
      if (!stateMachine) return;

      if (stateMachine.entry_point.actions) {
        stateMachine.entry_point.actions.splice(index, 1);
      }
    },
    moveStateNodesByDelta,
    snapStateNodesToGrid,
    moveStateLinkCurveDeviationByDelta,
    setStateLinkGuard,
    setStateLinkAction,
    addNewStateLinkAction: (
      state: ModelState,
      action: PayloadAction<{
        stateMachineUuid: string;
        linkId: string;
      }>,
    ) => {
      const { stateMachineUuid, linkId } = action.payload;
      const stateMachine = state.stateMachines?.[stateMachineUuid];
      if (!stateMachine) return;

      const link = stateMachine.links.find((link) => link.uuid === linkId);
      if (!link) return;

      if (!link.actions) link.actions = [''];
      link.actions.push('');
    },
    deleteStateLinkAction: (
      state: ModelState,
      action: PayloadAction<{
        stateMachineUuid: string;
        linkId: string;
        index: number;
      }>,
    ) => {
      const { stateMachineUuid, linkId, index } = action.payload;
      const stateMachine = state.stateMachines?.[stateMachineUuid];
      if (!stateMachine) return;

      const link = stateMachine.links.find((link) => link.uuid === linkId);
      if (!link) return;

      if (link.actions) {
        link.actions.splice(index, 1);
      }
    },

    deleteStateMachineEntities,
    createNewStateMachineWithUuid,
    changeStateNodeName,
    eraseModelLayout,
    consumeAutoLayout,
    postAutoLayout,
  },
  extraReducers: (builder) => {
    // NOTE: this is a hack, and should not be looked to as an example or acceptable pattern.
    // it's for the prototype version of realtime multiplayer.
    // basically, this prevents updates being sent to the server (both WS and HTTP)
    // when the only thing that happens is the model content loading.
    builder.addMatcher(
      (action) => {
        const [slice, actionName] = action.type.split('/');
        if (
          slice === 'modelSlice' &&
          actionName !== 'loadModelContent' &&
          actionName !== 'loadSubmodelContent' &&
          actionName !== 'updateReferencedSubmodelInstances'
        ) {
          return true;
        }
        return false;
      },
      (state) => {
        state.preventSendingUpdateData = false;
      },
    );
  },
});

export const modelActions = modelSlice.actions;

export default modelSlice;

// Selectors

const selectTopLevelNodes = (state: ModelState) => state.rootModel.nodes;
const selectTopLevelSubmodels = (state: ModelState) => state.submodels;
const selectCurrentSubmodelPath = (state: ModelState) =>
  state.currentSubmodelPath;

/**
 * Returns the type of the current subdiagram in view. `undefined` if not in a subdiagram.
 */
export const selectCurrentSubdiagramType = createSelector(
  [selectTopLevelNodes, selectTopLevelSubmodels, selectCurrentSubmodelPath],
  (topLevelNodes, topLevelSubmodels, currentSubmodelPath) => {
    const parentPath = currentSubmodelPath.slice(0, -1);
    const nodeId = currentSubmodelPath[currentSubmodelPath.length - 1];
    return getNestedNode(topLevelNodes, topLevelSubmodels, parentPath, nodeId)
      ?.type;
  },
);

/**
 * Returns the UUID of the reference submodel instance given the path.
 * Differs from currentDiagramSubmodelReferenceId in modelMetadata since if no node is selected within a submodel
 * the housing submodel is selected by default, and that's one level above the current path.
 */
export const selectSubmodelReferenceUuid = createSelector(
  [
    selectTopLevelNodes,
    selectTopLevelSubmodels,
    (state: ModelState, nodePath: string[]) => nodePath,
  ],
  (topLevelNodes, topLevelSubmodels, nodePath) => {
    if (nodePath.length === 0) return undefined;

    const parentPath = nodePath.slice(0, -1);
    const nodeId = nodePath[nodePath.length - 1];

    const currentSubdiagramHousingNode = getNestedNode(
      topLevelNodes,
      topLevelSubmodels,
      parentPath,
      nodeId,
    );
    if (
      !currentSubdiagramHousingNode ||
      currentSubdiagramHousingNode.type !== 'core.ReferenceSubmodel'
    )
      return undefined;

    const currentSubmodelInstance =
      currentSubdiagramHousingNode as SubmodelInstance;

    return currentSubmodelInstance.submodel_reference_uuid;
  },
);
