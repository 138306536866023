import * as NVG from 'nanovg-js';

export enum RasterLoadState {
  Loading,
  Loaded,
  Failed,
}

export type RasterMetaType =
  | {
      loadState: RasterLoadState.Loading | RasterLoadState.Failed;
    }
  | {
      imageId: number;
      width: number;
      height: number;
      preZoom: number;
      loadState: RasterLoadState.Loaded;
    };

export type RasterMetaStoreType = {
  [key: string]: RasterMetaType | undefined;
};

export const rasterMetaStore: RasterMetaStoreType = {};

export const loadArrayBufferPromise = async (
  url: string,
): Promise<ArrayBuffer | undefined> =>
  fetch(url).then((response) => {
    const contentType = response.headers.get('Content-Type') || '';
    const isImage = contentType.indexOf('image') > -1;

    if (isImage && response.body) {
      const reader = response.body.getReader();

      const streamResponse = new Response(
        new ReadableStream({
          async start(controller) {
            let reading = true;
            while (reading) {
              // eslint-disable-next-line
              const { done, value } = await reader.read();

              if (done) {
                reading = false;
              } else {
                controller.enqueue(value);
              }
            }

            controller.close();
            reader.releaseLock();
          },
        }),
      );

      return streamResponse.arrayBuffer();
    }

    return undefined;
  });

export const allocMemImageAndIntoStoreFromImageBuffer = (
  nvg: NVG.Context,
  imageBuffer: ArrayBuffer,
  id: string,
  preZoom = 1,
) => {
  const imageData = new Uint8Array(imageBuffer);
  const imageId = nvg.createImageMem(
    NVG.NVGimageFlags.PREMULTIPLIED,
    imageData,
  );

  const width: [number] = [0];
  const height: [number] = [0];
  nvg.imageSize(imageId, width, height);

  rasterMetaStore[id] = {
    imageId,
    width: width[0],
    height: height[0],
    preZoom,
    loadState: RasterLoadState.Loaded,
  };
};

export const loadImageIntoMemAndStore = async (
  nvg: NVG.Context,
  url: string,
  id: string,
  preZoom = 1,
) => {
  rasterMetaStore[id] = {
    loadState: RasterLoadState.Loading,
  };

  const imageBuffer = await loadArrayBufferPromise(url);

  if (!imageBuffer) {
    rasterMetaStore[id] = {
      loadState: RasterLoadState.Failed,
    };

    console.warn(`Failed to load image ID ${id} :`, url);
    return;
  }

  allocMemImageAndIntoStoreFromImageBuffer(nvg, imageBuffer, id, preZoom);
};

export const getOrInitLoadImageFromStore = (
  nvg: NVG.Context,
  url: string,
  id: string,
  preZoom = 1,
) => {
  if (!rasterMetaStore[id]) {
    loadImageIntoMemAndStore(nvg, url, id, preZoom);
  }

  return rasterMetaStore[id];
};

export const deleteImageFromMemAndStore = (nvg: NVG.Context, id: string) => {
  if (rasterMetaStore[id]) {
    const rasterMeta = rasterMetaStore[id];
    if (rasterMeta?.loadState === RasterLoadState.Loaded) {
      nvg.deleteImage(rasterMeta.imageId);
    }

    delete rasterMetaStore[id];
  }
};

export const deleteAllImagesFromMemAndStore = (nvg: NVG.Context) => {
  Object.keys(rasterMetaStore).forEach((id) => {
    deleteImageFromMemAndStore(nvg, id);
  });
};
