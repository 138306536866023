import { MouseActions } from 'app/common_types/MouseTypes';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { renderConstants } from 'app/utils/renderConstants';
import * as NVG from 'nanovg-js';
import { drawLink } from 'ui/modelRendererInternals/drawLink';
import { drawNode } from 'ui/modelRendererInternals/drawNode';
import {
  keysPressed,
  PortConnLUTType,
  RendererState,
} from 'ui/modelRendererInternals/modelRenderer';
import { LINE_COLORS } from './coloring';
import { convertZoomedScreenToWorldCoordinates } from './convertScreenToWorldCoordinates';
import { drawAnnotation } from './drawAnnotation';
import { getHoveringEntities } from './getHoveringEntities';

let frameCount = 0;
let frameCountStartTime = 0;
let lastRenderFPS = 0;

function drawDebuggingInfo(nvg: NVG.Context, rs: RendererState): void {
  if (!rs.refs.current.uiFlags.renderDebug) return;

  frameCount++;
  if (frameCountStartTime === 0) {
    frameCountStartTime = Date.now();
  }
  const time = Date.now() - frameCountStartTime;
  const fps = frameCount / (time / 1000);
  if (time > 1000) {
    lastRenderFPS = Math.round(fps);
    frameCountStartTime = Date.now();
    frameCount = 0;
  }

  const debugLegendX = rs.refs.current.uiFlags.isLeftSidebarOpen ? 248 : 16;

  const parts = [`FPS: ${lastRenderFPS}`];

  const keysPressedLines = JSON.stringify(keysPressed)
    .replaceAll('{', '')
    .replaceAll('}', '')
    .replaceAll('"', '')
    .replaceAll(',', '\n')
    .replaceAll(':', ': ')
    .split('\n');
  parts.push(...keysPressedLines);

  nvg.beginPath();
  nvg.rect(debugLegendX, 90, 150, parts.length * 15 + 8);
  nvg.fillColor(NVG.RGBA(255, 255, 255, 128));
  nvg.fill();

  for (let i = 0; i < parts.length; i++) {
    nvg.fontSize(12);
    nvg.textAlign(NVG.Align.LEFT);
    nvg.fillColor(nvg.RGBA(74, 74, 74, 255));
    nvg.text(debugLegendX + 8, 104 + i * 15, parts[i], null);
  }

  const worldCursor = convertZoomedScreenToWorldCoordinates(
    rs.camera,
    rs.screenCursorZoomed,
  );
  const hoveringEntities = getHoveringEntities(
    rs.mouseState,
    worldCursor,
    rs.camera,
    rs.zoom,
    rs.refs.current.nodes,
    rs.refs.current.links,
    rs.refs.current.annotations,
    rs.refs.current.linksIndexLUT,
    rs.linksRenderFrameData,
    getCurrentModelRef().submodelPath,
  );
  // NOTE: rs.hoveringEntity is not used

  for (let entity of hoveringEntities) {
    const geom = entity.geom;
    if (!geom) continue;

    nvg.beginPath();
    const rx = (geom.x + rs.camera.x) * rs.zoom;
    const ry = (geom.y + rs.camera.y) * rs.zoom;
    const rw = geom.width * rs.zoom;
    const rh = geom.height * rs.zoom;
    nvg.rect(rx, ry, rw, rh);
    if (entity === hoveringEntities[0]) {
      nvg.strokeColor(NVG.RGBA(0, 255, 0, 128));
      nvg.stroke();
    }
    nvg.fillColor(NVG.RGBA(0, 255, 0, 32));
    nvg.fill();
  }
}

export function drawScene(
  nvg: NVG.Context,
  rs: RendererState,
  connectedPortsLUT: PortConnLUTType,
  selectedBlockIds: string[],
  width: number,
  height: number,
): void {
  for (let i = 0; i < rs.refs.current.annotations.length; i++) {
    const annoData = rs.refs.current.annotations[i];
    const selected = rs.refs.current.selectedAnnotationIds.includes(
      annoData.uuid,
    );
    drawAnnotation(nvg, rs, annoData, selected);
  }

  for (let i = 0; i < rs.linksRenderFrameData.length; i++) {
    const linkData = rs.linksRenderFrameData[i];
    drawLink(
      nvg,
      rs, // TODO: decouple renderer state from primitive render funcs
      linkData,
      rs.camera.x,
      rs.camera.y,
    );
  }

  const clipMargin = renderConstants.GRID_UNIT_PXSIZE * 2;

  for (let i = 0; i < rs.refs.current.nodes.length; i++) {
    const node = rs.refs.current.nodes[i];
    const renderX = node.uiprops.x + rs.camera.x;
    const renderY = node.uiprops.y + rs.camera.y;

    // Clip (skip) blocks completely outside of the view
    if (
      renderX * rs.zoom > width + clipMargin ||
      renderY * rs.zoom > height + clipMargin ||
      renderX * rs.zoom <
        -(node.uiprops.grid_width ?? renderConstants.BLOCK_MIN_GRID_WIDTH) *
          renderConstants.GRID_UNIT_PXSIZE *
          rs.zoom ||
      renderY * rs.zoom <
        -(node.uiprops.grid_height ?? renderConstants.BLOCK_MIN_GRID_HEIGHT) *
          renderConstants.GRID_UNIT_PXSIZE *
          rs.zoom
    ) {
      continue;
    }

    drawNode(
      nvg,
      rs,
      node,
      connectedPortsLUT[node.uuid],
      selectedBlockIds.includes(node.uuid),
      renderX,
      renderY,
    );
  }

  if (
    rs.mouseState.state === MouseActions.MakingSelection ||
    rs.mouseState.state === MouseActions.DefiningAnnotationBox
  ) {
    const isForAnnotation =
      rs.mouseState.state === MouseActions.DefiningAnnotationBox;
    const { rawScreenCursorStartX, rawScreenCursorStartY } = rs.mouseState;
    const minX = Math.min(rs.screenCursorRaw.x, rawScreenCursorStartX);
    const maxX = Math.max(rs.screenCursorRaw.x, rawScreenCursorStartX);
    const minY = Math.min(rs.screenCursorRaw.y, rawScreenCursorStartY);
    const maxY = Math.max(rs.screenCursorRaw.y, rawScreenCursorStartY);

    const w = maxX - minX;
    const h = maxY - minY;
    const widthSteps = w / 10;
    const heightSteps = h / 10;

    nvg.strokeWidth(1);
    nvg.strokeColor(
      isForAnnotation
        ? LINE_COLORS.annotation_rectangle
        : LINE_COLORS.selection_rectangle,
    );

    for (let i = 0; i < widthSteps; i++) {
      const lineStart = minX + i * 10;
      const lineEnd = Math.min(lineStart + 5, maxX);
      // beginning side line
      nvg.beginPath();
      nvg.moveTo(lineStart, minY);
      nvg.lineTo(lineEnd, minY);
      nvg.stroke();
      // ending side line
      nvg.beginPath();
      nvg.moveTo(lineStart, maxY);
      nvg.lineTo(lineEnd, maxY);
      nvg.stroke();
    }

    for (let i = 0; i < heightSteps; i++) {
      const lineStart = minY + i * 10;
      const lineEnd = Math.min(lineStart + 5, maxY);
      // beginning side line
      nvg.beginPath();
      nvg.moveTo(minX, lineStart);
      nvg.lineTo(minX, lineEnd);
      nvg.stroke();
      // ending side line
      nvg.beginPath();
      nvg.moveTo(maxX, lineStart);
      nvg.lineTo(maxX, lineEnd);
      nvg.stroke();
    }
  }

  drawDebuggingInfo(nvg, rs);
}
