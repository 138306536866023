import { t } from '@lingui/macro';
import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { SubmodelInfoUI } from 'app/apiTransformers/convertGetSubmodelsListForModelParent';
import {
  TimeModeValueType,
  userDefinedBlockTimeModes,
} from 'app/common_types/TimeModeTypesData';
import { blockClassLookup } from 'app/generated_blocks/';
import {
  BlockInstance,
  SubmodelInstance,
} from 'app/generated_types/SimulationModel';
import { wildcatClassTable } from 'app/generated_wildcat_cache/wildcatClassStrings';
import {
  nodeClassWithoutNamespace_displayOnly,
  nodeTypeIsLocalSubdiagram,
  nodeTypeIsReferencedSubmodel,
  printNameToSpacedDisplayName,
} from 'app/helpers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  modelActions,
  selectSubmodelReferenceUuid,
} from 'app/slices/modelSlice';
import { getIsCurrentDiagramReadonly } from 'app/utils/modelDiagramUtils';
import { isSamePath } from 'app/utils/parentPathUtils';
import { getSpecificReferenceSubmodelByNode } from 'app/utils/submodelUtils';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM } from 'ui/codeEditor/CodeEditor';
import Button from 'ui/common/Button/Button';
import { ButtonVariants } from 'ui/common/Button/buttonTypes';
import { Code } from 'ui/common/Icons/Standard';
import SectionHeading from 'ui/common/Inputs/SectionHeading';
import SelectInput from 'ui/common/SelectInput';
import { BlockDetailTitle } from 'ui/modelEditor/BlockDetailTitle';
import { BlockParametersDetails } from 'ui/modelEditor/BlockParametersDetails';
import {
  DetailInputRowsSection,
  DetailsLabel,
  DetailsSection,
} from 'ui/modelEditor/DetailsComponents';
import SubmodelParameterDetails from 'ui/modelEditor/SubmodelParameterDetails';
import { useModelPermission } from 'ui/permission/useModelPermission';
import { useAppParams } from 'util/useAppParams';
import { BlockAcausalPortDetails } from './BlockAcausalPortDetails';
import BlockCodeGenDetails from './BlockCodeGenDetails';
import RequirementsSelector from './blockDetails/RequirementsSelector';
import BlockDisplayDetails from './BlockDisplayDetails';
import BlockInportDetails from './BlockInportDetails';
import { BlockNameField } from './BlockNameField';
import BlockOutportDetails from './BlockOutportDetails';
import CodeBlockParameterDetails from './BlockParameterDetails/CodeBlockParameterDetails';
import FmuBlockParameterDetails from './BlockParameterDetails/FmuBlockParameterDetails';
import { KalmanBlockParameterDetails } from './BlockParameterDetails/KalmanBlockParameterDetails';
import LeafSystemBlockParameterDetails from './BlockParameterDetails/LeafSystemBlockParameterDetails';
import { SindyBlockParametersDetails } from './BlockParameterDetails/SindyBlockParameterDetails';
import LegacySubmodelParametersDetails from './LegacySubmodelParameterDetails';
import { useNodeWithoutUIProps } from './useNodeWithoutUIProps';

interface Props {
  parentPath: string[];
  nodeId: string;
  canEditCurrentModelVersion: boolean;
}

export const BlockDetails: React.FC<Props> = ({
  parentPath,
  nodeId,
  canEditCurrentModelVersion,
}) => {
  const dispatch = useAppDispatch();
  const { fmuBlocksEnabled, developerModeEnabled, requirementsEnabled } =
    useAppSelector((state) => state.userOptions.options);

  const currentSubmodelPath = useAppSelector(
    (state) => state.model.present.currentSubmodelPath,
  );
  const idToVersionIdToSubmodelInfo = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelInfo,
  );
  const idToVersionIdToSubmodelInfoLite = useAppSelector(
    (state) => state.submodels.idToVersionIdToSubmodelInfoLite,
  );

  // determine read only mode
  const { modelId, projectId, versionId } = useAppParams();

  const loadedModelId = useAppSelector(
    (state) => state.modelMetadata.loadedModelId,
  );

  const referenceSubmodelId = useAppSelector(
    (state) => state.modelMetadata.currentDiagramSubmodelReferenceId,
  );

  // Different from referenceSubmodelId if we're in a submodel with no node selected
  const parentSubmodelReferenceUuid = useAppSelector((state) =>
    selectSubmodelReferenceUuid(state.model.present, parentPath),
  );

  const { arePermissionsLoaded } = useModelPermission(
    projectId,
    modelId,
    versionId,
  );

  const isDiagramReadonly = getIsCurrentDiagramReadonly({
    modelId,
    loadedModelId,
    referenceSubmodelId,
    arePermissionsLoaded,
    canEditCurrentModelVersion,
  });

  const { nodeWithoutUIProps: selectedNode, isInCurrentDiagram } =
    useNodeWithoutUIProps(parentPath, nodeId);
  const canEdit = canEditCurrentModelVersion && isInCurrentDiagram;

  const [searchParams, setSearchParams] = useSearchParams();
  const blockCodeOpen = !!searchParams.get(
    MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM,
  );

  const noNamespaceBlockClassName = React.useMemo(() => {
    if (!selectedNode?.type) return;
    return nodeClassWithoutNamespace_displayOnly(selectedNode.type);
  }, [selectedNode?.type]);

  const isInSourceCodeCache =
    wildcatClassTable[noNamespaceBlockClassName || ''];

  const navigateToBlockSourceCode = React.useCallback(async () => {
    if (blockCodeOpen) {
      searchParams.delete(MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM);
      setSearchParams(searchParams);
      return;
    }

    if (!noNamespaceBlockClassName) return;
    searchParams.set(
      MODEL_SOURCE_CODE_VIEWING_QUERY_PARAM,
      noNamespaceBlockClassName,
    );
    setSearchParams(searchParams);
  }, [blockCodeOpen, noNamespaceBlockClassName, searchParams, setSearchParams]);

  const changeTimeMode = React.useCallback(
    (value: string) => {
      dispatch(
        modelActions.changeBlockCommonParameter({
          parentPath,
          blockUuid: nodeId,
          paramPayload: {
            paramName: 'time_mode',
            value: value as TimeModeValueType,
          },
        }),
      );
    },
    [dispatch, nodeId, parentPath],
  );

  if (!selectedNode) {
    return null;
  }

  let submodelInfoLiteReference: SubmodelInfoLiteUI | undefined;
  let submodelInfoReference: SubmodelInfoUI | undefined;
  if (nodeTypeIsReferencedSubmodel(selectedNode.type)) {
    const submodelInstance = selectedNode as SubmodelInstance;
    if (submodelInstance && submodelInstance.submodel_reference_uuid) {
      submodelInfoLiteReference = getSpecificReferenceSubmodelByNode(
        submodelInstance,
        idToVersionIdToSubmodelInfoLite,
      );
      submodelInfoReference = getSpecificReferenceSubmodelByNode(
        submodelInstance,
        idToVersionIdToSubmodelInfo,
      );
    }
  }

  const blockClass = blockClassLookup(selectedNode.type);

  return (
    <>
      {/* Block name and type */}
      <BlockDetailTitle
        description={
          submodelInfoLiteReference
            ? submodelInfoLiteReference.description
            : nodeTypeIsLocalSubdiagram(selectedNode.type)
            ? ''
            : blockClass.base.description
        }
        helpUrl={blockClass.base.help_url}
        blockTypeDisplayName={
          submodelInfoLiteReference
            ? submodelInfoLiteReference.name
            : printNameToSpacedDisplayName(blockClass.base.name)
        }
        blockClass={blockClass}
        submodelReference={submodelInfoReference}
      />
      <DetailInputRowsSection>
        <BlockNameField
          parentPath={parentPath}
          blockUuid={selectedNode.uuid}
          title={selectedNode.name}
          canEdit={canEdit}
        />
        {isInSourceCodeCache && (
          <Button
            variant={ButtonVariants.SmallTertiary}
            onClick={navigateToBlockSourceCode}
            Icon={Code}>
            {blockCodeOpen ? t`Hide block code` : t`Show block code`}
          </Button>
        )}
      </DetailInputRowsSection>

      {requirementsEnabled && projectId && modelId && (
        <RequirementsSelector
          projectUuid={projectId}
          modelId={parentSubmodelReferenceUuid || modelId}
          blockInstanceUuid={nodeId}
          canEdit={canEdit}
        />
      )}

      {/* Parameters */}
      {selectedNode.type === 'core.Group' ? null : selectedNode.type ===
        'core.Submodel' ? (
        <LegacySubmodelParametersDetails
          parentPath={parentPath}
          submodelNode={selectedNode}
          canEdit={canEdit}
        />
      ) : selectedNode.type === 'core.ReferenceSubmodel' ? (
        <SubmodelParameterDetails
          parentPath={parentPath}
          submodelNode={selectedNode as SubmodelInstance}
          disabled={!canEdit}
        />
      ) : selectedNode.type === 'core.PythonScript' ? (
        <CodeBlockParameterDetails
          parentPath={parentPath}
          node={selectedNode as BlockInstance}
          disabled={!canEdit}
        />
      ) : selectedNode.type === 'core.ModelicaFMU' ? (
        <FmuBlockParameterDetails
          parentPath={parentPath}
          node={selectedNode as BlockInstance}
          disabled={!canEdit}
        />
      ) : selectedNode.type === 'core.SINDy' ? (
        <SindyBlockParametersDetails
          parentPath={parentPath}
          selectedNode={selectedNode}
          canEdit={canEdit}
        />
      ) : selectedNode.type === 'core.KalmanFilter' ? (
        <KalmanBlockParameterDetails
          parentPath={parentPath}
          selectedNode={selectedNode}
          canEdit={canEdit}
        />
      ) : selectedNode.type === 'core.CustomLeafSystem' ? (
        <LeafSystemBlockParameterDetails
          parentPath={parentPath}
          selectedNode={selectedNode}
          canEdit={canEdit}
        />
      ) : (
        <BlockParametersDetails
          parentPath={parentPath}
          selectedNode={selectedNode}
          canEdit={canEdit}
        />
      )}
      {/* Simulation settings */}
      {(selectedNode.type === 'core.PythonScript' ||
        selectedNode.type === 'core.StateMachine') &&
        blockClass.modes.time === 'any' && (
          <>
            <SectionHeading testId="simulation">
              {t({
                id: 'modelRenderer.propertiesSidebar.simulationSettingsHeading.label',
                message: 'Simulation',
              })}
            </SectionHeading>
            <DetailInputRowsSection key="sim_params">
              <DetailsSection key={`row-result-time_mode-${selectedNode.uuid}`}>
                <DetailsLabel data-test-id="block-details-time-mode-label">
                  {t({
                    id: 'blockDetails.TimeModeLabel',
                    message: 'Time mode',
                  })}
                </DetailsLabel>
                <SelectInput
                  testId="block-details-time-mode-select"
                  onSelectValue={changeTimeMode}
                  currentValue={
                    (selectedNode as BlockInstance)?.time_mode || 'agnostic'
                  }
                  options={userDefinedBlockTimeModes}
                  isDisabled={!canEdit}
                />
              </DetailsSection>
            </DetailInputRowsSection>
          </>
        )}
      {/* Inputs */}
      <BlockInportDetails
        parentPath={parentPath}
        selectedNode={selectedNode}
        canEdit={canEdit}
      />
      {/* Outputs */}
      <BlockOutportDetails
        parentPath={parentPath}
        selectedNode={selectedNode}
        canEdit={canEdit}
      />
      {/* Acausal ports */}
      <BlockAcausalPortDetails
        parentPath={parentPath}
        selectedNode={selectedNode}
        canEdit={canEdit}
      />
      {/* Settings */}
      {isSamePath(currentSubmodelPath, parentPath) && (
        <BlockDisplayDetails nodeId={nodeId} parentPath={parentPath} />
      )}
      {developerModeEnabled && // FMU export not ready in wildcat
        fmuBlocksEnabled && // FMU export only for advanced users
        selectedNode.type === 'core.ReferenceSubmodel' &&
        !isDiagramReadonly && (
          <BlockCodeGenDetails selectedNode={selectedNode} />
        )}
    </>
  );
};
