import { SubmodelInfoLiteUI } from 'app/apiTransformers/convertGetSubmodelsList';
import { Coordinate, Geometry } from 'app/common_types/Coordinate';
import { FakeSegmentType, PortIdentifier } from 'app/common_types/SegmentTypes';
import {
  BlockClassName,
  LinkInstance,
  NodeInstance,
} from 'app/generated_types/SimulationModel';

export enum ClickStates {
  Idle,
  Click,
  DoubleClick,
  ClickHeld,
}

export type MouseClickState =
  | { state: ClickStates.Idle }
  | { state: ClickStates.Click; x: number; y: number }
  | { state: ClickStates.DoubleClick; x: number; y: number }
  | {
      state: ClickStates.ClickHeld;
      zoomedCoord: Coordinate;
      rawCoord: Coordinate;
    };

export enum MouseActions {
  Idle,
  Panning,
  MakingSelection,
  DraggingSelected,
  DrawingLinkFromStart,
  DrawingLinkFromEnd,
  DraggingLinkSegment,
  DragDropLibraryBlock,
  ResizeNodeManually,
  ReadyToDefineAnnotation,
  DefiningAnnotationBox,
  ResizeAnnotationManually,
  ReadyToAddComment,
}

type MouseStateVariants =
  | { state: MouseActions.Idle }
  | { state: MouseActions.ReadyToDefineAnnotation }
  | { state: MouseActions.ReadyToAddComment }
  | {
      state: MouseActions.Panning;
      cursorStartX: number;
      cursorStartY: number;
      cameraStartX: number;
      cameraStartY: number;
    }
  | {
      state: MouseActions.MakingSelection;
      rawScreenCursorStartX: number;
      rawScreenCursorStartY: number;
    }
  | {
      state: MouseActions.DraggingSelected;
      startCursorX: number;
      startCursorY: number;
      previousCursorX: number;
      previousCursorY: number;
      selectionOverride?: {
        nodeUuids?: string[];
        linkUuids?: string[];
        annotationUuids?: string[];
      };
    }
  | {
      state:
        | MouseActions.DrawingLinkFromStart
        | MouseActions.DrawingLinkFromEnd;
      linkUuid: string;
    }
  | {
      state: MouseActions.DraggingLinkSegment;
      linkUuid: string;
      segmentId: number;
    }
  | {
      state: MouseActions.DragDropLibraryBlock;
      blockClassName: BlockClassName;
      referenceSubmodel?: SubmodelInfoLiteUI;
      overridePropDefaults?: Partial<NodeInstance>;
      cursorOffset: Coordinate;
    }
  | {
      state: MouseActions.ResizeNodeManually;
      nodeUuid: string;
      startingGridWidth: number;
      startingGridHeight: number;
      startingGridX: number;
      startingGridY: number;
      handleSides: [HoverEdgeSide] | [HoverEdgeSide, HoverEdgeSide];
    }
  | {
      state: MouseActions.DefiningAnnotationBox;
      rawScreenCursorStartX: number;
      rawScreenCursorStartY: number;
    }
  | {
      state: MouseActions.ResizeAnnotationManually;
      uuid: string;
      startingGridWidth: number;
      startingGridHeight: number;
      startingGridX: number;
      startingGridY: number;
      handleSides: [HoverEdgeSide] | [HoverEdgeSide, HoverEdgeSide];
    };

export type MouseState = MouseStateVariants & {
  draggingMode?: boolean;
};

export enum HoverEdgeSide {
  Top,
  Bottom,
  Left,
  Right,
}

export type HoverEdgeSidePair =
  | [HoverEdgeSide]
  | [HoverEdgeSide, HoverEdgeSide];

export enum HoverEntityType {
  Node,
  NodeName,
  Port,
  Link,
  TapPoint,
  HangingStartPoint,
  HangingEndPoint,
  FakeLinkSegment,
  SignalPlotter,
  Annotation,
  AnnotationText,
  NodeResizeEdge,
  AnnotationResizeEdge,
}

export type HoverEntity = {
  geom: Geometry;
} & (
  | {
      entityType: HoverEntityType.Node;
      block: NodeInstance;
    }
  | {
      entityType: HoverEntityType.NodeName;
      block: NodeInstance;
    }
  | {
      entityType: HoverEntityType.Port;
      port: PortIdentifier;
    }
  | {
      entityType: HoverEntityType.Link;
      link: LinkInstance;
      linkUuid: string;
      segmentId: number;
    }
  | {
      entityType: HoverEntityType.TapPoint;
      linkUuid: string;
      tappedLinkUuid: string;
      tappedSegmentId: number;
    }
  | {
      entityType: HoverEntityType.HangingStartPoint;
      linkUuid: string;
    }
  | {
      entityType: HoverEntityType.HangingEndPoint;
      linkUuid: string;
    }
  | {
      entityType: HoverEntityType.FakeLinkSegment;
      link: LinkInstance;
      linkUuid: string;
      fakeSegmentType: FakeSegmentType;
      vertexDataIndex: number;
    }
  | {
      entityType: HoverEntityType.SignalPlotter;
      block: NodeInstance;
    }
  | {
      entityType: HoverEntityType.Annotation;
      uuid: string;
    }
  | {
      entityType: HoverEntityType.AnnotationText;
      uuid: string;
    }
  | {
      entityType: HoverEntityType.NodeResizeEdge;
      nodeUuid: string;
      handleSides: HoverEdgeSidePair;
    }
  | {
      entityType: HoverEntityType.AnnotationResizeEdge;
      uuid: string;
      handleSides: HoverEdgeSidePair;
    }
);
