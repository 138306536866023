import { PayloadAction } from '@reduxjs/toolkit';
import { PortSide } from 'app/common_types/PortTypes';
import { blockClassLookup } from 'app/generated_blocks/';
import {
  BlockInstance,
  LinkInstance,
  NodeInstance,
  Parameter,
} from 'app/generated_types/SimulationModel';
import {
  assertUnreachable,
  makeParameter,
  nodeTypeIsReferencedSubmodel,
  nodeTypeIsSubdiagram,
} from 'app/helpers';
import {
  ModelState,
  getCurrentlyEditingModelFromState,
} from 'app/modelState/ModelState';
import { getUniqueParameterName } from 'app/transformers/uniqueNameGenerators';
import { getPortWorldCoordinate } from 'app/utils/getPortOffsetCoordinate';
import { setLinkSourceAndDependentTapSources } from 'app/utils/linkMutationUtils';
import { CommonParamPayload } from 'app/utils/modelDataUtils';
import { UpdatedPortInfo, updateBlockPorts } from 'app/utils/portMutationUtils';
import { WritableDraft } from 'immer/dist/types/types-external';
import { rendererState } from 'ui/modelRendererInternals/modelRenderer';

interface LinkUpdatePair {
  linksToUpdate: LinkInstance[];
  updateInfo: UpdatedPortInfo;
}

const reIndexPorts = (
  reindexedPorts: UpdatedPortInfo[],
  links: LinkInstance[],
  nodeUuid: string,
) => {
  // Find all the links to update (but don't update them yet)
  // because we rely on index for identity to determine
  // what links should be updated (we don't want to double-update a link)
  const updatePairs: LinkUpdatePair[] = reindexedPorts?.map((updateInfo) => {
    const linksToUpdate = links.filter((link) => {
      if (
        updateInfo.side == PortSide.Input &&
        link.dst &&
        link.dst.node === nodeUuid &&
        link.dst.port === updateInfo.oldIndex
      ) {
        return true;
      }

      if (
        updateInfo.side == PortSide.Output &&
        link.src &&
        link.src.node === nodeUuid &&
        link.src.port === updateInfo.oldIndex
      ) {
        return true;
      }

      return false;
    });

    return {
      linksToUpdate,
      updateInfo,
    };
  });

  // Now that we've identified all the updates,
  // we can update the links without modifying the reIndex results.
  updatePairs.forEach((updatePair) => {
    updatePair.linksToUpdate?.forEach((link) => {
      if (updatePair.updateInfo.side == PortSide.Input && link.dst) {
        link.dst.port = updatePair.updateInfo.index;
      } else if (updatePair.updateInfo.side == PortSide.Output && link.src) {
        link.src.port = updatePair.updateInfo.index;
      }
    });
  });
};

function updateParamHeight(
  block: NodeInstance,
  paramName: string,
  inputHeight?: string,
) {
  if (inputHeight) {
    if (!block.uiprops?.parameter_heights) {
      if (!block.uiprops) {
        block.uiprops = { x: 0, y: 0 };
        console.error(
          `Block of type ${block.type} with uuid ${block.uuid} was missing uiprops.`,
          JSON.stringify(block, null, 2),
        );
      }
      block.uiprops.parameter_heights = {};
    }
    block.uiprops.parameter_heights[paramName] = inputHeight;
  }
}

export function changeBlockParameter(
  state: WritableDraft<ModelState>,
  action: PayloadAction<{
    parentPath: string[];
    nodeUuid: string;
    paramName: string;
    value: string;
    inputHeight?: string;
  }>,
) {
  const { parentPath, nodeUuid, paramName, value, inputHeight } =
    action.payload;

  const model = getCurrentlyEditingModelFromState(state, parentPath);
  if (!model) return;

  const node = model.nodes.find((n) => n.uuid === nodeUuid);
  if (nodeTypeIsReferencedSubmodel(node?.type)) return;

  const block = node as BlockInstance;
  if (!block.parameters) return;

  const defaultParamDef = (
    blockClassLookup(block.type).parameter_definitions || []
  ).find((defaultParam) => defaultParam.name === paramName);

  // if the parameter exists on the base class, allow modification
  if (!defaultParamDef) return;

  // TODO: clean this up once we fix the required props stuff fully
  block.parameters[paramName] = makeParameter(value, defaultParamDef);
  const { removedPorts, reindexedPorts } = updateBlockPorts(block);

  updateParamHeight(block, paramName, inputHeight);

  // make sure previously connected links disconnect properly
  removedPorts?.forEach((port) => {
    model.links.forEach((link) => {
      if (
        port.side == PortSide.Input &&
        link.dst &&
        link.dst.node === nodeUuid &&
        link.dst.port === port.index
      ) {
        const portCoord = getPortWorldCoordinate(
          block,
          PortSide.Input,
          link.dst,
        );
        link.uiprops.hang_coord_end = portCoord;
        delete link.dst;
      } else if (
        port.side == PortSide.Output &&
        link.src &&
        link.src.node === nodeUuid &&
        link.src.port === port.index
      ) {
        // NOTE: This case is not used (yet?)
        const portCoord = getPortWorldCoordinate(
          block,
          PortSide.Output,
          link.src,
        );
        link.uiprops.hang_coord_start = portCoord;
        setLinkSourceAndDependentTapSources(
          link.uuid,
          undefined,
          model.links,
          rendererState?.refs?.current?.linksIndexLUT,
        );
      }
    });
  });

  // keep reindexed ports connected
  if (reindexedPorts) {
    reIndexPorts(reindexedPorts, model.links, nodeUuid);
  }
}

export function changeBlockCommonParameter(
  state: ModelState,
  action: PayloadAction<{
    parentPath: string[];
    blockUuid: string;
    paramPayload: CommonParamPayload;
  }>,
) {
  const { parentPath, blockUuid, paramPayload } = action.payload;

  const model = getCurrentlyEditingModelFromState(state, parentPath);
  if (!model) return;

  const node = model.nodes.find((n) => n.uuid === blockUuid);
  if (nodeTypeIsSubdiagram(node?.type)) return;

  const block = node as BlockInstance;
  switch (paramPayload.paramName) {
    case 'time_mode':
      block.time_mode = paramPayload.value;
      return;
  }

  assertUnreachable(paramPayload.paramName);
}

// FIXME: same as removeNodeExtraParameter? keep only one of them
export function deleteBlockParameter(
  state: ModelState,
  action: PayloadAction<{
    parentPath: string[];
    blockUuid: string;
    paramName: string;
  }>,
) {
  const { parentPath, blockUuid, paramName } = action.payload;

  const model = getCurrentlyEditingModelFromState(state, parentPath);
  if (!model) return;

  const node = model.nodes.find((n) => n.uuid === blockUuid);
  if (nodeTypeIsSubdiagram(node?.type)) return;

  const block = node as BlockInstance;
  delete block?.parameters?.[paramName];
}

export function addNodeExtraParameter(
  state: ModelState,
  payload: {
    parentPath: string[];
    nodeUuid: string;
    name?: string;
    defaultValue?: string;
  },
) {
  const { parentPath, nodeUuid, name, defaultValue } = payload;

  const model = getCurrentlyEditingModelFromState(state, parentPath);
  if (!model) return;

  const node = model.nodes.find((n) => n.uuid === nodeUuid);
  if (!node) return;

  const klass = blockClassLookup(node.type);
  if (!klass.base.extra_parameters) return;

  if (!node.parameters) {
    node.parameters = {};
  }

  let order = 0;
  const keys = Object.keys(node.parameters);
  for (let i = 0; i < keys.length; i++) {
    const param = node.parameters[keys[i]] as Parameter;
    if (param.source !== 'extra') continue;
    if (param.order === undefined) {
      console.warn('A parameter has no order value');
      param.order = order;
      order += 1;
    } else if (order <= param.order) {
      order = param.order + 1;
    }
  }

  const uniqueName = name || getUniqueParameterName(node);
  node.parameters[uniqueName] = {
    value: defaultValue ?? '0',
    source: 'extra',
    order,
  };
}

// FIXME: same as deleteBlockParameter? keep only one of them
export function removeNodeExtraParameter(
  state: ModelState,
  payload: {
    parentPath: string[];
    nodeUuid: string;
    paramName: string;
  },
) {
  const { parentPath, nodeUuid, paramName } = payload;

  const model = getCurrentlyEditingModelFromState(state, parentPath);
  if (!model) return;

  const node = model.nodes.find((n) => n.uuid === nodeUuid);
  if (!node) return;

  const klass = blockClassLookup(node.type);
  if (!klass.base.extra_parameters) return;
  if (!node.parameters) return;

  delete node.parameters[paramName];
}

export function renameNodeExtraParameter(
  state: ModelState,
  action: PayloadAction<{
    parentPath: string[];
    nodeUuid: string;
    oldName: string;
    newName: string;
  }>,
) {
  const { parentPath, nodeUuid, oldName, newName } = action.payload;
  const model = getCurrentlyEditingModelFromState(state, parentPath);
  if (!model) return;

  const node = model.nodes.find((n) => n.uuid === nodeUuid);
  if (!node || !node.parameters) return;
  if (!node.parameters[oldName]) return;

  const klass = blockClassLookup(node.type);
  if (!klass.base.extra_parameters) return;

  if (newName === oldName) return;

  node.parameters[newName] = node.parameters[oldName];
  delete node.parameters[oldName];
}

export function updateNodeExtraParameter(
  state: ModelState,
  action: PayloadAction<{
    parentPath: string[];
    nodeUuid: string;
    paramName: string;
    value: string;
    inputHeight?: string;
  }>,
) {
  const { parentPath, nodeUuid, paramName, value, inputHeight } =
    action.payload;

  const model = getCurrentlyEditingModelFromState(state, parentPath);
  if (!model) return;

  const node = model.nodes.find((n) => n.uuid === nodeUuid);
  if (!node) return;

  const klass = blockClassLookup(node.type);
  if (!klass) return;

  const param = node.parameters[paramName];

  // Make sure we can either: add more parameters to the node when we need them,
  // or, have a node parameter available to update.
  if (!klass.base.extra_parameters && !param) return;

  // Process the value and update the node.
  const processedValue = value.trim();
  if (param) {
    // Either update the parameter,
    param.value = processedValue;
  } else {
    // or, add the parameter.
    node.parameters[paramName] = {
      value: processedValue,
    };
  }

  updateParamHeight(node, paramName, inputHeight);
}
