import { NodeInstance } from '@collimator/model-schemas-ts';
import { useAppSelector } from 'app/hooks';
import { findNodeInDiagram, getNestedNode } from 'app/utils/modelDiagramUtils';
import React from 'react';
import { shallowEqual } from 'react-redux';

const zeroUIProps = { x: 0, y: 0 };

// This hook returns a version of the node without UI props, that we carefully
// update only when necessary in order to avoid re-renders while dragging
// blocks.
export const useNodeWithoutUIProps = (
  parentPath: string[],
  nodeId?: string,
): {
  nodeWithoutUIProps: NodeInstance | undefined;
  isInCurrentDiagram: boolean;
} => {
  const nodeInCurrentDiagram = useAppSelector((state) => {
    if (!nodeId) return undefined;
    const nodeFull = findNodeInDiagram(
      state.model.present.rootModel.nodes,
      state.model.present.submodels,
      nodeId,
    );
    if (!nodeFull) return undefined;
    return { ...nodeFull, uiprops: zeroUIProps };
  }, shallowEqual);

  const nodeNested = useAppSelector((state) => {
    if (nodeInCurrentDiagram || !nodeId) return undefined;
    const nodeFull = getNestedNode(
      state.model.present.rootModel.nodes,
      state.model.present.submodels,
      parentPath,
      nodeId,
    );
    if (!nodeFull) return undefined;
    return { ...nodeFull, uiprops: zeroUIProps };
  }, shallowEqual);

  const node = nodeInCurrentDiagram ?? nodeNested;
  const isInCurrentDiagram = !!nodeInCurrentDiagram;

  const [nodeWithoutUIProps, setNodeWithoutUIProps] = React.useState(node);

  React.useEffect(() => {
    if (shallowEqual(node, nodeWithoutUIProps)) return;
    setNodeWithoutUIProps(node);
  }, [nodeWithoutUIProps, node]);

  return React.useMemo(
    () => ({
      nodeWithoutUIProps,
      isInCurrentDiagram,
    }),
    [nodeWithoutUIProps, isInCurrentDiagram],
  );
};
