import styled from '@emotion/styled';

const TabSelectorContainer = styled.div`
  display: flex;
  flex: 1;
`;
const TabSelectorTab = styled.div<{ selected?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 32px;
  padding: 0 8px;
  user-select: none;

  ${({ theme, selected, disabled }) => `
    color: ${
      selected
        ? 'white'
        : disabled
        ? theme.colors.text.tertiary
        : theme.colors.text.secondary
    };
    border: 1px solid ${
      selected ? theme.colors.brand.primary.base : theme.colors.grey[10]
    };
    background: ${selected ? theme.colors.brand.primary.base : 'white'};
    font-weight: ${selected ? '600' : 'regular'};
    cursor: ${selected || disabled ? 'auto' : 'pointer'};
  `}

  border-right-style: none;

  &:last-child {
    border-right-style: solid;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

interface Props {
  options: Array<{ value: string; label: string }>;
  value: string;
  onSelect: (newVal: string) => void;
  disabled?: boolean;
}

export const TabSelector: React.FC<Props> = ({
  options,
  value,
  onSelect: onSelect_raw,
  disabled,
}: Props) => {
  const getOnSelect = (newVal: string) => () => onSelect_raw(newVal);

  return (
    <TabSelectorContainer>
      {options.map((option) => (
        <TabSelectorTab
          key={option.value}
          selected={value === option.value}
          onClick={
            disabled || value === option.value
              ? undefined
              : getOnSelect(option.value)
          }
          disabled={disabled}>
          {option.label}
        </TabSelectorTab>
      ))}
    </TabSelectorContainer>
  );
};
