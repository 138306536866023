import { AnnotationInstance } from 'app/generated_types/SimulationModel';
import { renderConstants } from 'app/utils/renderConstants';
import * as NVG from 'nanovg-js';
import { SPACING } from 'theme/styleConstants';
import { hexToRgbWithDefault } from 'util/hexToRgb';
import { RendererState } from './modelRenderer';
import { getFontOpacity, getFontSize, NAME_FONTSIZE } from './textRenderUtils';

const makeColorMapValue = (hex: string) => ({
  hex,
  rgb: hexToRgbWithDefault(`#${hex}`),
});

type AnnotationColorKey = 'green' | 'yellow' | 'blue' | 'pink' | 'gray';

export const annotationColorMap: {
  [k: string]: { hex: string; rgb: [number, number, number] };
} = {
  green: makeColorMapValue('8CE5E1'),
  yellow: makeColorMapValue('E0D796'),
  blue: makeColorMapValue('C4CBF2'),
  pink: makeColorMapValue('F1CFD8'),
  gray: makeColorMapValue('E4E7E7'),
};

export const annotationColorOrder: AnnotationColorKey[] = [
  'green',
  'yellow',
  'blue',
  'pink',
  'gray',
];

export function drawAnnotation(
  nvg: NVG.Context,
  rs: RendererState,
  annotation: AnnotationInstance,
  selected: boolean,
): void {
  nvg.beginPath();
  nvg.roundedRect(
    (rs.camera.x + annotation.x) * rs.zoom,
    (rs.camera.y + annotation.y) * rs.zoom,
    annotation.grid_width * renderConstants.GRID_UNIT_PXSIZE * rs.zoom,
    annotation.grid_height * renderConstants.GRID_UNIT_PXSIZE * rs.zoom,
    2 * rs.zoom,
  );
  const [r, g, b] = (
    annotationColorMap[annotation.color_id || 'green'] ||
    annotationColorMap.green
  ).rgb;
  nvg.fillColor(nvg.RGBA(r, g, b, 255 * 0.5));
  nvg.fill();

  if (selected) {
    const strokeWidth = 2;
    const halfStrokeWidth = strokeWidth / 2;
    nvg.beginPath();
    nvg.roundedRect(
      (rs.camera.x + annotation.x - halfStrokeWidth) * rs.zoom,
      (rs.camera.y + annotation.y - halfStrokeWidth) * rs.zoom,
      (annotation.grid_width * renderConstants.GRID_UNIT_PXSIZE + strokeWidth) *
        rs.zoom,
      (annotation.grid_height * renderConstants.GRID_UNIT_PXSIZE +
        strokeWidth) *
        rs.zoom,
      (2 + halfStrokeWidth) * rs.zoom,
    );
    nvg.strokeColor(nvg.RGB(105, 225, 219));
    nvg.strokeWidth(strokeWidth * rs.zoom);
    nvg.stroke();
  }

  if (rs.refs.current.uiFlags.editingAnnotationTextUUID !== annotation.uuid) {
    const fontSize = getFontSize(rs.zoom, NAME_FONTSIZE);
    const textOpacity = getFontOpacity(rs.zoom);
    const labelTop = annotation.label_position === 'top';
    const labelInside = annotation.label_position === 'inside';

    let textX = 0;

    if (labelInside) {
      textX = annotation.x + SPACING / 2;
    } else {
      textX = annotation.x;
    }

    let textY = 0;
    if (labelTop) {
      textY = annotation.y - SPACING / 3;
    } else if (labelInside) {
      textY = annotation.y + SPACING / 2;
    } else {
      textY =
        annotation.y +
        (annotation.grid_height + 1) * renderConstants.GRID_UNIT_PXSIZE;
    }

    nvg.fontSize(fontSize);
    nvg.fontFace('archivo');
    nvg.textAlign(
      NVG.Align.LEFT | (labelTop ? NVG.Align.BOTTOM : NVG.Align.TOP),
    );
    nvg.fillColor(nvg.RGBA(74, 74, 74, 255 * textOpacity));

    const lines = annotation.text.split('\n');

    for (let i_vis = 0; i_vis < lines.length; i_vis++) {
      const i_line = labelTop ? lines.length - 1 - i_vis : i_vis;

      const zoomedYAdjust = labelTop
        ? -(fontSize * 1.2 * i_vis)
        : fontSize * 1.2 * i_vis;
      nvg.text(
        (rs.camera.x + textX) * rs.zoom,
        (rs.camera.y + textY) * rs.zoom + zoomedYAdjust,
        lines[i_line],
        null,
      );
    }
  }
}
