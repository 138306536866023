import React from 'react';
import {
  PyodideWorkerMessage,
  PyodideWorkerMessageType,
  PyodideWorkerResponseReadStderr,
  PyodideWorkerResponseReadStdout,
} from './PyodideWorkerMessageTypes';

// WARNING: pyodide is downloaded by index.html and the TypeScript version
// must match otherwise you'll end up with mismatching API definitions.

const MODEL_BUILDER_WHEEL = `${process.env.PUBLIC_URL}/wheels/model_builder-0.0.1-py3-none-any.whl`;
const JAX_LITE_WHEEL = `${process.env.PUBLIC_URL}/wheels/jaxlite-0.0.1-py3-none-any.whl`;
const PYCOLLIMATOR_WHEEL = `${process.env.PUBLIC_URL}/wheels/pycollimator-2.0.8-py3-none-any.whl`;

interface PythonProviderProps {
  children?: React.ReactNode;
  modelBuilderWheelUrl?: string[];
}

interface PyodideSubInterface {
  runPythonAsync: (
    code: string,
    inputVariables?: Map<string, unknown>,
    returnVariables?: string[],
  ) => Promise<unknown>;
  runPythonAsyncRaw: (code: string) => Promise<string>;
  globalsSet: (key: string, value: unknown) => void;
}

export interface PythonContext {
  pyodide: PyodideSubInterface | null;
  isReady: boolean;
  executionId: React.MutableRefObject<number>;
  readStdout: () => Promise<string>;
  readStderr: () => Promise<string>;
  registerStdoutListener: (listener: (stdout: string) => void) => () => void;
  registerStderrListener: (listener: (stderr: string) => void) => () => void;
}

const singletonContext = React.createContext<PythonContext>({
  pyodide: null,
  isReady: false,
  executionId: { current: 0 },
  readStdout: async () => '',
  readStderr: async () => '',
  registerStdoutListener: () => () => {},
  registerStderrListener: () => () => {},
});

class DeferredPromise<T> {
  promise: Promise<T>;

  resolve!: (value: T | PromiseLike<T>) => void;

  reject!: (reason?: any) => void;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

export const PythonProvider = ({
  children,
  modelBuilderWheelUrl,
}: PythonProviderProps) => {
  const id = React.useRef(0);
  const [isReady, setIsReady] = React.useState(false);
  const worker = React.useRef<Worker | null>(null);
  const executionId = React.useRef<number>(0);
  const promises = React.useRef<Map<number, DeferredPromise<unknown>>>(
    new Map(),
  );
  const stdoutListeners = React.useRef<((msg: string) => void)[]>([]);
  const stderrListeners = React.useRef<((msg: string) => void)[]>([]);

  const pyodideCallAsync = React.useCallback(
    async (payload: PyodideWorkerMessage): Promise<unknown> => {
      id.current += 1;

      const deferred = new DeferredPromise<unknown>();
      promises.current.set(id.current, deferred);

      worker.current?.postMessage({
        ...payload,
        id: id.current,
      });
      const result = await deferred.promise;
      return result;
    },
    [],
  );

  // Create the worker only once
  React.useEffect(() => {
    if (worker.current) return;
    worker.current = new Worker(
      new URL('workers/pyodideWorker.ts', import.meta.url),
    );

    // wait 1 second in case the onmessage handler is not set yet
    setTimeout(() => {
      const msg: PyodideWorkerMessage = {
        type: PyodideWorkerMessageType.INIT,
        wheels: modelBuilderWheelUrl ?? [
          JAX_LITE_WHEEL,
          MODEL_BUILDER_WHEEL,
          PYCOLLIMATOR_WHEEL,
        ],
      };
      worker.current?.postMessage(msg);
    }, 1000);

    return () => {
      worker.current?.terminate();
      worker.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Listen to messages from the worker
  React.useEffect(() => {
    if (!worker.current) return;
    worker.current.onmessage = (event) => {
      const { id, isReady, type, ...data } = event.data;
      if (isReady) {
        setIsReady(true);
        return;
      }

      if (type === PyodideWorkerMessageType.NEW_STDOUT) {
        stdoutListeners.current.forEach((l) => l(data.msg));
        return;
      }

      if (type === PyodideWorkerMessageType.NEW_STDERR) {
        stderrListeners.current.forEach((l) => l(data.msg));
        return;
      }

      const promise = promises.current.get(id);
      if (!promise) return;
      if (data.error) {
        promise.reject(data.error);
      } else if (type === PyodideWorkerMessageType.RUN_PYTHON) {
        promise.resolve(data.results);
      } else if (type === PyodideWorkerMessageType.RUN_PYTHON_RAW) {
        promise.resolve(data.resultStr);
      } else {
        promise.resolve(data);
      }
    };
  }, [promises]);

  const contextValue = React.useMemo(
    (): PythonContext => ({
      pyodide: {
        runPythonAsync: (
          code: string,
          inputVariables?: Map<string, unknown>,
          returnVariables?: string[],
        ) =>
          pyodideCallAsync({
            type: PyodideWorkerMessageType.RUN_PYTHON,
            code,
            inputVariables,
            returnVariables,
          }),
        runPythonAsyncRaw: (code: string) =>
          pyodideCallAsync({
            type: PyodideWorkerMessageType.RUN_PYTHON_RAW,
            code,
          }) as Promise<string>,
        globalsSet: (key: string, value: unknown) =>
          pyodideCallAsync({
            type: PyodideWorkerMessageType.GLOBALS_SET,
            key,
            value,
          }),
      },
      readStdout: async (): Promise<string> => {
        const resp = (await pyodideCallAsync({
          type: PyodideWorkerMessageType.READ_STDOUT,
        })) as PyodideWorkerResponseReadStdout;
        return resp.stdout;
      },
      readStderr: async (): Promise<string> => {
        const resp = (await pyodideCallAsync({
          type: PyodideWorkerMessageType.READ_STDERR,
        })) as PyodideWorkerResponseReadStderr;
        return resp.stderr;
      },
      registerStdoutListener: (listener: (stdout: string) => void) => {
        stdoutListeners.current.push(listener);

        return () => {
          stdoutListeners.current = stdoutListeners.current.filter(
            (l) => l !== listener,
          );
        };
      },
      registerStderrListener: (listener: (stderr: string) => void) => {
        stderrListeners.current.push(listener);

        return () => {
          stderrListeners.current = stderrListeners.current.filter(
            (l) => l !== listener,
          );
        };
      },
      isReady,
      executionId,
    }),
    [isReady, pyodideCallAsync],
  );

  return (
    <singletonContext.Provider value={contextValue}>
      {children}
    </singletonContext.Provider>
  );
};

// NOTE: If possible, use directly usePythonExecutor instead.
export const usePython = () => {
  const pyContext = React.useContext(singletonContext);
  if (!pyContext) {
    throw new Error('usePython must be used within a PythonProvider');
  }
  return pyContext;
};
