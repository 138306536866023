import { t } from '@lingui/macro';
import { MouseActions } from 'app/common_types/MouseTypes';
import { NodeInstance } from 'app/generated_types/SimulationModel';
import { getCurrentModelRef } from 'app/sliceRefAccess/CurrentModelRef';
import { MAXIMUM_ZOOM, MINIMUM_ZOOM } from 'app/slices/cameraSlice';
import { modelActions } from 'app/slices/modelSlice';
import { notificationsActions } from 'app/slices/notificationsSlice';
import { DiagramFooterTab, uiFlagsActions } from 'app/slices/uiFlagsSlice';
import { renderConstants } from 'app/utils/renderConstants';
import hotkeys from 'hotkeys-js';
import { getTheme, getThemeValue } from 'theme/themes';
import { RendererState } from 'ui/modelRendererInternals/modelRenderer';
import { convertZoomedScreenToWorldCoordinates } from './convertScreenToWorldCoordinates';
import { copyEntities, pasteEntities } from './copyPaste';
import { getSelectedEntitiesFromRendererState } from './getSelectedNodesAndLinksFromRendererState';
import { getVisualNodeHeight } from './getVisualNodeHeight';
import { getVisualNodeWidth } from './getVisualNodeWidth';
import { transitionMouseState } from './transitionMouseState';
import {
  BASE_ZOOM_DELTA,
  resetZoomAroundScreenCenter,
  zoomAroundScreenCenter,
} from './zoom';

const getScreenDimensions = (rs: RendererState) => {
  const canvas = rs.refs.current.canvas;
  const externalOverlay = rs.refs.current.externalOverlay;

  if (!canvas || !externalOverlay) {
    return {
      rawScreenW: 0,
      rawScreenH: 0,
      screenW: 0,
      screenH: 0,
      xOffset: 0,
      yOffset: 0,
    };
  }

  const theme = getTheme('base');
  const { isLeftSidebarOpen, isRightSidebarOpen } = rs.refs.current.uiFlags;
  const leftSidebarWidthValue = getThemeValue(theme.sizes.leftSidebarWidth);
  const rightSidebarWidthValue = getThemeValue(theme.sizes.rightSidebarWidth);

  const rawScreenW = canvas.clientWidth;
  const rawScreenH = canvas.clientHeight;
  const screenW = externalOverlay.clientWidth;
  const screenH = externalOverlay.clientHeight;

  const xOffset =
    !isLeftSidebarOpen && isRightSidebarOpen
      ? -rightSidebarWidthValue
      : isLeftSidebarOpen && !isRightSidebarOpen
      ? leftSidebarWidthValue
      : 0;
  const externalOverlayRect = externalOverlay.getBoundingClientRect();
  const navbarHeight = externalOverlayRect.top;
  const consoleHeight =
    canvas.clientHeight - externalOverlayRect.height - navbarHeight;
  const yOffset = navbarHeight - consoleHeight;

  return {
    rawScreenW,
    rawScreenH,
    screenW,
    screenH,
    xOffset,
    yOffset,
  };
};

export const centerCameraAroundNode = (
  rs: RendererState,
  node: NodeInstance,
) => {
  const { rawScreenW, rawScreenH, xOffset, yOffset } = getScreenDimensions(rs);

  const halfNodeWidth = getVisualNodeWidth(node) / 2;
  const halfScreenWZoomed = rawScreenW / 2 / rs.zoom;
  const zoomedXOffsetHalf = xOffset / 2 / rs.zoom;
  const newCamX =
    node.uiprops.x + halfNodeWidth - halfScreenWZoomed - zoomedXOffsetHalf;

  const halfNodeHeight = getVisualNodeHeight(node) / 2;
  const halfScreenHZoomed = rawScreenH / 2 / rs.zoom;
  const zoomedYOffsetHalf = yOffset / 2 / rs.zoom;
  const newCamY =
    node.uiprops.y + halfNodeHeight - halfScreenHZoomed - zoomedYOffsetHalf;

  rs.camera.x = newCamX * -1;
  rs.camera.y = newCamY * -1;
  rs.setTransform({ x: newCamX, y: newCamY, zoom: rs.zoom });
};

const getModelAndScreenDimensions = (rs: RendererState) => {
  const GRID_MARGIN = renderConstants.GRID_UNIT_PXSIZE * 5;

  let minX = Number.MAX_SAFE_INTEGER;
  let maxX = -Number.MAX_SAFE_INTEGER;
  let minY = Number.MAX_SAFE_INTEGER;
  let maxY = -Number.MAX_SAFE_INTEGER;

  const nodes = rs.refs.current.nodes;
  const links = rs.refs.current.links;
  const annotations = rs.refs.current.annotations;

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    const currentNodeWidth = getVisualNodeWidth(node);
    const currentNodeHeight = getVisualNodeHeight(node);
    minX = Math.min(minX, node.uiprops.x - GRID_MARGIN);
    minY = Math.min(minY, node.uiprops.y - GRID_MARGIN);
    maxX = Math.max(maxX, node.uiprops.x + currentNodeWidth + GRID_MARGIN);
    maxY = Math.max(maxY, node.uiprops.y + currentNodeHeight + GRID_MARGIN);
  }

  for (let i = 0; i < annotations.length; i++) {
    const anno = annotations[i];
    const annoWidth = anno.grid_width * renderConstants.GRID_UNIT_PXSIZE;
    const annoHeight = anno.grid_height * renderConstants.GRID_UNIT_PXSIZE;
    minX = Math.min(minX, anno.x - GRID_MARGIN);
    minY = Math.min(minY, anno.y - GRID_MARGIN);
    maxX = Math.max(maxX, anno.x + annoWidth + GRID_MARGIN);
    maxY = Math.max(maxY, anno.y + annoHeight + GRID_MARGIN);
  }

  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    for (let j = 0; j < link.uiprops.segments.length; j++) {
      const segment = link.uiprops.segments[j];
      if (segment.segment_direction === 'vert') {
        minX = Math.min(minX, segment.coordinate - GRID_MARGIN);
        maxX = Math.max(maxX, segment.coordinate + GRID_MARGIN);
      }
      if (segment.segment_direction === 'horiz') {
        minY = Math.min(minY, segment.coordinate - GRID_MARGIN);
        maxY = Math.max(maxY, segment.coordinate + GRID_MARGIN);
      }
    }

    if (
      !link.src &&
      link.uiprops.hang_coord_start &&
      link.uiprops.link_type.connection_method !== 'link_tap'
    ) {
      const { hang_coord_start } = link.uiprops;

      minX = Math.min(minX, hang_coord_start.x - GRID_MARGIN);
      maxX = Math.max(maxX, hang_coord_start.x - GRID_MARGIN);
      minY = Math.min(minY, hang_coord_start.y - GRID_MARGIN);
      maxY = Math.max(maxY, hang_coord_start.y - GRID_MARGIN);
    }
    if (!link.dst && link.uiprops.hang_coord_end) {
      const { hang_coord_end } = link.uiprops;

      minX = Math.min(minX, hang_coord_end.x - GRID_MARGIN);
      maxX = Math.max(maxX, hang_coord_end.x - GRID_MARGIN);
      minY = Math.min(minY, hang_coord_end.y - GRID_MARGIN);
      maxY = Math.max(maxY, hang_coord_end.y - GRID_MARGIN);
    }
  }

  const modelX = minX;
  const modelY = minY;
  const modelW = maxX - minX;
  const modelH = maxY - minY;

  const { rawScreenW, rawScreenH, screenW, screenH, xOffset, yOffset } =
    getScreenDimensions(rs);

  if (!nodes.length && !annotations.length && !links.length) {
    // No entity: zoom to 100% and go to 0,0
    return {
      modelX: 0,
      modelY: 0,
      modelW: screenW,
      modelH: screenH,
      rawScreenW,
      rawScreenH,
      screenW,
      screenH,
      xOffset: 0,
      yOffset: 0,
    };
  }

  return {
    modelX,
    modelY,
    modelW,
    modelH,
    rawScreenW,
    rawScreenH,
    screenW,
    screenH,
    xOffset,
    yOffset,
  };
};

const scaleFitCameraBasedOnDimensions = (
  rs: RendererState,
  modelX: number,
  modelY: number,
  modelW: number,
  modelH: number,
  rawScreenW: number,
  rawScreenH: number,
  screenW: number,
  screenH: number,
  xOffset: number,
  yOffset: number,
) => {
  const targetZoom = Math.max(
    MINIMUM_ZOOM,
    Math.min(MAXIMUM_ZOOM, Math.min(screenW / modelW, screenH / modelH)),
  );
  const zoomedHalfXOffset = xOffset / targetZoom / 2;
  const zoomedHalfYOffset = yOffset / targetZoom / 2;
  const targetX = modelX + modelW / 2 - rawScreenW / targetZoom / 2;
  const targetY = modelY + modelH / 2 - rawScreenH / targetZoom / 2;
  rs.camera.x = -(targetX - zoomedHalfXOffset);
  rs.camera.y = -(targetY - zoomedHalfYOffset);
  rs.zoom = targetZoom;

  rs.setTransform({
    x: -targetX,
    y: -targetY,
    zoom: targetZoom,
  });
};

export const scaleCameraToFitModelIfLargerAndCenter = (rs: RendererState) => {
  const {
    modelX,
    modelY,
    modelW,
    modelH,
    rawScreenW,
    rawScreenH,
    screenW,
    screenH,
    xOffset,
    yOffset,
  } = getModelAndScreenDimensions(rs);

  if (modelW > screenW || modelH > screenH) {
    scaleFitCameraBasedOnDimensions(
      rs,
      modelX,
      modelY,
      modelW,
      modelH,
      rawScreenW,
      rawScreenH,
      screenW,
      screenH,
      xOffset,
      yOffset,
    );
  } else {
    const targetX = modelX + modelW / 2 - rawScreenW / 2;
    const targetY = modelY + modelH / 2 - rawScreenH / 2;
    rs.camera.x = -targetX;
    rs.camera.y = -targetY;
    rs.zoom = 1;

    rs.setTransform({
      x: -targetX,
      y: -targetY,
      zoom: 1,
    });
  }
};

export const scaleCameraToFitModel = (rs: RendererState) => {
  const {
    modelX,
    modelY,
    modelW,
    modelH,
    rawScreenW,
    rawScreenH,
    screenW,
    screenH,
    xOffset,
    yOffset,
  } = getModelAndScreenDimensions(rs);

  scaleFitCameraBasedOnDimensions(
    rs,
    modelX,
    modelY,
    modelW,
    modelH,
    rawScreenW,
    rawScreenH,
    screenW,
    screenH,
    xOffset,
    yOffset,
  );
};

export const allModKeys = ['Control', 'Meta', 'Shift', 'Alt'];

type KeyConfigVariant =
  | {
      variant: 'normal';
      hotkeyString: string;
    }
  | {
      variant: 'special';
      specialConfig: {
        modKeys: Array<'CtrlCmd' | 'Shift' | 'AltOption'>;
        key: string;
      };
    };

export type SingleShortcutConfig = KeyConfigVariant & {
  handler: (rs: RendererState) => void;
  preventDefault: boolean;
  ignoreIfTextFocused: boolean;
};

export type ShortcutKeysConfig = Array<SingleShortcutConfig>;

export const shortcutsConfig: ShortcutKeysConfig = [
  {
    variant: 'normal',
    hotkeyString: 'esc',
    handler: (rs: RendererState) => {
      transitionMouseState(rs, { state: MouseActions.Idle });
    },
    preventDefault: false,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'normal',
    hotkeyString: 'del,backspace,shift+del,shift+backspace',
    handler: (rs: RendererState) => {
      if (!rs.refs.current.uiFlags.canEditModel) return;

      if (hotkeys.shift) {
        if (
          rs.refs.current.selectedNodeIds.length > 0 &&
          rs.refs.current.selectedLinkIds.length === 0
        ) {
          for (let i = 0; i < rs.refs.current.selectedNodeIds.length; i++) {
            const nodeUuid = rs.refs.current.selectedNodeIds[i];

            rs.dispatch(
              modelActions.disconnectNodeFromAllLinks({
                nodeUuid,
                connectSingleIO: true,
              }),
            );
          }
        }

        if (rs.refs.current.selectedNodeIds) {
          // TODO: call delete block endpoint so that link deletes cascade.
          // dispatch(enhancedApi.endpoints.deleteBlock.initiate());
          rs.refs.current.visualizerPrefs.removeBlockPortsFromChart(
            rs.refs.current.selectedNodeIds.map((nodeId) => ({
              nodeId,
              parentPath: getCurrentModelRef().submodelPath,
            })),
            true,
          );
          rs.dispatch(
            modelActions.removeEntitiesFromModel({
              blockUuids: rs.refs.current.selectedNodeIds,
              linkUuids: rs.refs.current.selectedLinkIds,
              annotationUuids: rs.refs.current.selectedAnnotationIds,
              autoRemoveBlocksLinks: false,
            }),
          );
        }
      } else {
        rs.refs.current.visualizerPrefs.removeBlockPortsFromChart(
          rs.refs.current.selectedNodeIds.map((nodeId) => ({
            nodeId,
            parentPath: getCurrentModelRef().submodelPath,
          })),
          true,
        );

        // TODO: call delete block endpoint so that link deletes cascade.
        // dispatch(enhancedApi.endpoints.deleteBlock.initiate());
        rs.dispatch(
          modelActions.removeEntitiesFromModel({
            blockUuids: rs.refs.current.selectedNodeIds,
            linkUuids: rs.refs.current.selectedLinkIds,
            annotationUuids: rs.refs.current.selectedAnnotationIds,
            autoRemoveBlocksLinks: false,
          }),
        );
      }
    },
    preventDefault: false,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'special',
    specialConfig: {
      modKeys: ['CtrlCmd'],
      key: '-',
    },
    handler: (rs: RendererState) => {
      zoomAroundScreenCenter(-BASE_ZOOM_DELTA);
      rs.setTransform({
        x: rs.camera.x,
        y: rs.camera.y,
        zoom: rs.zoom,
      });
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'special',
    specialConfig: {
      modKeys: ['CtrlCmd'],
      key: '=',
    },
    handler: (rs: RendererState) => {
      zoomAroundScreenCenter(BASE_ZOOM_DELTA);
      rs.setTransform({
        x: rs.camera.x,
        y: rs.camera.y,
        zoom: rs.zoom,
      });
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  // Same as above but for keypad
  {
    variant: 'special',
    specialConfig: {
      modKeys: ['CtrlCmd'],
      key: '+',
    },
    handler: (rs: RendererState) => {
      zoomAroundScreenCenter(BASE_ZOOM_DELTA);
      rs.setTransform({
        x: rs.camera.x,
        y: rs.camera.y,
        zoom: rs.zoom,
      });
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'special',
    specialConfig: {
      modKeys: ['CtrlCmd'],
      key: '0',
    },
    handler: (rs: RendererState) => {
      resetZoomAroundScreenCenter();
      rs.setTransform({
        x: rs.camera.x,
        y: rs.camera.y,
        zoom: rs.zoom,
      });
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'normal',
    hotkeyString: 'space',
    handler: (rs: RendererState) => {
      scaleCameraToFitModel(rs);
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'normal',
    hotkeyString: 'ctrl+z,cmd+z,ctrl+shift+z,cmd+shift+z',
    handler: (rs: RendererState) => {
      if (!rs.refs.current.uiFlags.canEditModel) return;
      if (hotkeys.shift) {
        rs.refs.current.redoFunc();
      } else {
        rs.refs.current.undoFunc();
      }
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'normal',
    hotkeyString: 'ctrl+y,cmd+y',
    handler: (rs: RendererState) => {
      if (!rs.refs.current.uiFlags.canEditModel) return;
      rs.refs.current.redoFunc();
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'normal',
    hotkeyString: 'ctrl+c,cmd+c',
    handler: (rs: RendererState) => {
      const { selectedNodeIds, selectedLinkIds, selectedAnnotationIds } =
        rs.refs.current;

      const { selectedNodes, selectedLinks, selectedAnnotations } =
        getSelectedEntitiesFromRendererState(rs);

      copyEntities(
        getCurrentModelRef().projectId,
        selectedNodes,
        selectedLinks,
        selectedAnnotations,
        selectedNodeIds,
        selectedLinkIds,
        selectedAnnotationIds,
        rs.dispatch,
        rs.refs.current.visualizerPrefs,
        false,
      );
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'normal',
    hotkeyString: 'ctrl+x,cmd+x',
    handler: (rs: RendererState) => {
      if (!rs.refs.current.uiFlags.canEditModel) return;

      const { selectedNodeIds, selectedLinkIds, selectedAnnotationIds } =
        rs.refs.current;

      const { selectedNodes, selectedLinks, selectedAnnotations } =
        getSelectedEntitiesFromRendererState(rs);

      copyEntities(
        getCurrentModelRef().projectId,
        selectedNodes,
        selectedLinks,
        selectedAnnotations,
        selectedNodeIds,
        selectedLinkIds,
        selectedAnnotationIds,
        rs.dispatch,
        rs.refs.current.visualizerPrefs,
        true,
      );
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'normal',
    hotkeyString: 'ctrl+v,cmd+v,ctrl+shift+v,cmd+shift+v',
    handler: (rs: RendererState) => {
      if (!rs.refs.current.parent) return;
      if (!rs.refs.current.uiFlags.canEditModel) return;

      const {
        nodes,
        links,
        annotations,
        copiedSubmodelsSection,
        copiedStateMachines,
        projectId: pastingProjectId,
      } = rs.refs.current.uiFlags.inAppClipboard;

      const pastingFromSameProject =
        getCurrentModelRef().projectId === pastingProjectId;

      // TODO: remove this when we have more clear UX for inter-project pasting
      if (!pastingFromSameProject) return;

      const consoleOpen =
        rs.refs.current.uiFlags.diagramFooterTab !== DiagramFooterTab.None;
      const correctedScreenHeight = consoleOpen
        ? rs.refs.current.parent.clientHeight / 2
        : rs.refs.current.parent.clientHeight;
      const screenCenterX = rs.refs.current.parent.clientWidth / 2;
      const screenCenterY = correctedScreenHeight / 2;
      const worldCursor = convertZoomedScreenToWorldCoordinates(
        rs.camera,
        rs.screenCursorZoomed,
      );

      const pasteX = hotkeys.shift
        ? worldCursor.x
        : screenCenterX / rs.zoom - rs.camera.x;
      const pasteY = hotkeys.shift
        ? worldCursor.y
        : screenCenterY / rs.zoom - rs.camera.y;

      const pastedUuids = pasteEntities(
        pasteX,
        pasteY,
        false,
        nodes,
        links,
        annotations,
        copiedSubmodelsSection,
        copiedStateMachines,
        rs.refs.current.nodes,
        rs.dispatch,
      );

      rs.dispatch(
        modelActions.setSelections({
          selectionParentPath: getCurrentModelRef().submodelPath,
          selectedBlockIds: pastedUuids.nodeUuids,
          selectedLinkIds: pastedUuids.linkUuids,
          selectedAnnotationIds: [],
        }),
      );
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'normal',
    hotkeyString: 'ctrl+a,cmd+a',
    handler: (rs: RendererState) => {
      rs.dispatch(
        modelActions.setSelections({
          selectionParentPath: getCurrentModelRef().submodelPath,
          selectedBlockIds: rs.refs.current.nodes.map((node) => node.uuid),
          selectedLinkIds: rs.refs.current.links.map((link) => link.uuid),
          selectedAnnotationIds: [],
        }),
      );
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'normal',
    hotkeyString: 'alt+g,option+g',
    handler: (rs: RendererState) => {
      if (!rs.refs.current.uiFlags.canEditModel) return;
      rs.dispatch(
        modelActions.createSubdiagramFromSelection({
          subdiagramType: 'core.Group',
        }),
      );
    },
    preventDefault: true,
    ignoreIfTextFocused: false,
  },
  {
    variant: 'special',
    specialConfig: {
      modKeys: ['AltOption'],
      key: 'd',
    },
    handler: (rs: RendererState) => {
      if (getCurrentModelRef().topLevelModelType === 'Submodel') {
        return;
      }
      rs.dispatch(uiFlagsActions.toggleUIFlag('showDatatypesInModel'));
      rs.dispatch(
        uiFlagsActions.setUIFlag({ showSignalDomainsInModel: false }),
      );
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'special',
    specialConfig: {
      modKeys: ['AltOption'],
      key: 'r',
    },
    handler: (rs: RendererState) => {
      if (getCurrentModelRef().topLevelModelType === 'Submodel') {
        return;
      }
      rs.dispatch(uiFlagsActions.toggleUIFlag('showSignalDomainsInModel'));
      rs.dispatch(uiFlagsActions.setUIFlag({ showDatatypesInModel: false }));
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'special',
    specialConfig: {
      modKeys: ['AltOption'],
      key: 'l',
    },
    handler: (rs: RendererState) => {
      rs.dispatch(uiFlagsActions.toggleUIFlag('showSignalNamesInModel'));
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'special',
    specialConfig: {
      modKeys: ['AltOption', 'Shift'],
      key: 'k',
    },
    handler: (rs: RendererState) =>
      rs.dispatch(uiFlagsActions.toggleUIFlag('renderDebug')),
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'normal',
    hotkeyString: 'ctrl+s,ctrl+shift+s,cmd+s,cmd+shift+s',
    handler: (rs: RendererState) =>
      rs.dispatch(
        notificationsActions.setCurrentMessage({
          message: t({
            message: "We've got your back — your file is saved automatically.",
            id: 'modelEditor.autoSavePseudoNag',
          }),
          icon: undefined,
          canClose: true,
        }),
      ),
    preventDefault: true,
    ignoreIfTextFocused: false,
  },
  {
    variant: 'special',
    specialConfig: {
      modKeys: [],
      key: 'ArrowRight',
    },
    handler: (rs: RendererState) => {
      if (hotkeys.cmd || hotkeys.ctrl) {
        rs.dispatch(
          modelActions.resizeSelectedNodesByGridAmount({
            deltaGridX: 1,
            deltaGridY: 0,
          }),
        );
      } else {
        rs.dispatch(
          modelActions.moveSelectedEntitiesByDelta({
            deltaX: renderConstants.GRID_UNIT_PXSIZE,
            deltaY: 0,
          }),
        );
      }
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'special',
    specialConfig: {
      modKeys: [],
      key: 'ArrowLeft',
    },
    handler: (rs: RendererState) => {
      if (hotkeys.cmd || hotkeys.ctrl) {
        rs.dispatch(
          modelActions.resizeSelectedNodesByGridAmount({
            deltaGridX: -1,
            deltaGridY: 0,
          }),
        );
      } else {
        rs.dispatch(
          modelActions.moveSelectedEntitiesByDelta({
            deltaX: -renderConstants.GRID_UNIT_PXSIZE,
            deltaY: 0,
          }),
        );
      }
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'special',
    specialConfig: {
      modKeys: [],
      key: 'ArrowUp',
    },
    handler: (rs: RendererState) => {
      if (hotkeys.cmd || hotkeys.ctrl) {
        rs.dispatch(
          modelActions.resizeSelectedNodesByGridAmount({
            deltaGridX: 0,
            deltaGridY: -1,
          }),
        );
      } else {
        rs.dispatch(
          modelActions.moveSelectedEntitiesByDelta({
            deltaX: 0,
            deltaY: -renderConstants.GRID_UNIT_PXSIZE,
          }),
        );
      }
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
  {
    variant: 'special',
    specialConfig: {
      modKeys: [],
      key: 'ArrowDown',
    },
    handler: (rs: RendererState) => {
      if (hotkeys.cmd || hotkeys.ctrl) {
        rs.dispatch(
          modelActions.resizeSelectedNodesByGridAmount({
            deltaGridX: 0,
            deltaGridY: 1,
          }),
        );
      } else {
        rs.dispatch(
          modelActions.moveSelectedEntitiesByDelta({
            deltaX: 0,
            deltaY: renderConstants.GRID_UNIT_PXSIZE,
          }),
        );
      }
    },
    preventDefault: true,
    ignoreIfTextFocused: true,
  },
];

export const clickModifierConfig = {
  selectMultiple: 'Control',
  selectMultipleMacOS: 'Meta',
  quickConnect: 'Alt',
  dragCopy: 'Alt',
  tap: 'Alt',
  insertRemoveNode: 'Shift',
};

// "control" cannot be used for macos - this is reserved for zoom
// (pinch-zoom on macos uses it automatically)
export const scrollModifierConfig = {
  panHorizontal: 'Shift',
  panVertical: 'Control',
  panVerticalMacOS: 'Meta',
};

type ScrollModsType = typeof scrollModifierConfig;
type ScrollModsKey = keyof ScrollModsType;
export const scrollModifierPressed = (
  key: ScrollModsType[ScrollModsKey],
  wheelEv: WheelEvent,
) =>
  ({
    Shift: wheelEv.shiftKey,
    Control: wheelEv.ctrlKey,
    Meta: wheelEv.metaKey,
  }[key]);
